import React from "react";
import Share from "common/icons/Share2";

import { toast } from "react-toastify";

import { format, parseISO } from "date-fns";

import { navigate } from "@reach/router";
import { EventListDate, LiveClassDate } from "common/Ponents";
import Calendar from "common/icons/Calendar";
import Lightning from "common/icons/Lightning";
import { sharedEntityTrack } from "common/Ana";

export default function EventsCard(props) {
  const shareEvent = (e) => {
    e.preventDefault();
    sharedEntityTrack({ id: props.id }, "copy", "event");

    const shareLink = `${window.location.origin}/events/${
      props.id
    }?utm_source=eventsharecard&trl=monthq&utm_medium=dirlink&utm_campaign=${props.user.id}${
      props.booked ? `&coup=${props.booked.user_coup}` : ""
    }`;
    navigator.clipboard.writeText(shareLink);
    toast.info("Link Copied!", {
      autoClose: 1000,
      progressClassName: "progress",
    });
  };

  const dfs = parseISO(props.time);

  const t2 = format(dfs, "MMM dd, yyyy");

  const eventData = props.eventData;

  return (
    <div className="relative max-w-full mx-1 md:mx-auto md:max-w-lg ">
      <div
        className={`max-w-full mx-1 md:mx-auto rounded-md shadow-md overflow-visible mb-5 cursor-pointer md:max-w-lg bg-lbeigec1`}
        onClick={() => navigate(`/events/${props.id}`)}
      >
        <div className="">
          <div className="rounded-t-lg min-h-16 bg-gray-200 max-h-44">
            <img
              className="h-full w-full max-h-44 rounded-t-md object-cover"
              src={props.image}
              alt={`Chorus meditation teacher ${props.teacher?.first_name}`}
            />
          </div>
          <div className="p-4">
            <div className="font-semibold overflow-hidden truncate w-1/2 text-charcoal">
              {props.title}
            </div>

            <div className="opacity-50 text-sm mt-2 font-medium">
              <span className="text-sm font-medium">{props.teacher?.first_name}</span>
              {eventData?.duration > 180 ? "" : ` . ${props.duration} Min`}
            </div>

            <div className="mt-2 text-sm opacity-50">
              {eventData.duration > 180 ? (
                <EventListDate startTime={eventData.start_time} endTime={eventData.end_time} />
              ) : (
                <LiveClassDate startTime={props.time} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-0 mr-2 mt-16">
        <div className="flex items-center">
          <div className="text-sm text-white font-semibold mr-2">EVENT</div>
          <div className="h-10 w-10 rounded-lg bg-coral p-2 mr-2">
            <Calendar strokeWidth="2.75" cn="h-6 w-6 -mt-px -ml-px" />
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-0 mr-4">
        {props.booked ? (
          <div className="hidden sm:inline-flex px-3 items-center rounded-md text-sm bg-coral text-white">
            <Lightning cn={"mr-1"} />
            Booked
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={`absolute bottom-2 right-0 mr-2 mb-3 md:m-4`} onClick={(e) => shareEvent(e)}>
        <Share
          cn={`h-5 w-7 inline-block hover-black cursor-pointer  ${
            props.ondemand ? "text-white" : "text-gray-600"
          }`}
        />
      </div>
    </div>
  );
}
