import React from "react";

export default function LogoWhite() {
  return (
    <svg
      width="161"
      height="41"
      viewBox="0 0 161 41"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.1066 10.4475C49.9537 10.3942 48.8029 10.5915 47.7336 11.0257C46.6644 11.4599 45.702 12.1208 44.9129 12.9627V0.324829H39.3386V39.7476H44.8819V21.3983C44.8819 17.6216 46.7013 15.4624 49.8601 15.4624C53.0189 15.4624 54.6835 17.4049 54.6835 21.2358V39.7476H60.2423V20.7018C60.2423 14.0849 56.9984 10.4475 51.1066 10.4475Z"
        fill=""
      />
      <path
        d="M132.244 29.0598C132.244 32.7823 130.402 34.9957 127.32 34.9957C123.449 34.9957 122.605 31.9001 122.605 29.2223V10.7647H117.054V29.8105C117.054 36.4197 120.283 40.0648 126.143 40.0648C129.596 40.0648 131.455 38.5789 132.298 37.6192V39.7475H137.842V10.7647H132.244V29.0598Z"
        fill=""
      />
      <path
        d="M154.696 22.923C153.922 22.6598 153.202 22.4509 152.49 22.2497C151.778 22.0485 150.941 21.8085 150.167 21.5377C148.286 20.8566 146.707 19.8119 146.707 18.148C146.707 16.1281 148.867 14.8743 151.151 14.8743C154.247 14.8743 155.022 17.2967 155.169 19.332V19.5565H160.333V19.3166C160.279 13.6748 156.965 10.4476 151.251 10.4476C145.747 10.4476 142.054 13.6671 142.054 18.4653C142.054 22.2265 143.741 24.6565 147.365 26.2508C148.393 26.6745 149.448 27.0287 150.523 27.311C151.182 27.5045 151.801 27.6825 152.443 27.9069C154.704 28.6808 155.587 29.803 155.587 31.8306C155.6 32.2928 155.52 32.7528 155.349 33.1827C155.179 33.6125 154.922 34.003 154.596 34.3303C153.676 35.1379 152.474 35.5495 151.251 35.4757C148.286 35.4757 146.815 33.866 146.745 30.5537V30.3138H141.326V30.5537C141.264 33.4404 142.1 35.8317 143.687 37.4724C145.274 39.1131 147.868 40.065 151.112 40.065C157.12 40.065 160.999 36.6443 160.999 31.3508C161.06 27.0092 159.164 24.4863 154.696 22.923Z"
        fill=""
      />
      <path
        d="M90.4367 9.20944C89.8077 10.6113 89.0888 11.971 88.2844 13.2802C87.9205 13.0093 87.4405 12.6765 87.0534 12.4444C84.6151 11.001 81.8274 10.2542 78.9938 10.2852C70.3072 10.2852 64.0051 16.577 64.0051 25.2835C64.0051 33.9899 70.1368 40.2276 78.9009 40.2276C80.8823 40.2676 82.8514 39.907 84.6899 39.1675C86.5284 38.4279 88.1984 37.3246 89.5997 35.9238C91.0011 34.5231 92.1048 32.8537 92.8446 31.0159C93.5845 29.1781 93.9452 27.2099 93.9052 25.2293C93.9495 22.1588 93.0732 19.1453 91.389 16.577C92.4713 14.9908 93.3871 13.2973 94.122 11.5234L90.4367 9.20944ZM78.6687 15.4007H78.9938C81.1711 15.3758 83.2865 16.1246 84.963 17.5135C84.2015 18.3567 83.276 19.0359 82.243 19.5096C81.21 19.9833 80.0913 20.2416 78.9551 20.2686C76.3538 20.2686 75.2854 18.9916 75.2854 17.7301C75.2854 16.4687 76.4157 15.4858 78.63 15.3697L78.6687 15.4007ZM78.9551 35.1508C73.4582 35.1508 69.6104 31.0878 69.6104 25.2757C69.5658 23.2778 70.0749 21.3065 71.0814 19.5798C71.9098 22.3039 74.5576 24.6179 78.8855 24.6179C82.0888 24.548 85.1518 23.2902 87.4792 21.0889C88.007 22.4237 88.2699 23.8483 88.2534 25.2835C88.3076 31.0955 84.4598 35.1508 78.9551 35.1508Z"
        fill=""
      />
      <path
        d="M30.0472 25.4924L29.9001 25.9877C28.2975 31.3431 24.2097 34.4155 18.6895 34.4155C12.6584 34.4155 6.16274 30.0429 6.16274 20.4852C6.16274 13.7832 10.088 6.55489 18.6895 6.55489C24.4032 6.55489 28.2898 9.488 29.6292 14.8125L29.7608 15.331L35.3893 13.3498L35.2732 12.9164C33.2834 5.38629 27.082 0.913116 18.6663 0.913116C13.9022 0.918216 9.3175 2.72981 5.83757 5.9822C2.0207 9.61182 0 14.6035 0 20.4465C0 26.2895 1.97424 31.3431 5.69821 34.934C9.19174 38.257 13.8442 40.0867 18.6663 40.0341C26.7878 40.0341 33.1673 35.3906 35.3041 27.9147L35.428 27.4813L30.0472 25.4924Z"
        fill=""
      />
      <path
        d="M113.276 12.1577C111.994 11.2168 110.445 10.7098 108.855 10.7105C107.839 10.6769 106.829 10.8895 105.912 11.3301C104.996 11.7708 104.199 12.4264 103.591 13.2411V10.7646H98.0396V39.7474H103.598C103.598 39.7474 103.598 21.0731 103.598 20.8486C103.579 20.2255 103.688 19.605 103.917 19.0254C104.147 18.4458 104.493 17.9193 104.934 17.4785C105.375 17.0376 105.902 16.6917 106.482 16.4621C107.061 16.2325 107.682 16.124 108.306 16.1433C109.274 16.1412 110.219 16.4415 111.008 17.0023L113.276 12.1577Z"
        fill=""
      />
    </svg>
  );
}
