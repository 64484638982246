import React from "react";

function Share(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      className={props.cn}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="currentColor"
        className={props.pcn}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 13v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8L9 1m0 0L5 5m4-4v12"
      ></path>
    </svg>
  );
}

export default Share;
