import { format, parseISO, fromUnixTime, formatDistance } from "date-fns";

// ****************************************************************************
// DATE STUFFS
// ****************************************************************************

export function classDateDiff(dfs) {
  // const dfs = parseISO(startTime);
  return formatDistance(dfs, new Date());
}

// TODO this is duplicated in ponents LiveClassDate
export function classStartDisplay(startTime) {
  const dfs = parseISO(startTime);
  const now = new Date();
  if (
    dfs.getFullYear() === now.getFullYear() &&
    dfs.getMonth() === now.getMonth() &&
    dfs.getDate() === now.getDate()
  ) {
    return `Today ${format(dfs, "haaa")}`;
  }
  return format(dfs, "EE haaa LLLL dd, yyyy");
}

// Return just the data from a unix timestamp aka from stripe
export function unixDate(unixtime) {
  const dfs = fromUnixTime(unixtime);
  return format(dfs, "LLLL dd, yyyy");
}

export function shortDate(goTime) {
  const dfs = parseISO(goTime);
  return format(dfs, "LLLL dd, yyyy");
}

// Used in place of proper parent class slots for now
export function classSlotDate(startTime) {
  const dfs = parseISO(startTime);
  let mins = "";
  if (dfs.getMinutes() > 0) {
    mins = `_${format(dfs, "mm")}`;
  }
  const d = `${format(dfs, "eee")}_${format(dfs, "HH")}` + mins;
  return d.toLowerCase();
}
