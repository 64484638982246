export default function Badge(props) {
  return (
    <svg
      className={`h-6 w-6 inline-block ${props.cn}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 37 27"
    >
      <path
        stroke="#5BA7C7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M6.839 25.364A16.52 16.52 0 016.839 2m23.364 0a16.521 16.521 0 010 23.364M12.03 20.17a9.178 9.178 0 010-12.978m12.982 0a9.178 9.178 0 010 12.978m-4.656-6.49a1.836 1.836 0 11-3.672 0 1.836 1.836 0 013.672 0z"
      ></path>
    </svg>
  );
}
