import React, { useState, useEffect } from "react";
import useIsMounted from "hooks/useHasMounted";
import ChevronDoubleDown from "common/icons/ChevronDoubleDown";
import AutoTextArea from "components/InputFields/AutoTextArea";
import SimpleDialog from "./SimpleDialog";
import { navigate } from "@reach/router";

export default function SkipOnBoarding({ onClose, sendFeedBack }) {
  const isMounted = useIsMounted();

  const [feedback, setFeedback] = useState("");

  if (!isMounted) {
    return null;
  }

  return (
    <SimpleDialog onClose={onClose}>
      <div className="rounded-xl relative p-3 pb-6 w-90 bg-white w-full mx-auto">
        <p onClick={onClose} className="text-charcoal text-2xl float-right mr-4 cursor-pointer">
          &times;
        </p>
        <div className="text-center mt-6 px-8 mx-auto">
          <div className="text-center">
            <img
              className="w-28 mx-auto inline-block min-h-60"
              src="https://storage.googleapis.com/chorus-public/img/bill/undraw_in_thought_re_qyxl.png"
              alt=""
            />
          </div>
          <h2 className="text-charcoal mt-6 text-2xl sm:text-2.5xl font-semibold w-64 mx-auto">
            Didn’t want to take class?
          </h2>
          <AutoTextArea
            key="comment-key"
            type="text"
            rows={4}
            name="comment"
            placeholder={"Any feedback you share will help us improve."}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            className="mt-8 w-full pt-3 max-h-60 bg-white border-2 border-solid border-purpc1 rounded-lg text-sm box-shadow-none focus:outline-none  focus:border-purpc1"
          />
        </div>
        <div>
          <p className="text-purp text-center my-4">We promise we read every response.</p>
        </div>
        <div className="w-full text-center px-8">
          <button
            onClick={() => sendFeedBack(feedback)}
            disabled={!feedback}
            className="mt-1 mb-2 focus:outline-none w-full sm:w-84  mx-auto py-3 font-bold rounded-3xl text-xl border-2 border-solid border-coral text-coral hover:opacity-80"
          >
            Share Feedback
          </button>
          <ChevronDoubleDown cn="h-4 w-4 mt-2 mt-4 mb-4 mx-auto" stroke={"#5BA7C7"} />
        </div>
      </div>
      <div className="-mt-8 w-full rounded-b-xl mx-auto px-8 pt-12 pb-8 bg-purpc2 text-center  rounded-b-3xl">
        <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold w-64 mx-auto">
          Want to try somethign else?
        </h2>
        <p className="text-charcoal mt-4 opacity-80 text-sm sm:text-base">
          If you want to check out Chorus, but just didn’t want to watch this video, no worries!
        </p>
        <p className="text-center text-charcoal mb-4 mt-8 font-semibold">You can continue below.</p>

        <button
          onClick={() => navigate("/fbretreg")}
          className="focus:outline-none w-full sm:w-84 mt-4 mx-auto bg-coral py-3 rounded-3xl text-xl font-bold text-white hover:bg-gray-400"
        >
          Click to Continue
        </button>
      </div>
    </SimpleDialog>
  );
}
