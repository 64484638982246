import React from "react";
import useIsMounted from "hooks/useHasMounted";
import SimpleDialog from "../SimpleDialog";
import "./styles.css";

export default function FeedbackSent({ onClose }) {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  return (
    <SimpleDialog onClose={onClose} widthClass="w-full max-w-md w-82">
      <div className="w-full bg-white  px-3 pt-4 pb-12  rounded-xl">
        <p onClick={onClose} className="text-charcoal text-2xl float-right mr-4 cursor-pointer">
          &times;
        </p>
        <div className="text-center mt-6 px-8 mx-auto">
          <div className="text-center">
            <img
              className="w-40 mx-auto inline-block min-h-20"
              src="https://storage.googleapis.com/chorus-public/img/bill/undraw_positive_attitude_xaae.png"
              alt=""
            />
          </div>
          <p className="text-purp mt-4">Your opinion is very important to us</p>
          <p className="text-purp mt-4">We promise we read every piece of feedback.</p>
        </div>
        <div className="w-full text-center mt-4">
          <div class="check-circle mx-auto">
            <div class="check-circle__mark"></div>
          </div>
        </div>
      </div>
    </SimpleDialog>
  );
}
