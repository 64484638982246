import React, { useState } from "react";
import AppStore from "common/icons/AppStore";
import { setAppBannerFlagFromStorage } from "../../common/Storage";
import { Dialog } from "@headlessui/react";

export default function DownloadApp() {
  const [showDialog, setShowDialog] = useState(true);

  const hideDownloadAppBanner = () => {
    setShowDialog(false);
    setAppBannerFlagFromStorage();
  };

  return showDialog ? (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className="w-full h-screen absolute top-0 z-50 overflow-y-scroll pt-70-p pb-8"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <div className="rounded-large p-2 bg-white w-full max-w-md mx-auto">
          <p onClick={hideDownloadAppBanner} className="text-charcoal text-2xl float-right mr-4">
            &times;
          </p>
          <div className="text-center mt-6 w-52 mx-auto">
            <h2 className="text-charcoal text-4xl font-semibold mx-auto">Download our App</h2>
            <p className="text-charcoal mt-4">It’s available in the App Store.</p>
          </div>
          <div className="text-center mt-4">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/chorus-meditation/id1557561444#?platform=iphone"
            >
              <AppStore cn="w-32" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://chorusmeditation.typeform.com/to/IlMHunXQ"
            >
              <img
                className="h-20 mt-4 mx-auto"
                src="https://storage.googleapis.com/chorus-public/img/land/android.png"
              />
            </a>
          </div>
          <div className="text-center mt-8">
            <img
              className="w-64 inline-block"
              src="https://storage.googleapis.com/chorus-public/img/fblanding/iphone.png"
              alt=""
            />
          </div>
        </div>
      </Dialog>
    </div>
  ) : (
    <></>
  );
}
