import React, { Component, Suspense, lazy } from "react";
import { Link, Router } from "@reach/router";
import axios from "axios";
import { navigate } from "@reach/router";
import { isTablet, isIOS, isMobileOnly } from "react-device-detect";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import { ToastContainer } from "react-toastify";

import Footer from "components/Footer/index";
import LoadingScreen from "components/LoadingScreen";

// contexts
import withScheduleContext from "./consumers/ScheduleConsumerHOC";
import withUserContext from "./consumers/UserConsumerHOC";

// common
import { getAppBannerFlagFromStorage } from "common/Storage";

// custom styling class
import "./styles/index.css";
// Toastify styling
import "react-toastify/dist/ReactToastify.css";

import firebaseDefaultConfig from "./firebaseDefaultConfig.json";
import {
  apiBase,
  formAndSaveUserObject,
  handleDeviceIDAndAppLoad,
  handleUserState,
  refreshUser,
} from "common/Init";
import {
  clearLiveFilters,
  clearOdFilters,
  getAndClearLoginRedirect,
  saveLoginRedirect,
  storeGet,
} from "common/Params";
import { identify, page } from "common/Ana";
import LogoWhite from "common/icons/LogoWhite";
import Notifications from "common/icons/Notifications";
import { handleError2 } from "apiClient/errors";

import DownloadApp from "components/Dialogs/DownloadApp";

// ALWAYS load pages (for now) since these are outside of the lazyload block
import TrialExpire from "views/TrialExpire/index";
// const TrialExpire = lazy(() => import("views/TrialExpire")); // Lazy-loaded
import PUF from "views/PUF/index";
import EventList from "views/EventList/index";
import Community from "views/Community/index";
import Fbretgt from "components/Fbretgt/index";
import OnboardB from "views/OnboardB";

// Lazy loaded Pages
const LoginPage = lazy(() => import("views/LoginPage")); // Lazy-loaded
const Register = lazy(() => import("views/Register")); // Lazy-loaded

const UserRouter = lazy(() => import("./UserRouter")); // Lazy-loaded
const OnboardA = lazy(() => import("views/OnboardA")); // Lazy-loaded
const FbRetReg = lazy(() => import("views/FbRetReg")); // Lazy-loaded
const HomePage = lazy(() => import("./Home")); // Lazy-loaded
const ClassPage = lazy(() => import("views/ClassPage")); // Lazy-loaded
const OnDemands = lazy(() => import("./OnDemands")); // Lazy-loaded
const OnDemandPage = lazy(() => import("views/OnDemandPage/index")); // Lazy-loaded
const LoginWithCodePage = lazy(() => import("./LoginWithCode")); // Lazy-loaded
const LoginSendCodePage = lazy(() => import("./LoginSendCode")); // Lazy-loaded
const OnDemandLite = lazy(() => import("views/OnDemandLite")); // Lazy-loaded
const FBLand = lazy(() => import("views/FBLand")); // Lazy-loaded
const Welcome = lazy(() => import("views/Welcome")); // Lazy-loaded
const Offer = lazy(() => import("views/Offer")); // Lazy-loaded
const FBLandPuf = lazy(() => import("views/FBLandPuf")); // Lazy-loaded
const FBRegPuf = lazy(() => import("views/FBRegPuf")); // Lazy-loaded
const EventPage = lazy(() => import("views/EventPage")); // Lazy-loaded

class App extends Component {
  state = {
    loadingFilters: true,
    odFilters: firebaseDefaultConfig.od_filters,
    liveFilters: firebaseDefaultConfig.live_filters,
  };

  // store our current location and
  startSignIn = (params) => {
    saveLoginRedirect(window.location.pathname, params);
    navigate("/register");
  };

  setRemoteConfig = () => {
    // creating the singleton Remote Config object
    let remoteConfig = getRemoteConfig();
    // setting a minimum fetch interval
    remoteConfig.settings.minimumFetchIntervalMillis = 30000;
    // here we are setting the defaults from a json file for remote config
    remoteConfig.defaultConfig = {
      od_filters: firebaseDefaultConfig.od_filters,
      live_filters: firebaseDefaultConfig.live_filters,
      settings: firebaseDefaultConfig.settings,
    };
    // fetching values from the Remote Config backend
    fetchAndActivate(remoteConfig)
      .then((fetchedRemotely) => {
        // we might need these if else lately, so keeping it for now
        if (fetchedRemotely) {
          // Configs were retrieved from the backend and activated
          // and it will override the default values with retreived values
        } else {
          // 'No configs were fetched from the backend
        }
        const liveFiltersData = getValue(remoteConfig, "live_filters").asString();
        const liveFilters = liveFiltersData ? JSON.parse(liveFiltersData) : {};

        const odFiltersData = getValue(remoteConfig, "od_filters").asString();
        const odFilters = odFiltersData ? JSON.parse(odFiltersData) : {};

        this.setState({ loadingFilters: false, odFilters, liveFilters });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async componentDidMount() {
    // Clear out all filters when the app initializes
    clearLiveFilters();
    clearOdFilters();
    this.setRemoteConfig();

    const auth = getAuth();

    // FIRST get the teachers, and make sure they get STUFFED into the stupid
    // user object for now!
    // TODO get these from remote config/update at some interval
    axios({
      method: "GET",
      url: `${apiBase()}/teachers`,

      headers: { "X-APP-ID": "wapp_2021" },
      // headers: { Authorization: "Bearer " + this.props.user.token },
      data: {},
    })
      .then(async (resp) => {
        const user = await handleUserState(resp.data.teachers);

        if (user?.firebase_token) {
          signInWithCustomToken(auth, user?.firebase_token)
            .then((userCredential) => {})
            .catch((error) => {
              console.log("error", error);
            });
        }

        // Call/check shouldShowOBM after the state update
        this.props.setUserWithCallBack(user, true);
        this.props.setLoading(false);
        // Identify the user on app load and/or on login/reg
        if (user.token) {
          identify(user);
        }
        handleDeviceIDAndAppLoad();
      })
      .catch(handleError2);

    // THIS needs to always send back a valid user shell even if we're not logged in!
  }

  // Set from users api calls
  // Pass null if the user needs to be refreshed in case of new subscription or similar
  setUserData = async (userBookData, wasLogin, registered = false) => {
    if (userBookData) {
      this.props.setUserWithCallBack(
        formAndSaveUserObject(userBookData, userBookData.teachers),
        registered
      );
    } else {
      // We might again want to do this here and there to sync their data
      // as they could be making changes in ios and having the webapp
      // tab open for a long time
      // BUT if they are not logged in we cant refresh their user
      if (!this.props.user.token) {
        return;
      }
      const user = await refreshUser(this.props.user.token, this.props.user.teachers);
      this.props.setUserData(user);
      this.props.setLoading(false);
    }
    if (wasLogin) {
      let redir = getAndClearLoginRedirect();
      if (!redir?.redir) {
        navigate("/");
        return;
      }
      if (redir.params) {
        navigate(redir.redir, { state: redir.params });
      } else {
        navigate(redir.redir);
      }
    }
  };

  isFbretgtFlow = () => {
    const isFbretgt = storeGet("fbretgt");
    if (
      window.location.pathname === "/fbretgt" ||
      (isFbretgt &&
        (window.location.pathname === "/onboardb" || window.location.pathname?.includes("/odb")))
    ) {
      return true;
    }
    return false;
  };

  constructor(props) {
    super();
  }

  render() {
    if (this.props.loading || !this.props.user) {
      return (
        <>
          <Header />
          <LoadingScreen cn="-z-1" />
        </>
      );
    }

    const { shouldShowNav, shouldShowFooter } = this.props;

    const u = this.props.user;

    const showDownloadAppDialog =
      (isTablet || isMobileOnly) && isIOS && !getAppBannerFlagFromStorage();

    let appStoreLink = "https://apps.apple.com/jm/app/chorus-meditation/id1557561444#?platform=";
    if (showDownloadAppDialog) {
      appStoreLink = `${appStoreLink}${isTablet ? "ipad" : "iphone"}`;
    }

    // If they are logged in but have an inactive account, we want to gate hard for now.
    // This will likely change soon as people should be able to donate, join chorus for a cause
    // and possibly interact with the community
    if (
      u.status === "inactive" &&
      u.status2 !== "free" &&
      window.location.pathname !== "/offer" &&
      !this.isFbretgtFlow()
    ) {
      return (
        <>
          <Header user={this.props.user} />
          <div className="flex-grow -mt-42">
            {u.status2 === "new_puf" ? (
              <PUF user={this.props.user} setUserData={this.setUserData} />
            ) : (
              <TrialExpire user={this.props.user} setUserData={this.setUserData} />
            )}
          </div>
        </>
      );
    }

    return (
      <>
        {showDownloadAppDialog && storeGet("book") !== "true" && (
          <DownloadApp key="downloadPopup" link={appStoreLink} />
        )}
        <div className="min-h-screen flex flex-col w-full">
          <Header showNav={shouldShowNav} logout={this.logout} user={this.props.user} />

          <ToastContainer />
          <div className="flex-grow -mt-42 min-h-120">
            <Suspense
              fallback={
                <>
                  <LoadingScreen cn="-z-1" />
                </>
              }
            >
              <Router primary={false}>
                <HomePage
                  path="/"
                  user={this.props.user}
                  setUserData={this.setUserData}
                  startSignIn={this.startSignIn}
                  loadingFilters={this.state.loadingFilters}
                  liveFilters={this.state.liveFilters}
                />
                <HomePage
                  path="/live"
                  user={this.props.user}
                  setUserData={this.setUserData}
                  startSignIn={this.startSignIn}
                  loadingFilters={this.state.loadingFilters}
                  liveFilters={this.state.liveFilters}
                />
                <Community path="/community" />
                <EventList
                  path="/events"
                  user={this.props.user}
                  setUserData={this.setUserData}
                  startSignIn={this.startSignIn}
                />
                <LoginPage path="/login" user={this.props.user} setUserData={this.setUserData} />
                <LoginWithCodePage
                  path="/login-with-code"
                  user={this.props.user}
                  setUserData={this.setUserData}
                />
                <LoginSendCodePage
                  path="/login-send-code"
                  user={this.props.user}
                  setUserData={this.setUserData}
                />

                <Register path="/register" user={this.props.user} setUserData={this.setUserData} />

                <FBLand path="/fbland" user={this.props.user} setUserData={this.setUserData} />
                {/* <FBLandPuf
                  path="/fblandpuf"
                  user={this.props.user}
                  setUserData={this.setUserData}
                /> */}
                <FBLand path="/fblandpuf" user={this.props.user} setUserData={this.setUserData} />
                <Fbretgt path="/fbretgt" user={this.props.user} setUserData={this.setUserData} />
                <FbRetReg path="/fbretreg" user={this.props.user} setUserData={this.setUserData} />
                <Register path="/fbreg" user={this.props.user} setUserData={this.setUserData} />
                <FBRegPuf path="/fbregpuf" user={this.props.user} setUserData={this.setUserData} />
                {/* This is only for debugging, we should never actually route here */}
                <PUF path="/puf" user={this.props.user} setUserData={this.setUserData} />
                <Welcome path="/welcome" user={this.props.user} setUserData={this.setUserData} />
                <TrialExpire
                  path="/trial-expired"
                  user={this.props.user}
                  setUserData={this.setUserData}
                />

                <OnboardA path="/onboarda" user={this.props.user} setUserData={this.setUserData} />

                <OnboardB path="/onboardb" user={this.props.user} setUserData={this.setUserData} />

                <Offer path="/offer" user={this.props.user} setUserData={this.setUserData} />

                <ClassPage
                  path="/classes/:classID"
                  user={this.props.user}
                  setUserData={this.setUserData}
                  startSignIn={this.startSignIn}
                  // teachers={this.state.teachers}
                />
                <OnDemands
                  path="/on-demand"
                  user={u}
                  loadingFilters={this.state.loadingFilters}
                  odFilters={this.state.odFilters}
                />
                <EventPage
                  path="/events/:eid"
                  user={u}
                  startSignIn={this.startSignIn}
                  setUserData={this.setUserData}
                />

                {/* If ios app is installed, this route will go to the app */}
                <EventPage
                  path="/events-view/:eid"
                  user={u}
                  startSignIn={this.startSignIn}
                  setUserData={this.setUserData}
                />
                <OnDemandPage
                  path="/on-demand/:odid"
                  user={u}
                  startSignIn={this.startSignIn}
                  setUserData={this.setUserData}
                />
                {/* on demand onboarding */}
                <OnDemandLite
                  path="/odb/:odid"
                  isLite={true}
                  user={u}
                  startSignIn={this.startSignIn}
                  setUserData={this.setUserData} // Need to set after they watch 50%+
                />
                <UserRouter
                  path="/user/*"
                  user={u}
                  setUserData={this.setUserData}
                  startSignIn={this.startSignIn}
                />
                <NotFound default />
              </Router>
            </Suspense>
          </div>
          {!!shouldShowFooter && <Footer />}
        </div>
      </>
    );
  }
}

function Header(props) {
  const unreadNotifications = props?.user?.notifications?.num_unread || 0;
  return (
    <header className="bg-coral h-76 text-center">
      <div className="inline-block p-4">
        {props.showNav ? (
          <a target="_blank" rel="noreferrer" href="https://chorusmeditation.com">
            <LogoWhite />
          </a>
        ) : (
          <span>
            <LogoWhite />
          </span>
        )}
      </div>
      {props?.user && props?.user?.id && (
        <Link to="/user/messages" className="header-notifications flex">
          {props.showNav ? (
            <>
              <Notifications cn="h-7 w-7" />
              {unreadNotifications ? (
                <div className="bg-white ml-1 w-5 h-6 rounded-md text-coral mt-px font-semibold">
                  {unreadNotifications}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Link>
      )}

      {props.showNav ? <AppNav {...props} /> : ""}
    </header>
  );
}

function isActiveLink(curPage, linkPath) {
  if (curPage === "/" && linkPath === "/") {
    return true;
  }
  // Home page will flag as partially current very easily
  if (linkPath === "/") {
    if (curPage.startsWith("/classes")) {
      // Aka live classes so the left most home page link is active
      return true;
    }
    return false;
  }
  if (curPage.startsWith(linkPath)) {
    return true;
  }
  return false;
}

const NavLink = (props) => {
  return (
    <div className="inline-block relative">
      <Link
        {...props}
        getProps={(gprops) => {
          // console.log(gprops);
          //               /user/billing              /user
          // So need to do a partial match
          if (isActiveLink(gprops.location.pathname, gprops.href)) {
            return {
              // navactive is a custom css class!!!
              className: "block px-4 navactive font-bold",
            };
          }
          return {
            className: "block px-4 hover:font-semibold",
          };
        }}
      />
    </div>
  );
};

// Nav just for logged and paid users
function AppNav(props) {
  return (
    <nav className="flex justify-center w-full mb-3 text-white text-sm">
      <NavLink to="/">Classes</NavLink>
      <NavLink to="/community">Community</NavLink>
      <NavLink to="/user">
        <div className="flex">
          Account
          {/* {showNotificationDot && <span className="text-white mx-auto text-lg -m-3.5">●</span>} */}
        </div>
      </NavLink>
    </nav>
  );
}

function NotFound() {
  return (
    <div className="text-center p-5">
      <h1 className="font-medium text-2xl mb-12 text-center">Page Not Found</h1>

      <Link to="/">View Class List</Link>
    </div>
  );
}

export default withUserContext(withScheduleContext(App));
