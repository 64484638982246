import React, { useContext, useEffect, useState } from "react";
import * as halps from "common/Halps";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { getEnv } from "common/constants";
import { navigate } from "@reach/router";
import ScheduleContext from "contexts/ScheduleContext";
import { toast } from "react-toastify";
import wait from "common/wait";
import { storeGet, storeSet } from "common/Params";
import { scrollToTop } from "common/Ponents";
import SkipOnboardingSteps from "views/OnDemandLite/SkipOnboardingSteps";
import {
  clkOBBMoodSkipTrack,
  clkRetGtBMoodSkipTrack,
  clkOBBTimeSkipTrack,
  clkRetGtBTimeSkipTrack,
  clkMoodTrack,
} from "common/Ana";

export default function OnboardB(props) {
  const [selectedMood, setSelectedMood] = useState("");
  const { hideNav, showNav } = useContext(ScheduleContext);
  const [showSkipOnBoarding, setShowSkipOnBoarding] = useState(false);

  let isFbretgt = storeGet("fbretgt");

  const showSuccessMsg = async () => {
    let msg = isFbretgt ? "Welcome back, friend!" : "Account Created!";
    await wait(100);
    toast.info(msg, {
      autoClose: 1500,
      progressClassName: "progress",
    });
  };

  useEffect(() => {
    if (selectedMood === "sleep") {
      onTimeSelected(15);
    }
  }, [selectedMood]);

  useEffect(() => {
    // making sure that ob in att is var_b
    let attr = storeGet("attr");
    attr.ob = "var_b";
    storeSet("attr", attr);

    halps.pageView(isFbretgt ? "Fbretgt B Mood" : "Onboard B Mood", halps.getEntryFlow());
    hideNav();
    scrollToTop();
    if (props.user?.token) {
      showSuccessMsg();
    }

    // return () => {
    //   showNav();
    // };
  }, []);

  const onTimeSelected = (key) => {
    if (key === 0) {
      navigate("onboarda");
      return;
    }
    halps.pageView("Onboard B Time", {
      mood: selectedMood,
      entry_flow: isFbretgt ? `retgt_b` : `onb_b`,
    });

    const id = getOdId(key);
    storeSet("variantData", { variant: "variantB", time: key, mood: selectedMood });
    navigate(`/odb/${id}`);
  };

  // Clears there selection
  const goBack = () => {
    setSelectedMood("");
  };

  const getOdId = (time) => {
    const remoteConfig = getRemoteConfig();
    const onboardingConfig = getValue(remoteConfig, "onboarding").asString();
    const onboardingConfigObj = onboardingConfig && JSON.parse(onboardingConfig);

    let odId = "";
    if (onboardingConfigObj) {
      odId =
        time === 15
          ? onboardingConfigObj[getEnv()]["variantB"][selectedMood][0]
          : onboardingConfigObj[getEnv()]["variantB"][selectedMood][time][0];
    }
    return odId || "";
  };

  const handleSkip = () => {
    if (isFbretgt) {
      !selectedMood ? clkRetGtBMoodSkipTrack() : clkRetGtBTimeSkipTrack();
      setShowSkipOnBoarding(true);
    } else {
      !selectedMood ? clkOBBMoodSkipTrack() : clkOBBTimeSkipTrack();
      showNav();
      navigate(`/on-demand`);
    }
  };

  const createOD = (key, value, subValue, bgColor, headingColor, textColor) => {
    return (
      <div
        onClick={() => {
          setSelectedMood(key);
          const entryFlowData = halps.getEntryFlow();
          clkMoodTrack(isFbretgt ? "Fbretgt" : "Onboard", {
            entry_flow: entryFlowData.entry_flow,
            mood: key,
          });
        }}
        className={`mt-4 mx-auto h-20 w-48 p-1 ${bgColor} rounded-xl cursor-pointer`}
      >
        <div className="text-center rounded-lg  px-3 py-2 w-full">
          <div>
            <p className={`font-bold ${headingColor}`}>{value}</p>
            <p className={`text-sm ${textColor} mt-1`}>{subValue}</p>
          </div>
        </div>
      </div>
    );
  };

  const createODTime = (key, value, subValue, bgColor = "bg-white", headingColor, textColor) => {
    return (
      <div
        onClick={() => onTimeSelected(key)}
        className={`mt-4 mx-auto w-48  p-1  ${bgColor} rounded-xl cursor-pointer h-20`}
      >
        <div className="text-center px-3 py-2 w-full">
          {key ? (
            <div>
              <p className={`font-semibold text-xl ${headingColor}`}>{value}</p>
              <p className={`text-sm ${textColor} mt-1`}>{subValue}</p>
            </div>
          ) : (
            <p className="w-48 text-lg text-left">Not much, let’s book for later</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="text-center m-2 -mt-16">
        {showSkipOnBoarding && (
          <SkipOnboardingSteps user={props.user} onClose={() => setShowSkipOnBoarding(false)} />
        )}
        <h1 className="font-bold text-xl mt-4 text-white">🎉 Welcome to Chorus! 🎉</h1>
        <div className="relative mt-6 pt-2 mx-auto max-w-screen-lg rounded-xlarge bg-white px-2 sm:px-0">
          <div className="sm:w-184 mx-auto">
            {selectedMood && selectedMood !== "sleep" ? (
              <div>
                <p
                  className="mx-auto text-center text-sm mt-4 underline cursor-pointer"
                  onClick={goBack}
                >
                  Go back
                </p>
                <div>
                  <p className="text-4xl sm:text-6xl mx-auto font-bold text-charcoal mt-3 leading-10 md-leading-14 lg:leading-tight">
                    How much time do you have now?
                  </p>
                </div>
                <div className="sm:flex sm:justify-between items-center sm:w-132 mx-auto mt-6">
                  {createODTime(
                    30,
                    "30 minutes",
                    "(Recommended)",
                    "bg-coral",
                    "text-white",
                    "text-white"
                  )}
                  {createODTime(10, "10 minutes", "", "bg-c1_3", "text-purp", "text-purpc1")}
                  <div className="h-20 sm:hidden"></div>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <p className="text-4xl sm:text-6xl sm:w-152 mx-auto font-bold text-charcoal mt-8 leading-10 md-leading-14 lg:leading-tight">
                    What are you in the mood for?
                  </p>
                </div>
                <div className="sm:flex sm:justify-between items-center mt-6">
                  {createOD(
                    "upbeat",
                    "Upbeat",
                    "Energize, focus",
                    "bg-coral",
                    "text-white",
                    "text-white"
                  )}
                  {createOD(
                    "relaxing",
                    "Relaxing",
                    "Unwind after work",
                    "bg-lbeige",
                    "text-purp",
                    "text-purpc1"
                  )}
                  {createOD("sleep", "Sleep", "Zzzzzzzzz", "bg-lbeige", "text-purp", "text-purpc1")}
                </div>
              </div>
            )}
            <div className="mt-68 sm:mt-10 pb-12">
              <p
                className="text-sm text-purp hover:text-coral pb-4 cursor-pointer"
                onClick={handleSkip}
              >
                Skip
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
