import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import useIsMounted from "hooks/useHasMounted";

export default function SimpleDialog({ onClose, widthClass, children, key }) {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  return (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className={`fixed inset-0 z-40 flex items-center justify-center mx-auto ${
          widthClass || "max-w-sm w-full-mobile sm:max-w-md"
        }`}
        open={true}
        onClose={onClose}
        key={key || "simpleDialog"}
      >
        <div className="overflow-y-auto max-h-95 px-2 pt-2 sm:pt-0 sm:px-0">{children}</div>
      </Dialog>
    </div>
  );
}
