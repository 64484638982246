export default function Plus({ cn, strokeWidth, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn || "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      stroke={stroke || "currentColor"}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth || 2}
        d="M12 4v16m8-8H4"
      />
    </svg>
  );
}
