import { clkGoopTrack, clkNYTTrack, clkPopSugarTrack, clkRunnersWorldTrack } from "common/Ana";
import React from "react";

export default function Featured(props) {
  return (
    <div className="text-center bg-purpc2 rounded-3xl xl:px-8">
      <div className="w-full sm:flex sm:justify-between sm:items-center py-10 sm:py-16 px-4 sm:px-8">
        <a
          onClick={() => {
            clkNYTTrack();
            return true;
          }}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          href="https://www.nytimes.com/2019/11/15/business/the-work-diary-of-an-audio-erotica-ceo.html"
        >
          <img
            className="mx-auto sm:mx-0 w-60 sm:w-48 lg:w-60"
            src="https://storage.googleapis.com/chorus-public/img/land/nyTimes.png"
          />
        </a>
        <a
          onClick={() => {
            clkGoopTrack();
            return true;
          }}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          href="https://goop.com/wellness/health/best-online-tools-for-mental-health/"
        >
          <img
            className="mt-10 sm:mt-0 mx-auto sm:mx-0 w-24 sm:w-16 lg:w-24"
            src="https://storage.googleapis.com/chorus-public/img/etc/goop.png"
          />
        </a>
        <a
          onClick={() => {
            clkPopSugarTrack();
            return true;
          }}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          href="https://www.popsugar.com/smart-living/popular-activities-during-covid-48073371"
        >
          <img
            className="mt-12 sm:mt-0 mx-auto sm:mx-0 w-68 sm:w-56 lg:w-68"
            src="https://storage.googleapis.com/chorus-public/img/etc/popsugar.png"
          />
        </a>
        <a
          onClick={() => {
            clkRunnersWorldTrack();
            return true;
          }}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          href="https://www.runnersworld.com/health-injuries/g32392438/best-mental-health-apps/"
        >
          <img
            className="mt-12 sm:mt-0 mx-auto sm:mx-0 w-48 sm:w-40 lg:w-48"
            src="https://storage.googleapis.com/chorus-public/img/etc/runnersworld.png"
          />
        </a>
      </div>
    </div>
  );
}
