import React, { useEffect } from "react";
import AppStore from "common/icons/AppStore";

import { pageView } from "../../common/Halps";
import { scrollToTop } from "common/Ponents";

const Community = (props) => {
  useEffect(() => {
    pageView("Community");
    scrollToTop();
  }, []);

  return (
    <div className="rounded-large p-2 bg-white w-full max-w-md mx-auto">
      <div className="text-center mt-6 w-80 mx-auto">
        <h2 className="text-charcoal text-2xl font-semibold mx-auto">
          Please get our mobile apps for the full experience
        </h2>
        {/* <p className="text-charcoal mt-4">It’s available in the Apple App Store.</p> */}
      </div>
      <div className="text-center mt-4">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/us/app/chorus-meditation/id1557561444#?platform=iphone"
        >
          <AppStore cn="w-32" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://chorusmeditation.typeform.com/to/IlMHunXQ"
        >
          <img
            className="h-20 mt-4 mx-auto"
            src="https://storage.googleapis.com/chorus-public/img/land/android.png"
          />
        </a>
      </div>
      <div className="text-center mt-8">
        <img
          className="w-64 inline-block"
          src="https://storage.googleapis.com/chorus-public/img/fblanding/iphone.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default Community;
