import React from "react";
import CircleChecked from "common/icons/CircleChecked";
import { RadioGroup } from "@headlessui/react";
import InformationCircle from "common/icons/InformationCircle";
import StripeSecure from "components/StripeSecure/index";
import { clkJoinMembTrack } from "common/Ana";

const CreateRadioButtons = ({
  plan,
  sp,
  plans,
  showCardInputField,
  showCard,
  user,
  last4OrNull,
  showLast4,
  activateSub,
  btnText,
  spin,
  setShowCard,
  rdDisabled,
}) => {
  const CircleEmpty = (props) => {
    return (
      <div {...props}>
        <div className="rounded-full w-full h-full border-2 border-gray box-border"></div>
      </div>
    );
  };
  return (
    <div className="px-1 lg:px-10">
      <RadioGroup value={plan} onChange={sp} disabled={rdDisabled}>
        <div className={`grid grid-cols-1 sm:grid-cols-3 gap-8 sm:gap-4 px-4 mt-4 sm:mt-12`}>
          {plans
            ?.filter((p) => p.status === "active")
            .map((po) => {
              if (po.id === "free") {
                // They are already on a free plan, bail
                return "";
              }
              return (
                <RadioGroup.Option
                  key={po.id}
                  value={po.id}
                  className={({ checked, active, disabled }) =>
                    `sm:max-w-84 mx-auto rounded-3xl bg-white w-full p-3 px-12 sm:px-3 h-32 ${
                      checked ? " ring-2 ring-coral outline-none" : ""
                    } ${active ? " ring-2 ring-coral outline-none" : ""} ${
                      disabled
                        ? " bg-gray-200 text-gray-400 cursor-not-allowed text-opacity-25"
                        : "cursor-pointer "
                    }`
                  }
                >
                  {({ checked, disabled }) => (
                    <div className="relative">
                      <div className="">
                        {checked ? (
                          <CircleChecked
                            stroke="#fff"
                            fill="#EB5757"
                            strokeWidth={1.75}
                            className="w-8 h-8 -mt-1 -mb-1 mx-auto text-coral"
                          />
                        ) : (
                          <CircleEmpty
                            className={`w-6 h-6 mx-auto ${
                              disabled ? "bg-gray-200" : ""
                            } box-border p-0.5`}
                          />
                        )}

                        <div className="text-center">
                          <RadioGroup.Label
                            as="p"
                            className={`mt-3 font-bold text-base  ${checked ? "" : ""}`}
                          >
                            {po.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`mt-3 text-sm inline ${checked ? "" : ""}`}
                          >
                            {po.desc}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {po.id === user.member_plan ? (
                        <div className="absolute w-40 mx-auto -bottom-12 lg-bottom-8 lg:-bottom-12 left-1/2 transform -translate-x-1/2  rounded-lg bg-purpc1 text-white text-sm px-3 py-2">
                          Current Plan
                        </div>
                      ) : (
                        po.id === "6mo" && (
                          <div className="absolute w-24 -bottom-12 lg-bottom-8 lg:-bottom-12 left-1/2 transform -translate-x-1/2  rounded-lg bg-cblue text-white text-sm px-3 py-2">
                            50% off
                          </div>
                        )
                      )}
                    </div>
                  )}
                </RadioGroup.Option>
              );
            })}
        </div>

        {/* show update button only if the selected plan is not equal to currect user plan */}
        {user.member_plan !== plan ? (
          showCardInputField || showCard ? (
            !showCard && (
              <button
                className={`inline-block mt-10 mb-4 bg-coral py-2 px-12 rounded-3xl text-xl font-bold text-white hover:bg-gray-400 ${
                  spin ? "pointer-events-none bg-gray-200" : ""
                }`}
                onClick={() => {
                  setShowCard(true);
                  clkJoinMembTrack();
                }}
              >
                Join the Community
              </button>
            )
          ) : (
            <>
              {!!last4OrNull && showLast4 && (
                <div className="p-2 mt-8 -mb-8">
                  <p className="text-charcoal">
                    We'll use your default card ending in ({last4OrNull})
                  </p>
                </div>
              )}
              <button
                className={`inline-block mt-10 mb-4 bg-coral py-2 px-12 rounded-3xl text-xl font-bold text-white hover:bg-gray-400 ${
                  spin ? "pointer-events-none bg-gray-200" : ""
                }`}
                onClick={() => activateSub(plan)}
              >
                {btnText || "Update Plan"}
              </button>
            </>
          )
        ) : (
          <div className="mt-10"></div>
        )}

        {/* only show free tier button if the current active plan is not free */}
        {showCard || user.member_plan === "free" ? (
          <></>
        ) : (
          <>
            <div className="mb-8">
              <StripeSecure lockClass="w-4 mr-2" secureClass="text-base" powerClass="w-32" />
            </div>
            <p
              className={`mt-4 text-charcoal hover:text-coral cursor-pointer ${
                spin ? "pointer-events-none text-gray-200" : ""
              }`}
              onClick={() => activateSub("free")}
            >
              Downgrade to Free Tier
            </p>
            <div className="flex items-center justify-center">
              <p className="text-purp text-sm mr-1">$10 per class</p>

              <div className={`has-tooltip`}>
                <div className="tooltip rounded shadow-lg text-sm bg-white text-center w-36 p-2 bg-black text-purp font-medium ml-5 -mt-10">
                  Cancels current Subscription
                </div>
                <InformationCircle cn="h-5 w-5 cursor-pointer" stroke={"#B5AEC1"} />
              </div>
            </div>
          </>
        )}
      </RadioGroup>
    </div>
  );
};

export default CreateRadioButtons;
