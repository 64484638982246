import { navigate } from "@reach/router";
import { saveLoginRedirect } from "../../common/Params";

export function AlreadyLoggedIn() {
  return (
    <div className="bg-ctan md:w-148 mx-1 md:mx-auto mt-2 md:mt-5 shadow-md p-2 md:p-8 rounded-md">
      <h2 className="font-medium text-2xl">You are already signed in</h2>

      <p className="mt-5">
        Please{" "}
        <span onClick={() => navigate("/user")} className="underline text-cblue cursor-pointer">
          go here
        </span>{" "}
        to book a class
      </p>
      <p className="mt-5">
        <span onClick={() => navigate("/user")} className="underline text-cblue cursor-pointer">
          Or here
        </span>{" "}
        to review your account
      </p>
      <p className="mt-5">
        If you are having trouble with your account or have any other questions, send an email to{" "}
        <a className="underline" href="mailto:support@chorusmeditation.com">
          support@chorusmeditation.com
        </a>{" "}
      </p>
    </div>
  );
}

export function MustLogin(props) {
  const login = () => {
    saveLoginRedirect(window.location.pathname);
    navigate("/login");
  };
  return (
    <div className="bg-ctan md:w-148 mx-1 md:mx-auto mt-2 md:mt-5 shadow-md p-2 md:p-8 rounded-md">
      <h2 className="font-medium text-2xl">
        {props.title ? props.title : "Please login to continue"}
      </h2>
      <p className="mt-5">
        If you are having trouble with your account or have any questions, send an email to{" "}
        <a className="underline" href="mailto:support@chorusmeditation.com">
          support@chorusmeditation.com
        </a>{" "}
      </p>
      <button
        className="mx-auto block mt-6 py-2 px-4 w-full md:w-64 rounded-xl shadow-md bg-white text-gray-600 outline-none focus:outline-none"
        onClick={login}
      >
        <div className="font-semibold">Login</div>
      </button>
    </div>
  );
}

export function AlreadyMember() {
  return (
    <div className="bg-ctan md:w-148 mx-1 md:mx-auto mt-2 md:mt-5 shadow-md p-2 md:p-8 rounded-md">
      <h2 className="font-medium text-2xl">Welcome Chorus Member</h2>
      <p className="mt-5">
        You already have an active Chorus membership. Please{" "}
        <span onClick={() => navigate("/")} className="underline cursor-pointer">
          click here
        </span>{" "}
        to book a class or{" "}
        <span onClick={() => navigate("/user/billing")} className="underline cursor-pointer">
          go here
        </span>{" "}
        to manage your account.
      </p>
      <p className="mt-5">
        If you are having trouble with your account or have any questions, send an email to{" "}
        <a className="underline" href="mailto:support@chorusmeditation.com">
          support@chorusmeditation.com
        </a>{" "}
      </p>
    </div>
  );
}
