import React, { useState, useEffect, useRef, useContext } from "react";
import { storeDel, storeGet, storeGetAndDel } from "../common/Params";
import ScheduleContext from "../contexts/ScheduleContext";
import { getUserReq } from "../apiClient/requests";
import apiClient from "../apiClient";
import { formAndSaveUserObject } from "../common/Init";
import { navigate } from "@reach/router";
import { handleError2 } from "apiClient/errors";

const getInitialState = {
  bookings_upcoming: [],
  bookings_past: [],
  event_bookings_upcoming: [],
  event_bookings_past: [],
  teachers: [],
  user: {},
};

const useUser = () => {
  const [user, setUser] = useState({ ...getInitialState });
  // only use this flag when you are loading the user data for the first time like on page reload
  const [loading, setLoading] = useState(true);
  // use this loading when user data already exist like moving from one tab to another
  const [userDataloading, setUserDataLoading] = useState(true);

  const callbackRef = useRef(null);
  const { hideScheduleDialog, showScheduleDialog } = useContext(ScheduleContext);

  useEffect(() => {
    if (user?.id && callbackRef?.current?.exist) {
      shouldShowOBM(callbackRef.current.regOrShow);
      callbackRef.current = undefined;
    }
  }, [user?.id]);

  // only call this function when you want to
  // replicate this.setState({user}, ()=> shouldShowOBM())
  const setUserWithCallBack = (data, regOrShow = true) => {
    callbackRef.current = { regOrShow, exist: true };
    setUser({ ...data });
  };

  // use this method for updating the user state
  const setUserData = (data, doFormatting = false) => {
    let userData = data;
    if (doFormatting) {
      userData = formAndSaveUserObject(data, data?.teachers || []);
    }
    setUser({ ...userData });
  };

  const shouldShowOBM = (regOrShow) => {
    if (!user.token) {
      // Not logged in, never show since they cant book
      hideScheduleDialog();
      return false;
    }
    if (storeGet("book") === "true") {
      // They are already in the process of booking a class!
      hideScheduleDialog();
      return false;
    }

    if (!regOrShow) {
      // They are on some other page, hide
      hideScheduleDialog();
      return false;
    }

    // ** No longer valid as we'll use a page transition to deal with onboarding
    // Force the dialog to show since the user passed the obshow=true query param
    if (storeGet("obshow")) {
      // navigate("/onboardb");
      return true;
    }

    // they are in process of playing the media event
    if (storeGetAndDel("media-event")) {
      return false;
    }

    //
    // They just registered via oauth or email, take them to the onboarding page
    if (storeGet("did_reg")) {
      console.log("did reg 80");
      let isFbretgt = storeGet("fbretgt");
      if (!isFbretgt) {
        storeDel("did_reg");
        navigate("/welcome");
        return true;
      }
    }
  };

  const getUser = async () => {
    try {
      setUserDataLoading(true);
      const req = getUserReq();
      const resp = await apiClient(req);
      const userData = formAndSaveUserObject(resp?.data, resp?.data?.teachers || []);
      setUser(userData);
    } catch (e) {
      handleError2(e);
    } finally {
      setUserDataLoading(false);
    }
  };

  return {
    loading,
    userDataloading,
    setLoading,
    setUserData,
    user,
    getUser,
    setUserWithCallBack,
  };
};

export default useUser;
