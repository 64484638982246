import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import * as halps from "common/Halps";
import { LoadingWhite, scrollToTop } from "common/Ponents";

import { handleError2 } from "apiClient/errors";
import apiClient from "apiClient";
import EventsApi from "apiClient/requests/events";
import EventsCard from "components/Events/Card";
import ClassesTabs from "components/ClassesTabs/index";

const EventList = (props) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const getEvents = async () => {
    try {
      const req = EventsApi.get();
      const response = await apiClient(req);
      const data = response.data || {};
      setEvents(data.events);
      setTeachers(data.teachers);
    } catch (err) {
      handleError2(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    halps.pageView("Events");
    getEvents();
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <>
        <ClassesTabs label="events" />
        <LoadingWhite />

        {/* keep the gap between footer and navbar */}
        <div className="mb-36"></div>
      </>
    );
  }

  return (
    <>
      <ClassesTabs label="events" />
      <div className="mt-8">
        {events.map((e) => {
          const teacher = e.teacher_ids?.length ? halps.getTeacher(teachers, e.teacher_ids[0]) : {};
          return (
            <EventsCard
              title={e.title_a}
              teacher={teacher}
              time={e.start_time}
              image={e.image_link}
              id={e.id}
              booked={halps.getUserEvent(props.user, e.id)}
              key={e.id}
              isClass={true}
              duration={e.duration}
              user={props.user}
              eventData={e}
            />
          );
        })}
      </div>
    </>
  );
};

export default EventList;
