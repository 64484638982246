import React, { useRef, useState, useEffect } from "react";
import StarsIcon from "common/icons/StarsIcon";
import testimonials from "common/constants/fblanding/testimonials.json";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Testimonial(props) {
  const [autoplay, setAutoplay] = useState(false);

  // start autoplay when user scrolls to the testimonials
  const scroll = () => {
    document.addEventListener("scroll", function (e) {
      //Get the button
      let testimonialsDiv = document.getElementById("testimonials");
      let testimonialsDivTop = testimonialsDiv?.offsetTop || 1700;

      if (
        !autoplay &&
        testimonialsDiv &&
        document.documentElement?.scrollTop > testimonialsDivTop - 620
      ) {
        setTimeout(startAutoPlay, 500);
        document.removeEventListener("scroll");
      }
    });
  };

  const startAutoPlay = () => {
    if (!autoplay) setAutoplay(true);
  };

  useEffect(() => {
    scroll();
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("scroll");
    };
  }, []);

  return (
    <div className="md:max-w-screen-lg mx-2 md:mx-auto mt-12 sm:mt-24" id="testimonials">
      {/* don't remove it */}
      <div className="hidden">
        <div className="h-96 h-88 h-64 sm:mt-24 mt-12 sm:rounded-bl-xlarge sm:rounded-bl-xlarge hidden">
          {" "}
        </div>
      </div>
      {/* for desktop */}
      <div className="sm:w-full sm:flex sm:items-center sm:pl-2 lg:pl-4 xl:pl-0 hidden sm:block">
        <div className="sm:w-3/12 text-left">
          <p className="text-4xl md:text-5xl lg:text-6xl text-charcoal font-extralight">Our</p>
          <h1 className="text-4xl md:text-5xl lg:text-6xl text-charcoal font-semibold">
            Happy Members
          </h1>
        </div>
        <div className="w-9/12">
          <div className="sm:ml-16 relative">
            <img
              className="absolute h-24 -ml-16 -mt-12"
              src="https://storage.googleapis.com/chorus-public/img/fblanding/double-quote.png"
            />
            <div className="sm:h-80 bg-white w-full flex rounded-xlarge">
              <Carousel
                autoPlay={autoplay}
                infiniteLoop={true}
                className="sm:h-80 -mt-8 w-full testimonials"
                showThumbs={false}
                showStatus={false}
              >
                {testimonials.map((data, i) => (
                  <div key={`testimonial-${i}`} className="flex items-center">
                    <img
                      className={`w-auto-important mr-4 ${data.classes}`}
                      src={data.image}
                      alt={data.title}
                    />
                    <div className="text-left">
                      <div className="flex items-center">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <StarsIcon
                            key={value}
                            classNames="w-4 h-4 ml-1 fill-current text-yellow-500"
                          />
                        ))}
                      </div>
                      <p className="font-normal md:text-base lg:text-xl text-cblue mr-4 mt-3">
                        {data.content}
                        <span className="font-bold md:text-base lg:text-xl text-cblue">
                          {data.boldContent}{" "}
                        </span>
                      </p>
                      <p className="font-bold text-3xl text-coral mt-3">{data.name}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile  */}
      <div className="w-full sm:hidden">
        <div className="text-center">
          <p className="text-2xl text-charcoal font-extralight">
            Our <b className="font-bold">Happy Members</b>
          </p>
        </div>

        <div className="sm:ml-16 relative mt-24">
          <img
            className="absolute h-24 -mt-12 ml-3"
            src="https://storage.googleapis.com/chorus-public/img/fblanding/double-quote.png"
          />
          <div className="bg-white w-full inline-block rounded-xlarge">
            <Carousel
              autoPlay
              infiniteLoop={true}
              className="-mt-12 w-full testimonials"
              showThumbs={false}
              showStatus={false}
            >
              {testimonials.map((data, i) => (
                <div key={`mobile-testimonial-${i}`} className="items-center">
                  <img
                    className={`w-auto-important mr-4 ${data.classes}`}
                    src={data.image}
                    alt={data.title}
                  />
                  <div className="text-center mx-auto mt-8">
                    <div className="flex items-center justify-center">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <StarsIcon
                          key={value}
                          classNames="w-4 h-4 ml-1 fill-current text-yellow-500"
                        />
                      ))}
                    </div>
                    <p className="font-normal text-2xl text-cblue m-4">
                      {data.content}
                      <span className="font-bold">{data.boldContent} </span>
                    </p>
                    <p className={`font-bold text-sm text-coral mt-3 ${data.nameClass || ""}`}>
                      {data.name}
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
