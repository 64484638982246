import React from "react";
import { Dialog } from "@headlessui/react";
import useIsMounted from "hooks/useHasMounted";
import { navigate } from "@reach/router";

// only using this dialog for trial expired page
export default function PlanUpdated({ setUserData }) {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  return (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className="position-at-center-fixed z-50  pb-8"
        open={true}
        key="keepSubscription"
        onClose={setUserData}
      >
        <div className="rounded-xl p-3 pb-6 w-90 bg-white w-full max-w-md mx-auto">
          <p
            onClick={setUserData}
            className="text-charcoal text-2xl float-right mr-4 cursor-pointer"
          >
            &times;
          </p>
          <div className="text-center mt-6 px-8 mx-auto">
            <div className="text-center">
              <img
                className="w-32 mx-auto inline-block"
                src="https://storage.googleapis.com/chorus-public/img/bill/undraw_positive_attitude_xaae.png"
                alt=""
              />
            </div>
            <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold mx-auto">
              Yay! You’re all set.
            </h2>
            <p className="text-purp mt-4">
              Your subscription remains active and you have full access.
            </p>
          </div>
          <div className="w-full text-center">
            <button
              onClick={setUserData}
              className="focus:outline-none mt-10 mx-auto bg-coral px-12 py-3 rounded-3xl text-xl font-bold text-white hover:bg-gray-400"
            >
              Sounds good!
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
