import { clkTryForYourselfTrack } from "common/Ana";
import React from "react";

const ClassContent = ({ btnText, linkId }) => {
  return (
    <div className="w-full px-2 sm:px-0 text-left">
      <h2 className="text-charcoal text-4xl sm:text-4.5xl xl:text-5xl text-left font-thin	">
        To help you
      </h2>
      <h2 className="text-charcoal text-4xl sm:text-4.5xl xl:text-5xl md-lh-elight text-left font-bold w-64 md:w-80 xl:w-104">
        sleep, energize, and unwind
      </h2>
      <p className="mt-5 text-left text-charcoal">Hundreds to choose from + live classes daily</p>
      <a
        onClick={() => {
          clkTryForYourselfTrack();
          return true;
        }}
        href={linkId}
      >
        <button className="mt-6 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
          <p className="text-lg font-semibold text-white">{btnText}</p>
        </button>
      </a>
    </div>
  );
};

export default function ClassesInfo(props) {
  return (
    <div className="text-center sm:mt-20 bg-lbeige bg-opacity-50 rounded-large py-10 px-4 md:p-20 xl:p-20">
      <div className="w-full sm:flex sm:justify-between sm:items-center">
        <div className="hidden sm:block sm:w-6/12">
          <ClassContent {...props} />
        </div>
        <div className="w-full sm:w-5/12">
          <div className="w-68 xl:w-80 h-68 xl:h-80 bg-purpc1 rounded-full flex items-center justify-center relative mx-auto">
            <div>
              <p className="text-4xl sm:text-5xl xl:font-black font-bold italic text-center sm:text-left pl-6">
                100+
              </p>
              <p className="text-4xl sm:text-5xl xl:font-black font-bold italic text-center sm:text-left">
                Classes
              </p>
              <button className="mt-3 px-5 py-2 bg-black text-corange rounded-3xl text-lg sm:text-xl">
                Live + On Demand
              </button>
            </div>
            <div className="flex items-center justify-center h-16 sm:h-20 w-16 sm:w-20 bg-white rounded-full top-12 -right-3 absolute">
              <img
                className="w-10 sm:w-12"
                src="https://storage.googleapis.com/chorus-public/img/icons/liveClass.png"
              />
            </div>
          </div>
        </div>
        <div className="block sm:hidden mt-4">
          <ClassContent {...props} />
        </div>
      </div>
    </div>
  );
}
