import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import useIsMounted from "hooks/useHasMounted";
import ChevronDoubleDown from "common/icons/ChevronDoubleDown";
import { pageView } from "common/Halps";

// if the user is downgrading to free tier
export default function DowngradeWarning({ onCancel, onClose, onKeepSubscriptionClick }) {
  const isMounted = useIsMounted();

  useEffect(() => {
    pageView("Viewed Cancelation PopUp");
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className="left-1/2 transform -translate-x-1/2  h-screen fixed top-2 sm:top-6 overflow-y-auto z-50 w-full-mobile"
        open={true}
        onClose={onClose}
        key="warning"
      >
        <div className="max-w-sm sm:max-w-md px-2 pt-2 sm:pt-0 sm:px-0 mx-auto">
          <div className="rounded-b-xl relative p-3 pb-6 w-90 bg-white w-full mx-auto">
            <p onClick={onClose} className="text-charcoal text-2xl float-right mr-4 cursor-pointer">
              &times;
            </p>
            <div className="text-center mt-6 px-8 mx-auto">
              <div className="text-center">
                <img
                  className="w-32 mx-auto inline-block"
                  src="https://storage.googleapis.com/chorus-public/img/bill/undraw_in_thought_re_qyxl.png"
                  alt=""
                />
              </div>
              <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold mx-auto">
                Are you sure?
              </h2>
              <p className="text-purp mt-4">
                By downgrading to the free tier, you will cancel your current subscription and be
                required to pay $10 for every class.{" "}
              </p>
            </div>
            <div className="w-full text-center">
              <button
                onClick={onKeepSubscriptionClick}
                className="focus:outline-none w-full sm:w-84 mt-10 mx-auto bg-coral py-3 rounded-3xl text-xl font-bold text-white hover:bg-gray-400"
              >
                Keep Subscription
              </button>
              <button
                onClick={onCancel}
                className="focus:outline-none w-full sm:w-84 mt-4 mx-auto py-3 rounded-3xl text-xl border-2 border-solid border-lbeigec1 text-purp hover:text-white hover:bg-gray-400"
              >
                Please Cancel
              </button>
              <ChevronDoubleDown cn="h-4 w-4 mt-2 mb-4 mx-auto" stroke={"#5BA7C7"} />
            </div>
          </div>
          <div className="-mt-8 w-full rounded-b-xl mx-auto px-8 pt-12 pb-8 bg-purpc2 text-center">
            <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold mx-auto">
              Is it the price?
            </h2>
            <p className="text-charcoal mt-2 opacity-80 text-sm sm:text-base">
              If price was the issue, please email us at support@chorusmeditation.com and we can
              offer you an adusted membership.
            </p>
            <p className="text-left text-charcoal ml-3 mt-4 font-semibold">We offer</p>
            <p className="text-left mt-1 ml-3 text-purp mt-3">
              Discounted pricing when paying for multiple months
            </p>
            <p className="text-left mt-1 ml-3 text-purp">Sliding Scale Pricing</p>
            <p className="text-left mt-1 ml-3 text-purp">Full Scholarships</p>
            <button
              onClick={() => window.open("mailto:support@chorusmeditation.com", "_blank")}
              className="focus:outline-none w-full sm:w-84 mt-4 mx-auto py-3 px-10 sm:px-14 rounded-full text-xl font-medium border-2 border-solid border-coral text-coral hover:text-white hover:bg-gray-400"
            >
              Email us for discounted pricing
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
