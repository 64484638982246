import React, { useState } from "react";
import ArrowCircleRight from "../../common/icons/ArrowCircleRight";
import Mail from "../../common/icons/Mail";
import Phone from "../../common/icons/Phone";
import apiClient from "../../apiClient";
import { addMemberToKlaviyoListReq } from "../../apiClient/requests";
import { contactSupportTrack, joinNewsletterTrack } from "common/Ana";

export function NewsletterSupport() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) return;

    try {
      joinNewsletterTrack();
      setLoading(true);
      const req = addMemberToKlaviyoListReq({
        email,
      });
      await apiClient(req);
      setEmail("");
      setMsg("Thanks for subscribing");
      setLoading(false);
    } catch (err) {
      setMsg("Invalid email");
      setLoading(false);
    }
  };

  const setEmailValue = (value) => {
    setEmail(value);
    if (msg) setMsg("");
  };

  return (
    <div className="relative sm:static bg-purpc2 rounded-large p-7 sm:p-12 pb-16 sm:pb-24 w-full sm:flex justify-between mt-8">
      <div className="w-full sm:w-2/4 text-left">
        <div className="md:w-82 lg:w-96">
          <h2 className="text-charcoal text-2xl font-bold mt-2 sm:mt-0">STAY IN THE LOOP</h2>
          <p className="text-purp text-base mt-2">
            Sign up to stay in the loop with out weekly schedule and events.
          </p>
          <div className="mt-4">
            <form
              className={`relative text-gray-600 ${
                loading ? "pointer-events-none opacity-75" : ""
              }`}
              onSubmit={handleSubmit}
            >
              <input
                type="email"
                name="email"
                required
                value={email}
                onChange={(e) => setEmailValue(e.target.value)}
                placeholder="Your Email"
                className="bg-white h-12 px-5 rounded-full text-sm focus:outline-none focus:ring-0 focus:shadow-none border-none w-full placeholder-purp"
              />
              <button type="submit" className="absolute right-0 top-0">
                <ArrowCircleRight />
              </button>
            </form>
          </div>
          <div className="mt-1 ml-2 text-black">{msg}</div>
        </div>
      </div>

      <div className="block sm:hidden mx-10 my-12">
        <div className="w-full border border-solid border-gray-400 opacity-70"></div>
      </div>

      <div className="w-full sm:w-2/4 mt-8 sm:mt-0">
        <div className="sm:ml-8 text-left">
          <h2 className="text-charcoal text-2xl font-bold">Support</h2>
          <div className="flex mt-4 items-center">
            <Mail stroke={"#767090"} />
            <a
              href="mailto:support@chorusmeditation.com"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer"
              onClick={() => {
                contactSupportTrack();
                return true;
              }}
            >
              <p className="text-purp ml-2">support@chorusmeditation.com</p>
            </a>
          </div>
          {/* <div className="flex mt-4 items-center">
            <Phone />
            <p className="text-purpc2 ml-2">123-4567-890</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
