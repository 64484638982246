import React, { useContext, useEffect } from "react";

import ScheduleContext from "../../contexts/ScheduleContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LogoutOption from "../../components/User/Logout";
import { AlreadyMember, MustLogin } from "../../components/Gates";
import ClassPlay from "../../common/icons/ClassPlay";
import PenPaper from "../../common/icons/PenPaper";
import LiveClass from "../../common/icons/LiveClass";
import YC from "../../common/icons/YC";
import PUFBilling from "../../components/PUF/PUFBilling";
import { pageView } from "../../common/Halps";
import { scrollToTop } from "common/Ponents";
import { storeDel } from "common/Params";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// Special offers aka promotions page
export default function PUF(props) {
  const { hideNav, showNav } = useContext(ScheduleContext);

  // This page should always hide the nav
  useEffect(() => {
    pageView("PUF");
    hideNav();
    storeDel("fbretgt");
    scrollToTop();
    return () => {
      showNav();
    };
  }, []);

  // Must be signed in to pay
  if (!props.user.token) {
    return <MustLogin />;
  }

  // If they are active and not a trial member, show this since we don't want to accidentally create another subscription for them
  // TODO backend should really guard against this
  if (props.user.status === "active" && !props.user.status2.includes("trial")) {
    return <AlreadyMember />;
  }

  return (
    <div className="md:max-w-screen-lg mt-8 mx-2 md:mx-auto mb-8">
      <TopWhite user={props.user} />
      <Elements stripe={stripePromise}>
        {/* comments */}
        <PUFBilling user={props.user} setUserData={props.setUserData} />
      </Elements>

      <LogoutOption email={props.user.email} />
    </div>
  );
}

function TopWhite(props) {
  return (
    <div className="mb-8">
      <div className="bg-white rounded-3xl p-3 sm:py-14 sm:px-8 relative">
        {/* left col */}
        <div className="relative mt-5 sm:mt-1 text-center">
          <p className="text-coral text-xs font-light">Signed in as {props.user.email}</p>
          <h1 className="mt-4 text-4xl font-semibold px-8 sm:px-20 lg:px-36 leading-normal">
            Start enjoying
            <br />
            <span className="text-coral"> less stress today</span>
          </h1>
          <div className="hidden sm:block  w-full text-left sm:mt-12">
            <div className=" pt-2 w-full mx-auto px-16 mt-3">
              <div className="w-full border border-gray-100 h-px"></div>
            </div>
            <div className="sm:flex mt-8 ml-12 mr-8">
              <div className="sm:w-1/3">
                <PenPaper />

                <p className="font-semibold text-sm mt-1">No Commitment</p>
                <p className="text-xs text-gray-500 mt-2">Cancel anytime</p>
              </div>
              <div className="sm:w-1/3 sm:-ml-6">
                <ClassPlay />
                <p className="text-sm sm:pr-16 mt-1">
                  <span className="font-semibold">
                    Live classes daily + hundreds of on-demand classes
                  </span>
                </p>
                <p className="text-xs text-gray-500 mt-2 sm:pr-12">Available whenever you want</p>
              </div>
              <div className="sm:w-1/3 sm:ml-4">
                <LiveClass />

                <p className="text-sm sm:pr-8 mt-1">
                  <span className="font-semibold">
                    Designed to help you sleep, energize, and unwind
                  </span>
                </p>
                <p className="text-xs text-gray-500 mt-2">As short as 10 minutes</p>
              </div>
            </div>
          </div>
        </div>

        {/* mobile only vertically stacked cards */}
        <div className="sm:hidden mt-8 px-3">
          <div className="">
            <PenPaper />

            <p className="font-semibold text-sm mt-1">No Commitment</p>
            <p className="text-xs text-gray-500 mt-2">Cancel anytime</p>
          </div>
          <div className="mt-5 ">
            <ClassPlay />
            <p className="text-sm mt-1">
              <span className="font-semibold">
                Live classes daily + hundreds of on-demand classes
              </span>
            </p>
            <p className="text-xs mt-1">Available whenever you want</p>
          </div>
          <div className="mt-5 mb-5">
            <LiveClass />

            <p className="text-sm mt-1">
              <span className="font-semibold">
                Designed to help you sleep, energize, and unwind
              </span>
            </p>
            <p className="text-xs text-gray-500 mt-2">As short as 10 minutes</p>
          </div>
        </div>
      </div>
      <div className="flex -mt-5 rounded-b-3xl bg-lbeigec1 p-3 pt-8 justify-between">
        <div className="text-xs font-medium ml-4">
          <span className="text-coral">256</span> users signed up this month
        </div>
        <div className="text-xs font-medium mr-4">
          Chorus is funded by <YC />{" "}
        </div>
      </div>
    </div>
  );
}
