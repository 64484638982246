import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import useIsMounted from "hooks/useHasMounted";
import { navigate } from "@reach/router";

// user has upgraded the subscription
export default function SubscriptionUpgrade({ onClose }) {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }
  return (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className="position-at-center-fixed z-50 pb-8 w-full px-2"
        open={true}
        onClose={onClose}
      >
        <div className="rounded-xl p-3 pb-12 w-90 bg-white w-full max-w-md mx-auto">
          <p onClick={onClose} className="text-charcoal text-2xl float-right mr-4 cursor-pointer">
            &times;
          </p>
          <div className="text-center mt-6 px-8 mx-auto">
            <div className="text-center">
              <img
                className="w-32 mx-auto inline-block"
                src="https://storage.googleapis.com/chorus-public/img/bill/undraw_positive_attitude_xaae.png"
                alt=""
              />
            </div>
            <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold mx-auto">
              Yay! You’re all set.
            </h2>
            <p className="text-purp mt-4">
              Your subscription remains active and you have full access.
            </p>
            <p className="text-purp mt-4">
              You will be refunded for any unused months on your previous plan, an a new billing
              cycle will be started for this new pricing option.
            </p>
          </div>
          <div className="w-full text-center">
            <button
              onClick={() => navigate("/on-demand")}
              className="focus:outline-none mt-10 mx-auto bg-coral px-12 py-3 rounded-3xl text-xl font-bold text-white hover:bg-gray-400"
            >
              Take Class
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
