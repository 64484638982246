import * as Sentry from "@sentry/react";
import * as halps from "../common/Halps";
import { apiBase, logout } from "../common/Init";

export function handleErrorQuiet(error) {
  console.log("Handling error QUIETLY!");
  console.log(error);
  Sentry.captureException(error);
}

// Axios style error handler function that will assume sentry is active
// and sweetalert bad request errors.
export function handleError2(error) {
  // Auth failed, probably session ended, pw changed, or they (or CS) killed all sessions/rolled auth version
  if (error.response && error.response.status === 401) {
    halps.alertConfirmLogout();
    return;
  }

  if (
    error &&
    error.response &&
    error.response.status === 400 &&
    error.response.data.error &&
    error.response.data.error.msg
  ) {
    const e = error.response.data.error.msg;
    console.log(`Bad request from server ${e}`);
    if (error.response.data.error.title) {
      halps.alertError(error.response.data.error.title, e);
    } else {
      halps.alertError("Oops!", e);
    }
    return;
  }

  console.error("Hard error from server");
  console.error(error);
  halps.alertError(
    "Server error",
    "Something's not right but we'll be notified of your error and look into it asap. You can try again in a moment or email us at support@chorusmeditation.com"
  );
  Sentry.captureException(error);
  // throw error;
}
