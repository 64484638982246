import ScheduleContext from "../contexts/ScheduleContext";

const withScheduleContext = (Component) => {
  return (props) => {
    return (
      <ScheduleContext.Consumer>
        {({
          showScheduleDialog,
          hideScheduleDialog,
          showDialog,
          shouldShowFooter,
          shouldShowNav,
        }) => {
          return (
            <Component
              {...props}
              showScheduleDialog={showScheduleDialog}
              hideScheduleDialog={hideScheduleDialog}
              showDialog={showDialog}
              shouldShowNav={shouldShowNav}
              shouldShowFooter={shouldShowFooter}
            />
          );
        }}
      </ScheduleContext.Consumer>
    );
  };
};

export default withScheduleContext;
