import React, { useState, useEffect, useRef } from "react";

const AutoTextArea = (props) => {
  const textAreaRef = useRef();
  const [text, setText] = useState(props.value);
  const [textAreaHeight, setTextAreaHeight] = useState(props.defaultheight || "auto");

  const [onReload, setOnReload] = useState(true);

  useEffect(() => {
    if (!onReload) {
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
    } else setOnReload(false);
  }, [text]);

  useEffect(() => {
    if (text && props.value === "") {
      setText("");
      setTextAreaHeight(props.defaultheight || "auto");
    }
  }, [props.value]);

  const onChangeHandler = (event) => {
    setTextAreaHeight("auto");
    setText(event.target.value);

    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <textarea
      {...props}
      ref={textAreaRef}
      style={{
        height: textAreaHeight,
      }}
      onChange={onChangeHandler}
    />
  );
};

export default AutoTextArea;
