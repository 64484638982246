import { UserContext } from "../contexts/User";

const withUserContext = (Component) => {
  return (props) => {
    return (
      <UserContext.Consumer>
        {({
          setUser,
          user,
          loading,
          userDataloading,
          setLoading,
          setUserData,
          getUser,
          setUserWithCallBack,
        }) => {
          return (
            <Component
              {...props}
              setUser={setUser}
              user={user}
              loading={loading}
              userDataloading={userDataloading}
              setLoading={setLoading}
              setUserData={setUserData}
              getUser={getUser}
              setUserWithCallBack={setUserWithCallBack}
            />
          );
        }}
      </UserContext.Consumer>
    );
  };
};

export default withUserContext;
