import React, { useEffect } from "react";
import { scrollToTop } from "common/Ponents";
import "./index.css";

const LoadingScreen = (props) => {
  // todo: add scrollToTop in routes code so that its get called every time when the route changes
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="min-h-104">
      <div
        className={`fixed top-0 left-0  w-screen h-screen flex items-center justify-center ${props.cn}`}
        style={{ background: "#e2ded7" }}
      >
        <div className="loader-dots block relative w-20 h-5 mt-2">
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-500"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
