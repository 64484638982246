export default function ClassPlay(props) {
  return (
    <svg
      className={`h-6 w-6 inline-block ${props.cn}`}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.372 4.29983C30.21 3.65269 29.8801 3.05976 29.4157 2.58093C28.9512 2.10209 28.3686 1.7543 27.7267 1.5727C25.3813 1 16 1 16 1C16 1 6.61867 1 4.27334 1.62724C3.63143 1.80885 3.04882 2.15663 2.58435 2.63547C2.11988 3.11431 1.79 3.70724 1.62802 4.35437C1.19879 6.73455 0.988829 9.14909 1.00078 11.5676C0.985479 14.0044 1.19545 16.4373 1.62802 18.8354C1.80659 19.4625 2.14387 20.0328 2.60726 20.4915C3.07065 20.9501 3.64449 21.2814 4.27334 21.4535C6.61867 22.0807 16 22.0807 16 22.0807C16 22.0807 25.3813 22.0807 27.7267 21.4535C28.3686 21.2719 28.9512 20.9241 29.4157 20.4453C29.8801 19.9664 30.21 19.3735 30.372 18.7264C30.7979 16.3641 31.0078 13.968 30.9992 11.5676C31.0145 9.13088 30.8046 6.69793 30.372 4.29983V4.29983Z"
        stroke="#5BA7C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.932 16.0266L20.7725 11.5677L12.932 7.10889V16.0266Z"
        stroke="#5BA7C7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
