import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LogoutOption from "../../components/User/Logout";
import Plans from "components/TrialExpire/Plans";
import TopWhitePanel from "components/FbLanding/TopWhitePanel/index";
import ChorusGuide from "components/FbLanding/ChorusGuide";
import Testimonial from "components/FbLanding/Testimonial";
import DownloadApp from "components/DownloadApp";
import Footer from "components/Footer/index";
import Benefits from "components/FbLanding/Benefits/index";
import ClassesInfo from "components/FbLanding/ClassesInfo/index";
import ExpertTeachers from "components/FbLanding/ExpertTeachers";
import Featured from "components/FbLanding/Featured/index";
import FAQ from "components/FbLanding/FAQS/index";
import { scrollToTop } from "common/Ponents";
import { storeDel } from "common/Params";

export default function TrialExpire(props) {
  useEffect(() => {
    scrollToTop();
    storeDel("fbretgt");
  }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  return (
    <>
      <div className="-mt-2 mb-8">
        <div className="md:max-w-screen-md lg:max-w-screen-xl lg:px-20 mx-2 sm:mx-auto text-center">
          <TopWhitePanel
            key="welcome"
            heading={"We hope you had a great first two weeks of Chorus!"}
            trialExpire={true}
            btnText="Join Membership"
            linkId="#join"
          />
          <Benefits btnText="Join Membership" linkId="#join" />
        </div>

        <div className="md:max-w-screen-md xl:max-w-screen-xl mx-2 xl:px-12 md:mx-auto  text-center">
          <ClassesInfo btnText="Join Membership" linkId="#join" />
        </div>

        <div className="md:max-w-screen-lg mx-2 sm:mx-auto text-center">
          <ExpertTeachers btnText="Join Membership" linkId="#join" />
        </div>

        <div className="md:max-w-screen-md xl:max-w-screen-xl mx-2 px-2 xl:px-12 md:mx-auto  text-center">
          <ChorusWorks />
          <ChorusGuide key="chorusGuide" btnText="Join Membership" linkId="#join" />

          <a href="#join">
            <button className="mt-8 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
              <p className="text-lg font-semibold text-white">Join Membership</p>
            </button>
          </a>
        </div>

        <div className="md:max-w-screen-lg mx-2 mt-6 sm:mx-auto text-center">
          <Testimonial key="testimonial" />
        </div>

        <div className="md:max-w-screen-lg xl:max-w-screen-xl mx-2 md:px-2 xl:px-12 md:mx-auto text-center">
          {/* features */}
          <div className="mx-auto mt-10 sm:mt-20 mb-8">
            <h2 className="text-4xl font-bold text-charcoal">As featured in</h2>
          </div>
          <Featured />
          <FAQ btnText="Join Membership" linkId="#join" />
          <Elements stripe={stripePromise}>
            <Plans user={props.user} setUserData={props.setUserData} />
          </Elements>
        </div>
        <div className="md:max-w-screen-lg mx-2 sm:mx-auto text-center">
          <DownloadApp />
        </div>
        <LogoutOption email={props.user.email} />

        <div className="mb-6">
          <Footer />
        </div>
      </div>
    </>
  );
}

function ChorusWorks() {
  return (
    <div className="mx-auto mt-16 sm:mt-24">
      <h2 className="text-4xl font-bold text-charcoal mx-4 sm:mx-0">How Chorus works...</h2>
    </div>
  );
}
