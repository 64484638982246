import * as halps from "../../common/Halps";
import { CardElement } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/react";
import {
  addPaymentMethod,
  makeDefaultPaymentMethod,
  removePaymentMethod,
  activateStripeSub,
  cancelSub,
  activateStripeSubWPlan,
} from "../../apiClient/requests";
import apiClient from "../../apiClient";
import { getUserFromStorage } from "../../common/Init";
import { getCoupon } from "../../common/Params";
import { handleError2 } from "apiClient/errors";
import {
  addSubTrack,
  billAddPMTrack,
  billDefaultPMTrack,
  billRemovePMTrack,
  cancelSubTrack,
} from "common/Ana";

// This might be a bad pattern but given the many, many states
// of the billing page, this might help keep things tidy, we'll see
// Just DON'T use this for anything react!
export default class BillingActions {
  constructor(stripe, elements, user, setBillData, billData, setUserData) {
    this.stripe = stripe;
    this.elements = elements;
    this.user = user;
    this.setBillData = setBillData;
    this.billData = billData;
    this.setUserData = setUserData;
  }

  _stripeSet = () => {
    if (!this.stripe || !this.elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      halps.alertError(
        "Billing system error",
        "Something's not right but we'll be notified of your error and look into it asap. You can try again in a moment or email us at support@chorusmeditation.com "
      );
      console.error(this.stripe);
      console.error(this.elements);
      Sentry.captureException({ stripe: this.stripe, elements: this.elements });
      return false;
    }
    return true;
  };

  // Use this for newer cases where we offer the customer a way to choose another
  // payment method besides their default one inline
  _tryCreatePMInStripe = async () => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = this.elements.getElement(CardElement);
    if (!cardElement) {
      return { error: "not_found" };
    }

    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (!error || error === "not_found") {
      // No error, we added a card
      billAddPMTrack();
    }
    return { error, paymentMethod, cardElement };
  };

  _createPMInStripe = async () => {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = this.elements.getElement(CardElement);

    const { error, paymentMethod } = await this.stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (!error || error === "not_found") {
      // No error, we added a card
      billAddPMTrack();
    }
    return { error, paymentMethod, cardElement };
  };

  // Add a payment method. Will make it the default
  addPM = async (setSpin) => {
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }

    const { error, paymentMethod, cardElement } = await this._createPMInStripe();

    if (error) {
      halps.alertError("Oops", error.message);
      setSpin(false);
      return;
    }

    try {
      // Make the request
      const req = addPaymentMethod({ pm: paymentMethod });
      const resp = await apiClient(req);
      cardElement.clear();
      this.setBillData(resp.data);
      // Fire GA on success
      window.dataLayer.push({
        event: "add_payment_info",
        payment_type: "CC",
      });
    } catch (err) {
      // TODO test bad cards and handle those errors (probably any 400s?)
      handleError2(err);
    }
    setSpin(false);
  };

  makeDefaultPM = async (setSpin, pmid) => {
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }
    try {
      // Make the request
      const req = makeDefaultPaymentMethod(pmid, {});
      const resp = await apiClient(req);
      this.setBillData(resp.data);
      billDefaultPMTrack();
    } catch (err) {
      // TODO test bad cards and handle those errors (probably any 400s?)
      handleError2(err);
    }
    setSpin(false);
  };

  removePM = async (setSpin, pmid) => {
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }
    try {
      // Make the request
      const req = removePaymentMethod(pmid, {});
      const resp = await apiClient(req);
      this.setBillData(resp.data);
      billRemovePMTrack();
    } catch (err) {
      // TODO test bad cards and handle those errors (probably any 400s?)
      handleError2(err);
    }
    setSpin(false);
  };

  cancelSub = async (setSpin) => {
    setSpin(true);
    console.log("cancelling sub");

    try {
      // Make the request
      const req = cancelSub({});
      const resp = await apiClient(req);
      this.setBillData(resp.data);
      const user = getUserFromStorage();

      cancelSubTrack();
    } catch (err) {
      handleError2(err);
    }
    setSpin(false);
  };

  // Add a new pm, make it default, start the sub in stripe. Can be used in several cases
  activateWithPM = async (setSpin) => {
    const coupon = getCoupon();
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }

    const { error, paymentMethod, cardElement } = await this._createPMInStripe();

    if (error) {
      halps.alertError("Oops", error.message);
      setSpin(false);
      return;
    }

    try {
      // Make the request
      const req = activateStripeSub({ pm: paymentMethod, coupon });
      const resp = await apiClient(req);
      cardElement.clear();
      await this.setBillData(resp.data);
      await this.setUserData(); // Refresh the user so app will know they are active sub
      // Fire GA on success
      window.dataLayer.push({
        event: "purchase",
        payment_type: "CC",
        platform: "stripe",
        value: "40",
      });
      addSubTrack("1mo40");
      this.fbSubscribe();
    } catch (err) {
      // TODO test bad cards and handle those errors (probably any 400s?)
      handleError2(err);
      setSpin(false);
    }
    // TODO consider what to do with this as react complains about setState on an unmounted component
    // which I assume is because we call this long after we've already setUserState which unmounts
    // the component that called this. Probably nbd for now
    // setSpin(false);
  };

  startStripeTrial = async (setSpin) => {
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }

    const { error, paymentMethod, cardElement } = await this._createPMInStripe();

    if (error) {
      halps.alertError("Oops", error.message);
      setSpin(false);
      return;
    }

    try {
      // Make the request
      // NOTE this is the same backend request for a few situations.
      // The backend will check the user's status before deciding whether or not
      // to do a trial
      const req = activateStripeSub({ pm: paymentMethod });
      const resp = await apiClient(req);
      cardElement.clear();
      await this.setBillData(resp.data);
      await this.setUserData(); // Refresh the user so app will know they are active sub

      this.fbStartTrial();
    } catch (err) {
      // TODO test bad cards and handle those errors (probably any 400s?)
      handleError2(err);
      setSpin(false);
    }
  };

  // FB tracking event for when the user actually subscribes
  fbSubscribe = () => {
    halps.fbTrack("Subscribe", { value: "40.00", currency: "USD" });
  };

  // Theyve started a stripe trial, track this with a value (non-stripe trial has 0 val)
  fbStartTrial = () => {
    halps.fbTrack("StartTrial", { value: "40.00", currency: "USD" });
  };

  // Activate using the default payment meth
  activate = async (setSpin, coupon) => {
    setSpin(true);
    try {
      // Make the request
      const req = activateStripeSub({ coupon });
      const resp = await apiClient(req);
      this.setBillData(resp.data);
      await this.setUserData(); // Refresh the user so app will know they are active sub
      const user = getUserFromStorage();

      addSubTrack("1mo40"); // Old school call, probably not used anymore hardcode plan to 1mo40
      this.fbSubscribe();
    } catch (err) {
      handleError2(err);
    }
    setSpin(false);
  };

  ccExpired = (year, mon) => {
    const today = new Date();
    const exp = new Date();
    exp.setFullYear(year, mon, 1);

    if (exp < today) {
      return true;
    }
  };

  // Return the last 4 of default method if exists
  hasPM = () => {
    // hasPM={props.billData.payment_methods.length > 0}
    if (
      this.billData?.customer?.invoice_settings?.default_payment_method?.card?.last4?.length === 4
    ) {
      if (
        this.ccExpired(
          this.billData.customer.invoice_settings.default_payment_method.card.exp_year,
          this.billData.customer.invoice_settings.default_payment_method.card.exp_month
        )
      ) {
        return null;
      }
      return this.billData.customer.invoice_settings.default_payment_method.card.last4;
    }
    return null;
  };

  // Dont Check Card - just simply activate the plan
  activateNewWPlanDCC = async (setSpin, plan, successCB) => {
    setSpin(true);
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }
    try {
      // Make the request
      let reqObj = { plan_id: plan };
      const req = activateStripeSubWPlan(reqObj);
      const resp = await apiClient(req);
      this.setBillData(resp.data);
      addSubTrack(plan);
      await this.setUserData(); // Refresh the user so app will know they are active sub
      const user = getUserFromStorage();

      this.fbSubscribe();
      if (successCB) successCB();
    } catch (err) {
      handleError2(err);
    } finally {
      setSpin(false);
    }
  };

  /* New (hopefully final) billing activation thingy. Plan is the billing plan or coupon
  - If they have a PM we'll skip all stripe crap
  - If not we wont
  - Same request as always just with a plan
  */
  activateNewWPlan = async (setSpin, plan, successCB) => {
    setSpin(true);
    let paymentMethod = null;
    let cardElement = null;
    let error = null;
    if (!this._stripeSet()) {
      setSpin(false);
      return;
    }

    // Let's always TRY to get at the new payment method incase they chose a new one inline
    ({ error, paymentMethod, cardElement } = await this._tryCreatePMInStripe());

    if (error) {
      if (error === "not_found") {
        console.log("Didnt find a new payment method");
      } else {
        halps.alertError("Oops", error.message);
        setSpin(false);
        return;
      }
    }

    try {
      // Make the request
      let reqObj = { plan_id: plan };
      if (paymentMethod) {
        reqObj.pm = paymentMethod;
      }
      const req = activateStripeSubWPlan(reqObj);
      const resp = await apiClient(req);
      if (paymentMethod) {
        cardElement.clear(); // Probably don't need to do this...
      }
      this.setBillData(resp.data);
      addSubTrack(plan);

      await this.setUserData(); // Refresh the user so app will know they are active sub
      const user = getUserFromStorage();

      if (successCB) successCB();
      this.fbSubscribe();
    } catch (err) {
      handleError2(err);
    }
    setSpin(false);
  };
}

export function ccExpired(year, mon) {
  const today = new Date();
  const exp = new Date();
  exp.setFullYear(year, mon, 1);

  if (exp < today) {
    return true;
  }
}

// TODO move some of these functions to functional since we'll need to use them outside of actions
// Return the default method based on bill data
export function defaultPM(billData) {
  if (billData?.customer?.invoice_settings?.default_payment_method?.card?.last4?.length === 4) {
    if (
      ccExpired(
        billData.customer.invoice_settings.default_payment_method.card.exp_year,
        billData.customer.invoice_settings.default_payment_method.card.exp_month
      )
    ) {
      return null;
    }
    return billData.customer.invoice_settings.default_payment_method.card;
  }
  return null;
}
