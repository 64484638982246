import React from "react";

function PianoLines({ cn, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn || "h-7 w-8"}
      fill="none"
      viewBox="0 0 33 27"
    >
      <path
        fill={fill || "#5BA7C7"}
        d="M15.003 18.269H1.509c-.572 0-1.009.471-1.009 1.01 0 .572.471 1.009 1.01 1.009h13.526a2.326 2.326 0 012.322 2.322 2.326 2.326 0 01-2.322 2.322 2.326 2.326 0 01-2.322-2.322c0-.572-.47-1.01-1.009-1.01-.572 0-1.01.471-1.01 1.01 0 2.389 1.952 4.374 4.375 4.374a4.366 4.366 0 004.374-4.374c-.033-2.39-1.985-4.341-4.441-4.341zM1.51 9.05H21.8c2.389 0 4.374-1.952 4.374-4.375A4.366 4.366 0 0021.8.301a4.366 4.366 0 00-4.375 4.374c0 .572.471 1.01 1.01 1.01.572 0 1.01-.471 1.01-1.01a2.326 2.326 0 012.321-2.322 2.326 2.326 0 012.322 2.322c0 1.279-1.043 2.322-2.356 2.322H1.51C.937 6.997.5 7.468.5 8.007a1.02 1.02 0 001.01 1.042z"
      ></path>
      <path
        fill={fill || "#5BA7C7"}
        d="M27.217 12.65H1.51C.937 12.65.5 13.12.5 13.66c0 .571.471 1.009 1.01 1.009h25.707a3.231 3.231 0 11-3.23 3.23c0-.202.034-.404.067-.572.101-.538-.269-1.077-.807-1.178a1.005 1.005 0 00-1.178.808c-.067.303-.067.606-.067.942a5.256 5.256 0 005.249 5.25 5.256 5.256 0 005.249-5.25c0-2.894-2.39-5.25-5.283-5.25z"
      ></path>
    </svg>
  );
}

export default PianoLines;
