import { createReqObject, getAuthenticatedHeaders } from "./index";

const EventsApi = {
  // get events
  get: () => {
    return createReqObject(`/events`, "GET", getAuthenticatedHeaders());
  },

  //   get event by id
  getById: (id) => {
    return createReqObject(`/events/${id}`, "GET", getAuthenticatedHeaders());
  },

  // Cancel an event booking
  cancelEventBooking: (id) => {
    return createReqObject(`/events/bookings/${id}`, "DELETE", getAuthenticatedHeaders());
  },

  // Create booking
  liveBook: (data) => {
    return createReqObject(`/events/bookings`, "POST", getAuthenticatedHeaders(), data);
  },
};

export default EventsApi;
