// Analytics and other related stuffs via SEGMENT!
import * as Sentry from "@sentry/react";
import { fbTrack } from "./Halps";
import { getUserFromStorage } from "./Init";

/*

Notes from Oct 29, 2021
- Added a generic % od view track!
- Dates convo for start/aired dates and js issues. 

*/

// ****************************************************************************
// Page/screen views
// ****************************************************************************
// Flag that the user loaded a particular page
// Probably for iOS let's use the screen call so we can easily separate these
export function page(name, props) {
  window.analytics.page(name, props);
  console.log(`[ana] page ${name} with props ${JSON.stringify(props)}`);
}

// ****************************************************************************
// Landing pages
// ****************************************************************************
export function downloadAppClkTrack() {
  window.analytics.track("clk_download_ios_app");
  console.log(`[ana] clk_download_ios_app`);
}

export function joinNewsletterTrack() {
  window.analytics.track("join_newsletter");
  console.log(`[ana] join_newsletter`);
}

export function clkTryForYourselfTrack() {
  window.analytics.track("clk_try_for_yourself");
  console.log(`[ana] clk_try_for_yourself`);
}

export function clkNYTTrack() {
  window.analytics.track("clk_nyt");
  console.log(`[ana] clk_nyt`);
}

export function clkGoopTrack() {
  window.analytics.track("clk_goop");
  console.log(`[ana] clk_goop`);
}

export function clkPopSugarTrack() {
  window.analytics.track("clk_popsugar");
  console.log(`[ana] clk_popsugar`);
}

export function clkRunnersWorldTrack() {
  window.analytics.track("clk_runnersworld");
  console.log(`[ana] clk_runnersworld`);
}

// ****************************************************************************
// FB Retargiting
// ****************************************************************************

// welcome popup view all click
export function fbretgtWelcomePopUpClosesTrack(properties) {
  window.analytics.track(`fbretgt_welcome_pop_up_xout`, properties);
  console.log(`[ana] fbretgt_welcome_pop_up_xout`);
}

// welcome popup view all click
export function fbretgtWelcomeViewAllTrack(properties) {
  window.analytics.track(`fbretgt_all_classes`, properties);
  console.log(`[ana] fbretgt_all_classes`);
}

// welcome pop up scheducle button click
export function fbretgtWelcomeClassTrack(name, properties) {
  window.analytics.track(`fbretgt_${name}_person`, properties);
  console.log(`[ana] fbretgt_${name}_person`);
}

// Retgt welcome pop up
export function fbretgtWelcomePopupTrack(properties) {
  window.analytics.track("Viewed FBRetgt Welcome Pop-UP", properties);
  console.log(`[ana] Viewed FBRetgt Welcome Pop-UP`);
}

// Retgt feedback submitted
export function submitFeedbackTrack(properties) {
  window.analytics.track("retgt_submit_feedback", properties);
  console.log(`[ana] retgt_submit_feedback`);
}

// Retgt B Time clicked skip
export function clkRetGtBTimeSkipTrack() {
  window.analytics.track("clk_retgt_b_time_skip", {
    entry_flow: "retgt_b",
  });
  console.log(`[ana] clk_retgt_b_time_skip`);
}

// Retgt B Mood clicked skip
export function clkRetGtBMoodSkipTrack() {
  window.analytics.track("clk_retgt_b_mood_skip", {
    entry_flow: "retgt_b",
  });
  console.log(`[ana] clk_retgt_b_mood_skip`);
}

// ****************************************************************************
// Onboarding including the "lite" on demand player
// ****************************************************************************

// Mood clicked
export function clkMoodTrack(name, properties) {
  window.analytics.track(`Viewed ${name} B Time`, properties);
  console.log(`[ana] Viewed ${name} B Time`);
}

// Onboard B Mood clicked skip
export function clkOBBMoodSkipTrack() {
  window.analytics.track("clk_onb_b_mood_skip", {
    entry_flow: "onb_b",
  });
  console.log(`[ana] clk_onb_b_mood_skip`);
}

// Onboard B Time clicked skip
export function clkOBBTimeSkipTrack() {
  window.analytics.track("clk_onb_b_time_skip", {
    entry_flow: "onb_b",
  });
  console.log(`[ana] clk_onb_b_time_skip`);
}

// They are clicking the read more about what to expect link which takes them
// off site in a new tab
export function odbReadMoreExpectTrack(properties) {
  window.analytics.track("clk_read_more_what_to_expect", properties);
  console.log(`[ana] clk_read_more_what_to_expect`);
}

// They are clicking the read more about teacher
export function readMoreAboutTeacherTrack(properties) {
  window.analytics.track("clk_read_more_teacher", properties);
  console.log(`[ana] clk_read_more_teacher`);
}

// They clicked the cal schedule for later link
export function odbCalScheduleTrack(properties) {
  window.analytics.track("clk_odb_cal_schedule", properties);
  console.log(`[ana] clk_odb_cal_schedule`);
}

// They clicked the skip link at the bottom of the odb player
export function odbSkipTrack(properties) {
  window.analytics.track("clk_odb_skip", properties);
  console.log(`[ana] clk_odb_skip`);
}

// ondemand lite spinner
export function odbSpinnerTrack(name, properties) {
  window.analytics.track(`Viewed ${name} Spinner`, properties);
  console.log(`[ana] Viewed ${name} Spinner`);
}

// ****
// Odb PCE clickyclacks
// Right now there's 4 possible click options
// ****

// "Schedule Class" button
export function openPCEPopUpTrack(name, properties) {
  window.analytics.track(name, properties);
  console.log(`[ana] ${name}`);
}

// "Schedule Class" button
export function clkPCEClassTrack(name, type, properties) {
  window.analytics.track(`${type}_pce_${name}`, properties);
  console.log(`[ana] ${type}_pce_${name}`);
}

// "View all classes" button
export function odbPCEViewAllTrack(type, properties) {
  window.analytics.track(`${type}_pce_all`, properties);
  console.log(`[ana] ${type}_pce_all`);
}

// "Close" button
export function odbPCECloseTrack(properties) {
  window.analytics.track("clk_odb_pce_close", properties);
  console.log(`[ana] clk_odb_pce_close`);
}

// ****************************************************************************
// User and account related
// ****************************************************************************

/*
Identify will now set A LOT of params including:
https://docs.google.com/document/d/11Y5dewrYkVZpOVHTnbSLe4TCr72RBJW-qkiaZA2CMxY/edit#heading=h.9p7xkxri0fi6

Which will move to the taxonomy doc here:
https://docs.google.com/spreadsheets/d/1WQbsARPS_5h9UMZlrg9_Y5DX1NniU9CqZJWxSEmvCqs/edit#gid=656872197


Missing fields and other notes:
- age usually not set yet
- gender usually not set yet

**** WE CAN ONLY HAVE 20 CUSTOM PARAMs, KEEP CLOSE CONTROL OF THESE ****

*/
export function identify(user) {
  window.analytics.identify(user.id, {
    name: `${user.first_name} ${user.last_name}`, // 1
    email: user.email,
    pushable: user.pushable,
    source: user.source,
    onboard: user.onboard,
    trial_type: user.trial_type, //
    trial_days: user.trial_days,
    member_status: user.member_status,
    member_tier: user.member_tier,
    member_plan: user.member_plan, //
    member_plan_canceled: user.member_plan_canceled,
    member_pay_platform: user.member_pay_platform,
    member_ever: user.member_ever, //
    member_interval_price: user.member_interval_price,
    member_interval_months: user.member_interval_months,
    member_monthly_price: user.member_monthly_price,
  });
  console.log(`[ana] identifying user ${user.id} ${user.email}`);

  // Always identify the user when they load the page, not just when they login
  Sentry.setUser({
    email: user.email,
    id: user.id,
  });
}

// user add the profile photo
export function userAddThePhotoTrack() {
  window.analytics.track(`add_profile_photo`);
  console.log(`[ana] add_profile_photo`);
}

// user toggles the push notifications
export function updatedSettingsEventTrack(
  settingName,
  enabled,
  eventName = "push_settings_updated"
) {
  window.analytics.track(eventName, {
    setting_name: settingName,
    enabled: enabled,
  });
  console.log(`[ana] ${eventName}`);
}

// user click the notification
export function userClickTheNotificationTrack() {
  window.analytics.track(`notifs_click`);
  console.log(`[ana] notifs_click`);
}

// user mark the notification as read
export function userMarkTheNotificationAsReadTrack() {
  window.analytics.track(`notifs_marked_as_read`);
  console.log(`[ana] notifs_marked_as_read`);
}

// user click the learn more on billing page
export function userClickTheLearnMoreTrack() {
  window.analytics.track(`clk_learn_more`);
  console.log(`[ana] clk_learn_more`);
}

// user logged in using code
export function loginWithCodeTrack() {
  window.analytics.track(`login_with_code`);
  console.log(`[ana] login_with_code`);
}

// User has finished logging in
export function loginTrack(user, method) {
  identify(user);
  window.analytics.track("login", {
    method,
  });
  console.log(`[ana] user logged in via ${method}`);

  // OLD for GA/GTM
  window.dataLayer.push({
    event: "login",
    email: user.email,
    user_id: user.id,
    method,
  });
}

// User has finished creating a new account
// Method should be email, gplus, fb
// onboard should be var_b or var_c
// Trial should be yes_cc no_cc apple
export function regTrack(user, method, onboard, trial) {
  identify(user);
  window.analytics.track("reg", {
    method,
    onboard,
    trial,
  });
  console.log(`[ana] user registered via ${method}`);

  window.dataLayer.push({
    event: "sign_up",
    method: "email",
    email: user.email,
    user_id: user.id,
  });

  // This is the nocc aka stripe trial which is NOT being used currently
  if (user.status2.startsWith("new")) {
    // In all other cases they will need to take a next step to finish registration/trial/purchase
    fbTrack("InitiateCheckout", { value: "0.00", currency: "USD" });
  } else {
    // Normal complete registration where they are given an implict free trial, no more steps required
    fbTrack("StartTrial", { value: "0.00", currency: "USD" });
  }
}

// TODO shouldnt need to pass user info here (after were rid of GA, remove)
export function logoutTrack(user) {
  window.analytics.track("logout");
  window.dataLayer.push({
    event: "logout",
    email: user.email,
    user_id: user.id,
  });
  console.log(`[ana] user logged out`);
}

export function loginSendCodeTrack(email) {
  window.analytics.track("login_send_code", { email });
  console.log(`[ana] user ${email} requested login code`);
}

export function clkContinueGoogle() {
  window.analytics.track("clk_continue_with_google");
  console.log(`[ana] clk_continue_with_google`);
}

export function clkContinueFB() {
  window.analytics.track("clk_continue_with_fb");
  console.log(`[ana] clk_continue_with_fb`);
}

export function clkContinueEmail() {
  window.analytics.track("clk_continue_with_email");
  console.log(`[ana] clk_continue_with_email`);
}

export function clkFilterTrack(filter_type) {
  console.log(`[ana] click filter type ${filter_type}`);
  window.analytics.track("clk_filter", {
    filter_type,
  });
}

// ****************************************************************************
// Live classes
// ****************************************************************************
export function bookLiveClassTrack(cls, properties) {
  console.log(`[ana] book live class`);

  window.analytics.track("class_book", {
    teacher: cls.teacher_id,
    type: cls.type,
    duration: cls.duration,
    slot: cls.slot,
    id: cls.id,
    ...properties,
  });

  fbTrack("Schedule");
}

export function cancelLiveClassTrack(cls, properties) {
  console.log(`[ana] class_cancel`);

  window.analytics.track("class_cancel", {
    teacher: cls.teacher_id,
    type: cls.type,
    duration: cls.duration,
    slot: cls.slot,
    id: cls.id,
    ...properties,
  });
}
// clk_blog_how_meditation_breathing_chorus_method
export function clkBlogHowMeditationTrack(properties) {
  console.log(`[ana] clk_blog_how_meditation_breathing_chorus_method`);
  window.analytics.track("clk_blog_how_meditation_breathing_chorus_method", properties);
}

export function openedZoomTrack(classId) {
  console.log(`[ana] zoom_link_open`);
  window.analytics.track("zoom_link_open", {
    classId: classId,
  });
}

// *** Note this is a generic event and should probably be moved to a generic section!
export function addToCal(props) {
  console.log(`[ana] add_to_calendar with props ${JSON.stringify(props)}`);
  window.analytics.track("add_to_calendar", props);
}

export function addLiveClassToCalTrack(cls) {
  addToCal({
    loc: "live",
    teacher: cls.teacher_id,
    type: cls.type,
    duration: cls.duration,
    slot: cls.slot,
    id: cls.id,
  });
}

// ****************************************************************************
// On-demand
// ****************************************************************************
export function odViewTrack(od, properties) {
  console.log(`[ana] od view`);
  // Adding Nov 16, 2021 per Glen
  fbTrack("CompleteRegistration");

  window.analytics.track("od_view", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
    ...properties,
    // TODO add slot?
  });
}

// firing this event for od media
export function odViewPercentTrack(percent, od, isOndemand, props = {}) {
  let data = {};
  console.log(`[ana] od view UPDATE ${percent}`);

  data = {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    percent,
    id: od.id,
    ...props,
  };
  if (isOndemand) data["class_id"] = od.class_id;
  window.analytics.track(`od_view_update`, data);
}

// firing in the case of od_view_50 and od_view_80
export function odView50And80PercentTrack(percent, od, isOndemand, props = {}) {
  let data = {};
  console.log(`[ana] od od_view_${percent}`);
  data = {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    percent,
    id: od.id,
    ...props,
    // TODO add slot?
  };
  if (isOndemand) data["class_id"] = od.class_id;
  window.analytics.track(`od_view_${percent}`, data);
}

export function addOdClassToCalTrack(od) {
  addToCal({
    loc: "od",
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
  });
}

export function odFavoriteTrack(od) {
  console.log(`[ana] add_favorite`);
  window.analytics.track("add_favorite", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
    loc: "od",
  });
}

export function odLikeTrack(od) {
  console.log(`[ana] class_like`);
  window.analytics.track("class_like", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
    loc: "od",
  });
}

export function odMusicLinkTrack(od) {
  console.log(`[ana] od_music_link`);
  window.analytics.track("od_music_link", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
  });
}

// ** As of now this just shows a popup prompting user to DL ios app
export function odStatsTrack(od) {
  console.log(`[ana] od_stats`);
  window.analytics.track("od_stats", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
  });
}

export function odSkipIntroTrack(od) {
  console.log(`[ana] od_skip_intro`);
  window.analytics.track("od_skip_intro", {
    teacher: od.teacher_id,
    type: od.type,
    duration: od.duration,
    id: od.id,
    class_id: od.class_id,
  });
}

// ****************************************************************************
// Events
//
// ****************************************************************************
// They tried to START booking but might have bailed due to some issues
export function clkBookEventTrack(ev) {
  console.log(`[ana] event_clk_count_me_in`);
  window.analytics.track("event_clk_count_me_in", {
    teacher: ev.teacher_ids[0],
    id: ev.id,
  });
}

export function bookEventTrack(ev) {
  console.log(`[ana] event_book`);
  window.analytics.track("event_book", {
    teacher: ev.teacher_ids[0],
    id: ev.id,
  });
}

export function cancelEventTrack(ev) {
  console.log(`[ana] event_cancel`);
  window.analytics.track("event_cancel", {
    teacher: ev.teacher_ids[0],
    id: ev.id,
  });
}

export function eventOpenedZoomTrack(eventId) {
  console.log(`[ana] event_zoom_link_open`);
  window.analytics.track("event_zoom_link_open", {
    eventId,
  });
}

export function eventAddToCalTrack(ev) {
  addToCal({
    loc: "event",
    teacher: ev.teacher_ids[0],
    id: ev.id,
  });
}

export function eventOpenMapTrack(ev) {
  console.log(`[ana] open_map_event`);
  window.analytics.track("open_map_event", {
    teacher: ev.teacher_ids[0],
    id: ev.id,
  });
}

export function addEventCouponTrack(ev, coup) {
  console.log(`[ana] add_coupon`);
  window.analytics.track("add_coupon", {
    teacher: ev.teacher_ids[0],
    id: ev.id,
    coup,
  });
}

// *** Other needed events items:
// Purchase (will get a book+purchase): Should contain a coupon param
// Close purchase dialog
// Apply coup
// Add CC
// Click "User Another" card

// ****************************************************************************
// Billing/payments
// ****************************************************************************

// They are adding a new sub. This might need to get refined since they can now also
//  - change sub plan (without canceling)
//  - Cancel (which is also downgrading to free tier)

export function addSubTrack(plan) {
  const user = getUserFromStorage();
  const memberPlan = user?.member_plan;
  if (plan === "free") {
    console.log(`[ana] bill_mem_add_free add sub w/ plan ${plan}`);
    window.analytics.track("bill_mem_add_free", {
      platform: "stripe",
    });
  } else if (!plan) {
    // They are reactivating a previously canceled sub
    console.log(`[ana] bill_mem_add_re_sub reactivating sub w/ plan ${plan}`);
    window.analytics.track("bill_mem_add_re_sub", {
      platform: "stripe", // We're always stripe with this code
    });
  } else if (memberPlan && memberPlan !== "free" && memberPlan !== plan) {
    // Normal plan add
    console.log(`[ana] bill_mem_change_plans add sub w/ plan ${plan}`);
    window.analytics.track("bill_mem_change_plans", {
      platform: "stripe", // We're always stripe with this code
      plan,
    });
  } else {
    // Normal plan add
    console.log(`[ana] bill_mem_add_sub add sub w/ plan ${plan}`);
    window.analytics.track("bill_mem_add_sub", {
      platform: "stripe", // We're always stripe with this code
      plan,
    });
  }
}

export function cancelSubTrack() {
  console.log(`[ana] cancel sub`);
  window.analytics.track("bill_mem_cancel_sub");
}

export function clkJoinMembTrack() {
  console.log(`[ana] clk_join_memb`);
  window.analytics.track("clk_join_memb");
}

export function clkKeepSubscriptionTrack() {
  console.log(`[ana] clk_keep_sub`);
  window.analytics.track("clk_keep_sub");
}

export function billPlanPickedTrack(productId) {
  console.log(`[ana] bill_plan_picked ${productId}`);
  window.analytics.track("bill_plan_picked", {
    productId,
    platform: "stripe",
  });
}

export function billAddPMTrack() {
  console.log(`[ana] bill_add_pm`);
  window.analytics.track("bill_add_pm", {
    platform: "stripe",
  });
}

export function billDefaultPMTrack() {
  console.log(`[ana] bill_default_pm`);
  window.analytics.track("bill_default_pm", {
    platform: "stripe",
  });
}

export function billRemovePMTrack() {
  console.log(`[ana] bill_remove_pm`);
  window.analytics.track("bill_remove_pm", {
    platform: "stripe",
  });
}

export function clkBillChangePlansTrack() {
  window.analytics.track("clk_bill_change_plans", {
    platform: "stripe",
  });
  console.log(`[ana] clk_bill_change_plans`);
}

export function clkBillSavedCardsTrack() {
  window.analytics.track("clk_bill_saved_cards", {
    platform: "stripe",
  });
  console.log(`[ana] clk_bill_saved_cards`);
}

// ****************************************************************************
// Comments/Posts/Replies
// ****************************************************************************

//when someone cares for either comment, reply, post (loc)
export function careTrack(loc) {
  console.log(`[ana] care`);
  window.analytics.track("care", {
    loc,
  });
}

// when someone adds a comment to a class or post
export function addCommentTrack(commendThreadId, mentionedUser) {
  console.log(`[ana] comment_add`);
  window.analytics.track("comment_add", {
    commendThreadId,
    mentionedUser, // true of false
  });
}

//when someone replies to a comment
export function addReplyTrack(commentId) {
  console.log(`[ana] comment_reply`);
  window.analytics.track("comment_reply", {
    commentId,
  });
}

// when someone creates a post
// Note this is NOT available currently on the webapp
// export function addPostTrack(mentionedUser) {
//   window.analytics.track("post_add", {
//     mentionedUser: mentionedUser,
//   });
// }

// ****************************************************************************
// Sharing
// ****************************************************************************

// We use this generic function to track all types of sharing
// I think "method" for webapp will always be "copy" for now since we dont have
// a share menu like ios and just simply copy to clipboard
//
// Later events might do the share via email to friends which could be different
export function sharedEntityTrack(entity, method, content_type) {
  console.log(
    `[ana] shared_entity entity: ${JSON.stringify(
      entity
    )} method: ${method} content_type: ${content_type}`
  );
  window.analytics.track("shared_entity", {
    method: method,
    item_id: entity.id,
    content_type: content_type,
  });
}

// ****************************************************************************
// Contact
// ****************************************************************************

// When the user clicks the link to email support@chorusmeditation.com
export function contactSupportTrack() {
  console.log(`[ana] contact_support`);
  window.analytics.track("contact_support");
}
