export default function PoweredByStripe(props) {
  return (
    <svg
      className={`ml-2 ${props.classNames || "w-24"} inline-block`}
      viewBox="0 0 120 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M116.8 0H2.984C2.19259 0 1.4336 0.314385 0.873993 0.873993C0.314385 1.4336 0 2.19259 0 2.984L0 24.216C0 25.0074 0.314385 25.7664 0.873993 26.326C1.4336 26.8856 2.19259 27.2 2.984 27.2H116.8C117.649 27.2 118.463 26.8629 119.063 26.2627C119.663 25.6626 120 24.8487 120 24V3.2C120 2.35131 119.663 1.53737 119.063 0.937258C118.463 0.337142 117.649 0 116.8 0V0ZM119.2 24C119.2 24.6365 118.947 25.247 118.497 25.6971C118.047 26.1471 117.437 26.4 116.8 26.4H2.984C2.40542 26.3979 1.85113 26.1671 1.44201 25.758C1.03288 25.3489 0.802108 24.7946 0.8 24.216V2.984C0.802108 2.40542 1.03288 1.85113 1.44201 1.44201C1.85113 1.03288 2.40542 0.802108 2.984 0.8H116.8C117.437 0.8 118.047 1.05286 118.497 1.50294C118.947 1.95303 119.2 2.56348 119.2 3.2V24Z"
          fill="#333031"
        />
        <path
          d="M13.6561 8.99194H10.2161V17.5999H11.7521V14.2719H13.6561C15.5761 14.2719 16.7761 13.3439 16.7761 11.6319C16.7761 9.91994 15.5761 8.99194 13.6561 8.99194ZM13.5761 12.9919H11.7521V10.3519H13.6001C14.7041 10.3519 15.2881 10.8239 15.2881 11.6719C15.2881 12.5199 14.6801 12.9519 13.6001 12.9519L13.5761 12.9919Z"
          fill="#333031"
        />
        <path
          d="M20.0801 11.2C19.6595 11.1952 19.2426 11.2783 18.856 11.4442C18.4695 11.61 18.1219 11.8548 17.8356 12.163C17.5494 12.4711 17.3307 12.8357 17.1937 13.2334C17.0567 13.631 17.0043 14.053 17.0401 14.472C17.0026 14.8934 17.0532 15.318 17.1887 15.7187C17.3243 16.1195 17.5417 16.4876 17.8273 16.7998C18.1129 17.1119 18.4603 17.3611 18.8475 17.5317C19.2346 17.7022 19.653 17.7902 20.0761 17.7902C20.4991 17.7902 20.9175 17.7022 21.3047 17.5317C21.6919 17.3611 22.0393 17.1119 22.3248 16.7998C22.6104 16.4876 22.8279 16.1195 22.9634 15.7187C23.099 15.318 23.1496 14.8934 23.1121 14.472C23.149 14.0534 23.0978 13.6316 22.9617 13.234C22.8256 12.8363 22.6077 12.4716 22.3221 12.1633C22.0364 11.855 21.6893 11.61 21.3031 11.4441C20.917 11.2782 20.5003 11.1951 20.0801 11.2ZM20.0801 16.536C19.1041 16.536 18.4801 15.736 18.4801 14.472C18.4801 13.208 19.0881 12.408 20.0801 12.408C21.0721 12.408 21.6801 13.208 21.6801 14.472C21.6801 15.736 21.0481 16.528 20.0801 16.528V16.536Z"
          fill="#333031"
        />
        <path
          d="M29.424 15.48L28.296 11.304H27.112L25.992 15.48L24.856 11.304H23.376L25.272 17.608H26.52L27.672 13.48L28.824 17.608H30.072L31.968 11.304H30.544L29.424 15.48Z"
          fill="#333031"
        />
        <path
          d="M35.2 11.2C34.7849 11.2077 34.3757 11.2996 33.9973 11.4703C33.6188 11.641 33.279 11.8868 32.9984 12.1928C32.7179 12.4987 32.5024 12.8586 32.3652 13.2504C32.2279 13.6422 32.1717 14.0578 32.2 14.472C32.1665 14.8925 32.2212 15.3155 32.3606 15.7137C32.5 16.1119 32.7209 16.4767 33.0093 16.7847C33.2977 17.0927 33.6472 17.337 34.0354 17.5022C34.4237 17.6674 34.8421 17.7497 35.264 17.7439C35.844 17.7752 36.4192 17.6237 36.9086 17.3109C37.398 16.998 37.7769 16.5395 37.992 16L36.8 15.5039C36.7064 15.8131 36.5106 16.0812 36.2445 16.2644C35.9785 16.4476 35.6581 16.5349 35.336 16.5119C35.1065 16.5174 34.8783 16.4763 34.6652 16.391C34.4521 16.3057 34.2585 16.178 34.0962 16.0157C33.9339 15.8534 33.8062 15.6598 33.7209 15.4467C33.6356 15.2336 33.5945 15.0054 33.6 14.7759H38.016V14.296C38.032 12.568 37.056 11.2 35.2 11.2ZM33.656 13.7039C33.712 13.3366 33.8994 13.002 34.1833 12.7623C34.4672 12.5225 34.8284 12.3938 35.2 12.4C35.3765 12.385 35.5542 12.4079 35.7212 12.4669C35.8882 12.526 36.0408 12.62 36.1687 12.7426C36.2966 12.8652 36.3969 13.0136 36.463 13.178C36.5291 13.3424 36.5594 13.5189 36.552 13.696L33.656 13.7039Z"
          fill="#333031"
        />
        <path
          d="M40.5521 12.24V11.304H39.1121V17.6H40.5521V14.296C40.5392 14.0825 40.5718 13.8688 40.6477 13.6688C40.7236 13.4689 40.841 13.2873 40.9922 13.1361C41.1434 12.9849 41.325 12.8675 41.5249 12.7916C41.7249 12.7158 41.9386 12.6832 42.1521 12.696C42.3278 12.6836 42.5043 12.6836 42.6801 12.696V11.256C42.5681 11.256 42.4401 11.256 42.2721 11.256C41.9212 11.2378 41.5725 11.3209 41.2675 11.4954C40.9626 11.6698 40.7142 11.9283 40.5521 12.24Z"
          fill="#333031"
        />
        <path
          d="M45.9839 11.2C45.5688 11.2077 45.1596 11.2996 44.7812 11.4703C44.4028 11.641 44.0629 11.8868 43.7824 12.1928C43.5018 12.4987 43.2864 12.8586 43.1491 13.2504C43.0119 13.6422 42.9556 14.0578 42.9839 14.472C42.9505 14.8925 43.0052 15.3155 43.1445 15.7137C43.2839 16.112 43.5048 16.4767 43.7932 16.7847C44.0816 17.0927 44.4311 17.337 44.8193 17.5022C45.2076 17.6674 45.626 17.7497 46.0479 17.744C46.6226 17.7684 47.1907 17.6137 47.6736 17.3013C48.1566 16.9889 48.5306 16.5341 48.7439 16L47.5119 15.528C47.4184 15.8371 47.2225 16.1053 46.9565 16.2884C46.6905 16.4716 46.3701 16.5589 46.0479 16.536C45.8231 16.5339 45.601 16.4872 45.3944 16.3985C45.1879 16.3098 45.001 16.181 44.8447 16.0195C44.6884 15.8579 44.5657 15.6669 44.4839 15.4576C44.402 15.2482 44.3626 15.0247 44.3679 14.8H48.7999V14.32C48.7999 12.568 47.8079 11.2 45.9839 11.2ZM44.4399 13.704C44.4975 13.3394 44.6845 13.0079 44.9665 12.7699C45.2486 12.5319 45.6069 12.4034 45.9759 12.408C46.1524 12.393 46.3301 12.4159 46.4972 12.4749C46.6642 12.534 46.8167 12.628 46.9446 12.7506C47.0725 12.8732 47.1728 13.0216 47.2389 13.186C47.305 13.3504 47.3353 13.5269 47.3279 13.704H44.4399Z"
          fill="#333031"
        />
        <path
          d="M54.0481 11.9999C53.8261 11.7394 53.5483 11.5323 53.2353 11.3938C52.9223 11.2553 52.5822 11.189 52.2401 11.1999C50.4721 11.1999 49.4641 12.6799 49.4641 14.4719C49.4641 16.2639 50.4721 17.7439 52.2401 17.7439C52.5824 17.7566 52.9232 17.6912 53.2364 17.5526C53.5497 17.4139 53.8273 17.2058 54.0481 16.9439V17.5999H55.4881V8.99194H54.0481V11.9999ZM54.0481 14.6799C54.0804 14.9081 54.063 15.1404 53.9972 15.3612C53.9313 15.582 53.8186 15.7859 53.6667 15.9591C53.5148 16.1323 53.3272 16.2707 53.1169 16.3647C52.9066 16.4587 52.6785 16.5062 52.4481 16.5039C51.4001 16.5039 50.8481 15.7039 50.8481 14.4879C50.8481 13.2719 51.4081 12.4719 52.4481 12.4719C53.3361 12.4719 54.0481 13.1199 54.0481 14.3039V14.6799Z"
          fill="#333031"
        />
        <path
          d="M63.4479 11.1999C63.0994 11.1847 62.752 11.2488 62.4319 11.3874C62.1118 11.5259 61.8273 11.7354 61.5999 11.9999V8.99194H60.1599V17.5999H61.5999V16.9359C61.8231 17.1971 62.1023 17.4046 62.4167 17.5431C62.731 17.6816 63.0726 17.7475 63.4159 17.7359C65.1759 17.7359 66.1839 16.2479 66.1839 14.4639C66.1839 12.6799 65.2079 11.1999 63.4479 11.1999ZM63.1999 16.4799C62.9695 16.4822 62.7414 16.4347 62.5311 16.3407C62.3208 16.2467 62.1332 16.1083 61.9813 15.9351C61.8294 15.7619 61.7167 15.558 61.6509 15.3372C61.585 15.1164 61.5676 14.8841 61.5999 14.6559V14.2799C61.5999 13.0959 62.2719 12.4479 63.1999 12.4479C64.2399 12.4479 64.7999 13.2479 64.7999 14.4639C64.7999 15.6799 64.1999 16.4799 63.1999 16.4799Z"
          fill="#333031"
        />
        <path
          d="M69.544 15.728L68 11.304H66.48L68.8 17.376L68.56 17.968C68.5192 18.1708 68.4014 18.3499 68.2314 18.4678C68.0613 18.5856 67.8522 18.633 67.648 18.6C67.4882 18.6116 67.3278 18.6116 67.168 18.6V19.808C67.3613 19.8367 67.5566 19.8501 67.752 19.848C68.2514 19.8843 68.7478 19.7439 69.1543 19.4515C69.5608 19.159 69.8516 18.7331 69.976 18.248L72.568 11.352H71.056L69.544 15.728Z"
          fill="#333031"
        />
        <path
          d="M100 9.94401C99.6841 9.94218 99.371 10.0027 99.0786 10.1222C98.7862 10.2416 98.5202 10.4176 98.296 10.64L98.184 10.088H96.272V20.424L98.448 19.952V17.448C98.8808 17.8105 99.4275 18.0087 99.992 18.008C101.544 18.008 102.968 16.736 102.968 13.92C102.968 11.344 101.528 9.94401 100 9.94401ZM99.48 16.048C99.2891 16.059 99.0982 16.0274 98.9211 15.9554C98.744 15.8835 98.5851 15.773 98.456 15.632V12.344C98.5856 12.1979 98.7463 12.0825 98.9262 12.0064C99.1061 11.9303 99.3008 11.8953 99.496 11.904C100.296 11.904 100.84 12.808 100.84 13.968C100.84 15.128 100.288 16.048 99.48 16.048Z"
          fill="#333031"
        />
        <path
          d="M106.984 9.94397C104.888 9.94397 103.616 11.752 103.616 14.032C103.616 16.728 105.12 18.096 107.264 18.096C108.101 18.1096 108.927 17.9086 109.664 17.512V15.712C108.995 16.0497 108.254 16.2199 107.504 16.208C106.64 16.208 105.904 15.896 105.792 14.848H110.096C110.096 14.728 110.096 14.256 110.096 14.048C110.168 11.752 109.08 9.94397 106.984 9.94397ZM105.808 13.2C105.808 12.192 106.424 11.768 106.968 11.768C107.512 11.768 108.088 12.192 108.088 13.2H105.808Z"
          fill="#333031"
        />
        <path
          d="M90.4 10.6879L90.264 10.0319H88.408V17.7999H90.552V12.536C90.7456 12.3009 91.0064 12.1305 91.2994 12.0476C91.5925 11.9646 91.9039 11.9732 92.192 12.072V10.0319C91.8647 9.90374 91.5018 9.89966 91.1717 10.0205C90.8416 10.1413 90.5671 10.3787 90.4 10.6879Z"
          fill="#333031"
        />
        <path
          d="M79.5679 12.3681C79.5679 12.0161 79.8559 11.8801 80.3119 11.8801C81.0677 11.8963 81.8084 12.0939 82.4719 12.4561V10.3521C81.7859 10.0747 81.0518 9.93602 80.3119 9.94406C78.5439 9.94406 77.3679 10.8881 77.3679 12.4721C77.3679 14.9521 80.6799 14.5521 80.6799 15.6161C80.6799 16.0321 80.3279 16.1681 79.8799 16.1681C79.0347 16.1187 78.2128 15.8721 77.4799 15.4481V17.6001C78.2352 17.9369 79.0529 18.1114 79.8799 18.1121C81.6879 18.1121 82.9359 17.1921 82.9359 15.5841C82.8959 12.8961 79.5679 13.3761 79.5679 12.3681Z"
          fill="#333031"
        />
        <path
          d="M85.8241 8.19202L83.7041 8.65602V15.8C83.6932 16.1011 83.7437 16.4012 83.8527 16.6821C83.9617 16.9629 84.1268 17.2186 84.338 17.4335C84.5491 17.6484 84.8019 17.818 85.0808 17.9319C85.3597 18.0458 85.6589 18.1017 85.9601 18.096C86.4857 18.1195 87.0093 18.0181 87.4881 17.8V16C87.2081 16.12 85.8401 16.528 85.8401 15.2V12H87.4881V10.128H85.8401L85.8241 8.19202Z"
          fill="#333031"
        />
        <path d="M93 9.36003L95.184 8.90403V7.17603L93 7.63203V9.36003Z" fill="#333031" />
        <path d="M95.184 10.088H93V17.8H95.184V10.088Z" fill="#333031" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="120" height="27.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
