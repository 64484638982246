import React from "react";
import JoinButt from "./JoinButt";
import CardOrLast4 from "./CardOrLast4";

const ShowStripeInputFields = ({ activateSub, actions, rdDisabled, plan, user }) => {
  return (
    <>
      <div className="rounded-b-large h-16 w-full mt-8" style={{ background: "#e5d8cb" }}></div>
      <div className="w-full py-8 text-center bg-coral rounded-b-large -mt-8 px-4 sm:px-10">
        <div className="rounded-3xl sm:mx-4 pt-2 px-4 sm:px-16">
          <div>
            <CardOrLast4 title={"Add New Card"} actions={actions} plan={{ id: plan }} user={user} />
          </div>
          <div className="w-full">
            <JoinButt
              title={"Subscribe"}
              clr=""
              disabled={rdDisabled}
              clk={() => activateSub(plan, true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowStripeInputFields;
