import React, { useEffect, useState } from "react";
import { getBillingData } from "apiClient/requests";
import apiClient from "apiClient";
import { handleError2 } from "apiClient/errors";
import PlansSection from "components/Billing/Plans";

export default function Plans(props) {
  const [billData, setBillData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPlanCardInput, setShowPlanCardInput] = useState(false);

  const getBillData = async () => {
    try {
      const req = getBillingData();
      const response = await apiClient(req);
      const data = response.data || {};
      let paymentMethods = data.payment_methods || [];
      let defaultId = data?.customer?.invoice_settings?.default_payment_method?.id || "";

      // moving the default card at first position
      if (defaultId) {
        paymentMethods.sort((x, y) => {
          return x.id === defaultId ? -1 : y.id === defaultId ? 1 : 0;
        });
      }
      data.payment_methods = paymentMethods;
      setBillData(data);
    } catch (err) {
      handleError2(err);
    }
  };

  useEffect(() => {
    getBillData();
  }, []);

  useEffect(() => {
    if (billData?.plans) {
      setLoading(false);
    }
  }, [billData]);

  if (!billData || loading) {
    return <div className="text-center"></div>;
  }

  return (
    <PlansSection
      user={props.user}
      setBillData={setBillData}
      billData={billData}
      plans={billData.plans || []}
      setUserData={props.setUserData}
      showCard={showPlanCardInput}
      setShowCard={setShowPlanCardInput}
      savedCardHidden={false}
      showCardInputField={!billData?.payment_methods?.length}
      showLast4={true}
      title={"Join the Community"}
      btnText={"Join Membership"}
      trialExpire={"true"}
    />
  );
}
