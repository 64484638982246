import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactPixel from "react-facebook-pixel";
// import { globalHistory } from "@reach/router";
import "./index.css";
import App from "./App";

// context provider
import { ScheduleProvider } from "./contexts/ScheduleContext";
import CommentsProvider from "./contexts/Comments";
import NotificationsProvider from "./contexts/Notifications";
import UserProvider from "./contexts/User";

// Hacktacular hack to hack page views
// globalHistory.listen(({ location }) => {
//   console.log(`history changed! ${location}`);
//   console.log(location);
// });

// Init sentry!
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});

// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init("2194395710851405", {}, options);
ReactPixel.pageView();

// import reportWebVitals from './reportWebVitals';
ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <ScheduleProvider>
        <UserProvider>
          <NotificationsProvider>
            <CommentsProvider>
              <App />
            </CommentsProvider>
          </NotificationsProvider>
        </UserProvider>
      </ScheduleProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
