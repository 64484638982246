import React, { useState } from "react";
import Plus from "common/icons/Plus";
import Minus from "common/icons/Minus";
import { clkTryForYourselfTrack } from "common/Ana";

const Heading = ({ text, isOpened, handleClick }) => {
  return (
    <div
      className="flex items-center justify-between py-3 w-full font-semibold cursor-pointer text-lg"
      onClick={handleClick}
      style={{ borderTop: "1px solid #DEE2E6" }}
    >
      {text}
      <span className="h-6 w-6 flex items-center justify-center text-teal-500">
        {isOpened ? <Minus /> : <Plus />}
      </span>
    </div>
  );
};

export default function FAQ({ btnText, linkId }) {
  const [state, setState] = useState({ 2: true });

  const handleClick = (index) => {
    setState({ ...state, [index]: !state[index] });
  };
  return (
    <div className="text-left mt-5 sm:mt-10 sm:mx-2 xl:mx-0 bg-lbeige bg-opacity-70 py-5 sm:pt-14 sm:pb-10 pl-5 pr-2 sm:px-10 xl:px-16 rounded-3xl">
      <h2 className="text-4xl font-semibold sm:font-bold text-charcoal mb-8">
        Frequently asked questions
      </h2>
      {/* 1 */}
      <div>
        <Heading
          text={"What is Chorus exactly?"}
          isOpened={state[0]}
          handleClick={() => handleClick(0)}
        />
        {state[0] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              Live and on-demand classes, led by expert instructors, that combine breathing to the
              beat of awesome music with stress-relief techniques to create an
              <span className="font-semibold">
                {" "}
                immediate release of stress in your mind and body.
              </span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* 2 */}
      <div>
        <Heading
          text={"How is it different?"}
          isOpened={state[1]}
          handleClick={() => handleClick(1)}
        />
        {state[1] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              Unlike traditional meditation, which is typically done alone, quiet and slow, often
              making it challenging for beginners - Chorus is:
            </p>
            <ul className="list-inside list-disc">
              <li className="ml-4">
                <span className="font-semibold">active</span>, with{" "}
                <span className="font-semibold">breathing to the beat</span>
              </li>
              <li className="ml-4">
                <span className="font-semibold">social</span>, with{" "}
                <span className="font-semibold">warm, personable teachers, </span> and
              </li>
              <li className="ml-4">
                <span className="font-semibold">fun</span>, with{" "}
                <span className="font-semibold">new and popular music</span>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 3 */}
      <div>
        <Heading
          text={"What’s the music like? 🎵 🎵"}
          isOpened={state[2]}
          handleClick={() => handleClick(2)}
        />
        {state[2] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              At Chorus we intentionally use popular music (think: Beyoncé, Odesza, Bon Iver, etc),
              to guide the experience and fill it with emotion. By pairing the Chorus Method with
              music you know and love, it makes the experience fun and more accessible than
              traditional methods.
            </p>
            <p className="max-w-screen-md text-charcoal mt-2">
              Got a favorite artist? Find the teacher who best fits your vibe and dive right in!
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 4 */}
      <div>
        <Heading
          text={"How does the breathing help me?"}
          isOpened={state[3]}
          handleClick={() => handleClick(3)}
        />
        {state[3] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              The breathing pattern we use at Chorus improves your nervous system health by making
              your body better able to shift into relaxation and focused states. It also creates a
              tingling sensation that leaves you physically and mentally calmer after
              <span className="font-semibold">
                {" "}
                your very first session (it also just feeeeels great!)
              </span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 5 */}
      <div>
        <Heading
          text={"How will Chorus improve my life?"}
          isOpened={state[4]}
          handleClick={() => handleClick(4)}
        />
        {state[4] ? (
          <div className="py-3 max-w-screen-md">
            <p className="text-charcoal">
              Chorus will reduce your stress so you can unlock the very best version of you! This
              might look like:
            </p>
            <ul className="list-inside list-disc">
              <li className="ml-4">Less stress and anxiety</li>
              <li className="ml-4">Better sleep</li>
              <li className="ml-4">More control over your emotions</li>

              <li className="ml-4">
                Fewer feelings of overwhelm, burnout, lack of direction, family pressure, societal
                pressure (ooof...the list goes on!)
              </li>
              <li className="ml-4">More patience with loved ones</li>
              <li className="ml-4">The healthiest, happiest version of you!</li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 6 */}
      <div>
        <Heading
          text={"What is the expert-led Chorus Method?"}
          isOpened={state[5]}
          handleClick={() => handleClick(5)}
        />
        {state[5] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              The proprietary Chorus Method was designed based on incredible traditions dating back
              thousands of years and{" "}
              <span className="font-semibold">
                {" "}
                research on improving mental and physical health.{" "}
              </span>
            </p>
            <p className="max-w-screen-md text-charcoal mt-2">
              <span className="font-semibold">Our teachers are world-class practitioners </span>
              from multiple emotional and physical health disciplines, and bring their years of
              wisdom and expert teaching to Chorus.
            </p>
            <p className="max-w-screen-md text-charcoal mt-2">
              To learn more about the Chorus Method, check out our blog post:{" "}
              <a
                className="underline text-cblue hover:text-coral"
                href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
              >
                How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief,
                Nervous System Health, and a Life of Happiness.
              </a>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 7 */}
      <div>
        <Heading
          text={"What do I get with the membership?"}
          isOpened={state[6]}
          handleClick={() => handleClick(6)}
        />
        {state[6] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              Unlimited access to all our live and on-demand classes, designed specifically to help
              you energize, relax, or sleep! Classes are as short as 10-minutes making them easy to
              fit into any day.
            </p>
            <p className="max-w-screen-md text-charcoal mt-2">
              Plus, as a Chorus member, you receive 1 guest pass per class to bring a friend or
              loved one.
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 8 */}
      <div>
        <Heading
          text={"Will I be able to talk directly with the teacher?"}
          isOpened={state[7]}
          handleClick={() => handleClick(7)}
        />
        {state[7] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">Yes!</p>
            <p className="max-w-screen-md text-charcoal">
              Chorus is all about <span className="font-semibold"> real, human, connection.</span>{" "}
              Your teacher is there with you in every live and on-demand class - available through
              sychronous and asychonous chat, no matter what kind of class you take.
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 9 */}
      <div>
        <Heading
          text={"How much does Chorus cost?"}
          isOpened={state[8]}
          handleClick={() => handleClick(8)}
        />
        {state[8] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              Chorus is free for your first 14 days. Then $10 per month when you pay every 12
              months, or $20 per month when you pay every 6 months, or $40 per month when you pay
              every 1 month.{" "}
              <span className="font-semibold">
                That’s less than a dollar a day to live your very best life!
              </span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* 10 */}
      <div>
        <Heading
          text={"What if I try it and don’t like it?"}
          isOpened={state[9]}
          handleClick={() => handleClick(9)}
        />
        {state[9] ? (
          <div className="py-3">
            <p className="max-w-screen-md text-charcoal">
              Chorus is free for your first 14 days. Then your first month is completely risk free.
              Try it for the next 30 days and still don’t love it? We’ll refund your entire
              purchase.
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* end */}
      <div className="w-full" style={{ borderTop: "1px solid #DEE2E6" }}></div>

      <div className="w-full text-center">
        <a
          onClick={() => {
            clkTryForYourselfTrack();
            return true;
          }}
          href={linkId}
        >
          <button className="mt-6 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
            <p className="text-lg font-semibold text-white">{btnText}</p>
          </button>
        </a>
      </div>
    </div>
  );
}
