import React from "react";
import chorusFlowList from "../../../common/constants/fblanding/chorusFlow.json";

export default function ChorusGuide() {
  return (
    <div className="text-center mt-5 sm:mt-8 sm:mx-2 xl:mx-0">
      <div className="hidden">
        <div className="w-12 sm:mt-12 w-16 h-8 sm:mt-14 h-10 w-14 mt-8 sm:mt-10 h-14 w-24 hideen"></div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-3 mx-4 sm:mx-0 sm:gap-8">
        {chorusFlowList.map((data, i) => (
          <div
            key={`guide-${i}`}
            className="bg-white mx-auto sm:max-w-72 w-full h-64 sm:h-72 rounded-xlarge"
          >
            {data.images === 2 ? (
              <div className="flex justify-center items-center">
                <img src={data.image} className={`${data.classes}`} />
                <img src={data.image1} className={`${data.classes1}`} />
              </div>
            ) : (
              <img src={data.image} className={`mx-auto ${data.classes}`} />
            )}
            <p className="text-lg sm:text-xl font-semibold text-purp mt-4 sm:mt-6">{data.title}</p>
            {data.secondaryTitle ? (
              <p className="text-sm font-normal text-dcoral mt-2 sm:mt-4 mx-4">
                {data.secondaryTitle}
              </p>
            ) : (
              <></>
            )}
            <p className="text-sm font-normal text-purp mt-2 sm:mt-4 mx-5">{data.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
