import React, { useState, useEffect } from "react";
import { firestoreDb } from "../firebase";
import {
  doc,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  onSnapshot,
  collection,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import {
  commentsCollection,
  repliesCollection,
  usersCollection,
  reactionsCollection,
} from "../common/constants";
import { addCommentTrack, addReplyTrack, careTrack } from "common/Ana";

export const getCommentsInitialState = () => ({
  comments: [],
  replies: {},
  users: {},
  addComment: (classId, content, user) => {},
  deleteComment: (commentId, replies) => {},
  deleteReply: (reply) => {},
  addCommentReaction: (commentId, reactionType, user) => {},
  removeCommentReaction: (reaction) => {},
  addReply: (commentId, content, user) => {},
  addReplyReaction: (replyId, reactionType, user) => {},
  removeReplyReaction: (replyId, reaction) => {},
  subscribeToComments: (classId) => {},
  subscribeToReply: (commentId) => {},
  setUsers: (users) => {},
  commentsLoading: false,
});

const useComments = () => {
  const [comments, setComments] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [users, setUsers] = useState({});

  const addComment = async (classId, content, user) => {
    addCommentTrack(classId, false);

    const userRef = doc(firestoreDb, usersCollection, user.id);
    const object = {
      user_id: user.id,
      content,
      user_name: user.first_name + " " + user?.last_name,
      class_id: classId,
      created_at: new Date(),
      updated_at: new Date(),
      replies: [],
      reactions: [],
      user: userRef,
    };
    await addDoc(collection(firestoreDb, commentsCollection), object);
  };

  const getUsers = () => {
    let _users = {};

    const unsub = onSnapshot(
      collection(firestoreDb, usersCollection),
      (usersSnapshot) => {
        usersSnapshot.forEach((user) => {
          const userData = user.data();
          _users[user.id] = userData;
        });
        setUsers(_users);
      },
      (error) => {
        console.log(error);
      }
    );

    return unsub;
  };

  const subscribeToComments = (classId) => {
    if (!classId) {
      return;
    } else {
      setComments([]);
    }
    setCommentsLoading(true);

    const q = query(collection(firestoreDb, commentsCollection), where("class_id", "==", classId));
    const unsub = onSnapshot(
      q,
      async (querySnapshot) => {
        let _docs = [];
        for (let i in querySnapshot.docs) {
          const _doc = querySnapshot.docs[i];
          let cmnt = _doc.data();
          const userRef = doc(firestoreDb, cmnt?.user.path);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            cmnt.user = userSnap.data();
          }
          let commentId = _doc.id;
          //
          let reactions = [];

          for (let k = 0; k < cmnt.reactions.length; k++) {
            const reactionDoc = cmnt.reactions[k];

            const docRef = doc(firestoreDb, reactionDoc.path);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              let reaction = docSnap.data();
              reaction = {
                ...reaction,
                id: reactionDoc.id,
              };
              reactions.push(reaction);
            } else {
              console.log("No such document!");
            }
          }

          cmnt = {
            ...cmnt,
            id: commentId,
            reactions: reactions,
          };

          _docs.push(cmnt);
        }

        const _docsData = await Promise.all(_docs);

        setComments(_docsData);
        setCommentsLoading(false);
      },
      (error) => {
        setCommentsLoading(false);
      }
    );

    return () => {
      unsub();
    };
  };

  const addCommentReaction = async (commentId, reactionType, user) => {
    careTrack("comment");
    const userRef = doc(firestoreDb, usersCollection, user.id);
    const object = {
      type: reactionType,
      user_id: user?.id,
      parent_id: commentId,
      user: userRef,
      created_at: new Date(),
      updated_at: new Date(),
    };
    const reaction = await addDoc(collection(firestoreDb, reactionsCollection), object);

    const commentRef = doc(firestoreDb, commentsCollection, commentId);
    await updateDoc(commentRef, { reactions: arrayUnion(reaction) });
  };

  const removeCommentReaction = async (commentId, reaction) => {
    const commentRef = doc(firestoreDb, commentsCollection, commentId);
    const reactionRef = doc(firestoreDb, reactionsCollection, reaction.id);

    await updateDoc(commentRef, { reactions: arrayRemove(reactionRef) });
    await deleteDoc(reactionRef);
  };

  const addReply = async (commentId, content, user) => {
    const userRef = doc(firestoreDb, usersCollection, user.id);
    const object = {
      content,
      user_id: user?.id,
      user_name: user?.first_name + " " + user?.last_name,
      created_at: new Date(),
      updated_at: new Date(),
      reactions: [],
      comment_id: commentId,
      user: userRef,
    };

    addReplyTrack(commentId);

    const reply = await addDoc(collection(firestoreDb, repliesCollection), object);

    const commentRef = doc(firestoreDb, commentsCollection, commentId);
    await updateDoc(commentRef, { replies: arrayUnion(reply) });
  };

  const addReplyReaction = async (replyId, reactionType, user) => {
    careTrack("reply");
    const userRef = doc(firestoreDb, usersCollection, user.id);
    const object = {
      type: reactionType,
      user_id: user?.id,
      parent_id: replyId,
      user: userRef,
      created_at: new Date(),
      updated_at: new Date(),
    };

    const reaction = await addDoc(collection(firestoreDb, reactionsCollection), object);

    const replyRef = doc(firestoreDb, repliesCollection, replyId);
    await updateDoc(replyRef, { reactions: arrayUnion(reaction) });
  };

  const removeReplyReaction = async (replyId, reaction) => {
    const reactionRef = doc(firestoreDb, reactionsCollection, reaction.id);
    const replyRef = doc(firestoreDb, repliesCollection, replyId);

    await updateDoc(replyRef, { reactions: arrayRemove(reactionRef) });

    await deleteDoc(reactionRef);
  };

  const deleteComment = async (commentId, replies) => {
    const commentRef = doc(firestoreDb, commentsCollection, commentId);

    const q = query(
      collection(firestoreDb, reactionsCollection),
      where("parent_id", "==", commentId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((_doc) => {
      deleteDoc(_doc.ref);
    });

    replies.forEach((reply) => {
      deleteReply(reply);
    });
    await deleteDoc(commentRef);
  };

  const deleteReply = async (reply) => {
    const replyRef = doc(firestoreDb, repliesCollection, reply.id);
    const commentRef = doc(firestoreDb, commentsCollection, reply.comment_id);

    await updateDoc(commentRef, { replies: arrayRemove(replyRef) });

    const q = query(
      collection(firestoreDb, reactionsCollection),
      where("parent_id", "==", reply.id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((_doc) => {
      deleteDoc(_doc.ref);
    });
    await deleteDoc(replyRef);
  };

  return {
    subscribeToComments,
    addComment,
    deleteComment,
    deleteReply,
    addReply,
    addCommentReaction,
    removeCommentReaction,
    addReplyReaction,
    removeReplyReaction,
    comments,
    getUsers,
    users,
    commentsLoading,
  };
};

export default useComments;
