export default function SecureLock(props) {
  return (
    <svg
      className={`${props.classNames || "w-3"} inline-block`}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 7H2.33333C1.59695 7 1 7.59695 1 8.33333V13C1 13.7364 1.59695 14.3333 2.33333 14.3333H11.6667C12.403 14.3333 13 13.7364 13 13V8.33333C13 7.59695 12.403 7 11.6667 7Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66675 7V4.33333C3.66675 3.44928 4.01794 2.60143 4.64306 1.97631C5.26818 1.35119 6.11603 1 7.00008 1C7.88414 1 8.73198 1.35119 9.3571 1.97631C9.98223 2.60143 10.3334 3.44928 10.3334 4.33333V7"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
