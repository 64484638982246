import { clkTryForYourselfTrack } from "common/Ana";
import React from "react";

const GetReliefData = ({ btnText, linkId }) => {
  return (
    <div className="w-full px-2 sm:px-0 text-left">
      <h2 className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-thin	">
        Stress-relief
      </h2>
      <h2 className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-semibold">
        that’s fun. Finally.
      </h2>
      <p className="mt-6 text-left">
        Listen to an awesome playlist and feel an immediate release of stress in your body and mind.
      </p>
      <a
        onClick={() => {
          clkTryForYourselfTrack();
          return true;
        }}
        href={linkId}
      >
        <button className="mt-4 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
          <p className="text-lg font-semibold text-white">{btnText}</p>
        </button>
      </a>
    </div>
  );
};

const GetBreatheData = ({ btnText, linkId }) => {
  return (
    <div className="w-full px-2 sm:px-0 text-left">
      <h2 className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-thin	">
        Breathe to
      </h2>
      <h2 className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-semibold">
        the beat of music
      </h2>
      <p className="mt-6 text-left">
        From hip hop to indie to pop, our instructor-curated playlists will keep you motivated and
        engaged
      </p>
      <a
        onClick={() => {
          clkTryForYourselfTrack();
          return true;
        }}
        href={linkId}
      >
        <button className="mt-4 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
          <p className="text-lg font-semibold text-white">{btnText}</p>
        </button>
      </a>
    </div>
  );
};

export default function Benefits(props) {
  return (
    <div className="mx-2 md:mx-14 text-center mt-10 sm:mt-20">
      <div className="w-full sm:flex sm:justify-between sm:items-center">
        <div className="hidden sm:block w-5/12">
          <GetReliefData {...props} />
        </div>
        <div className="w-full sm:w-6/12 px-2 sm:px-0">
          <img
            alt="relief"
            src="https://storage.googleapis.com/chorus-public/img/etc/stress-relief-user.png"
          />
        </div>
        <div className="block sm:hidden mt-4">
          <GetReliefData {...props} />
        </div>
      </div>
      <div className="w-full sm:flex sm:justify-between sm:items-center mt-12">
        <div className="w-full sm:w-84 mx-auto sm:ml-0 w-6/12 px-2 sm:px-0">
          <img
            alt="breathe"
            className="w-full sm:w-84 -ml-8"
            src="https://storage.googleapis.com/chorus-public/img/etc/breathe-user.png"
          />
        </div>
        <div className="sm:w-6/12 my-6 sm:my-0">
          <GetBreatheData {...props} />
        </div>
      </div>
    </div>
  );
}
