import React from "react";
import { Link } from "@reach/router";

export default function ClassesTabs({ label }) {
  const mainClass = "rounded-md p-3 min-w-18 text-center";
  const activeClass = "bg-coral text-white";
  const inActiveClass = "text-purpc1 cursor-pointer hover:bg-gray-300";

  return (
    <div className="relative bg-white rounded-md shadow-md px-4 p-2 flex ietms-center justify-between w-84 mx-auto">
      <Link to="/" className={`${mainClass} ${label === "live" ? activeClass : inActiveClass}`}>
        Live
      </Link>
      <Link
        to="/on-demand"
        className={`${mainClass} ${label === "on-demand" ? activeClass : inActiveClass}`}
      >
        On-Demand
      </Link>
      <Link
        to="/events"
        className={`${mainClass} ${label === "events" ? activeClass : inActiveClass}`}
      >
        Events
      </Link>
    </div>
  );
}
