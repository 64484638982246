import React, { useState } from "react";
import FeedbackSent from "components/Dialogs/FeedbackSent/index";
import SkipOnBoarding from "components/Dialogs/SkipOnBoarding";
import { navigate } from "@reach/router";
import { contact } from "apiClient/requests";
import apiClient from "apiClient";
import { submitFeedbackTrack } from "common/Ana";
import { getEntryFlow } from "common/Halps";

export default function SkipOnboardingSteps({ onClose, user }) {
  const [showFeedbackSent, setShowFeedbackSent] = useState(false);

  const sendFeedBack = async (content) => {
    try {
      submitFeedbackTrack(getEntryFlow());
      const reqData = {
        comments: content,
        email: user?.email || "",
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        type: "onboard_skip_feedback",
      };
      const req = contact(reqData);
      await apiClient(req);
    } catch (err) {}
    setShowFeedbackSent(true);
  };

  const handleClose = () => {
    setShowFeedbackSent(false);
    onClose();
    navigate("/fbretreg");
  };

  return showFeedbackSent ? (
    <FeedbackSent onClose={handleClose} />
  ) : (
    <SkipOnBoarding sendFeedBack={sendFeedBack} onClose={onClose} />
  );
}
