import { clkTryForYourselfTrack } from "common/Ana";
import React from "react";

export default function ClassesInfo({ btnText, linkId }) {
  return (
    <div className="mx-2 md:mx-14 text-center mt-10 sm:mt-20">
      <div className="w-full sm:flex sm:justify-between sm:items-center mt-12">
        <div className="mx-auto sm:ml-0 sm:w-5/12 px-2 sm:px-0">
          <img
            alt="breathe"
            className="w-80 mx-auto sm:-ml-8"
            src="https://storage.googleapis.com/chorus-public/img/land/expert-teacher.png"
          />
        </div>
        <div className="w-full sm:w-6/12 px-2 sm:px-0 text-left mt-6 sm:mt-0">
          <p className="text-4xl sm:text-5xl lg:text-6xl text-left font-thin text-charcoal">
            <span className="text-4xl sm:text-5xl lg:text-6xl text-left font-semibold mr-1">
              Expert-led
            </span>
            by
          </p>{" "}
          <p className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-thin">
            world-class
          </p>
          <p className="text-charcoal text-4xl sm:text-5xl lg:text-6xl text-left font-thin">
            teachers
          </p>
          <p className="mt-6 text-left text-lg text-charcoal max-w-84">
            Designed and led by our world-class teachers, Chorus classes are
          </p>
          <p className="text-left text-charcoal font-semibold">as short as 10-minutes</p>
          <a
            onClick={() => {
              clkTryForYourselfTrack();
              return true;
            }}
            href={linkId}
          >
            <button className="mt-4 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
              <p className="text-lg font-semibold text-white">{btnText}</p>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
