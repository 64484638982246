import React from "react";
import { format, parseISO } from "date-fns";
import { FB, Google, Lock, Spinner } from "./Icons";
import Mail from "common/icons/Mail";
import { navigate } from "@reach/router";
import { getFBOauthRedirect, getGoogleOauthRedirect } from "./Params";
import {
  clkBlogHowMeditationTrack,
  clkContinueEmail,
  clkContinueFB,
  clkContinueGoogle,
} from "./Ana";

export function Filters(props) {
  return (
    <div className="bg-white rounded-md px-4 mx-0 pt-1 md:pt-2 md:max-w-lg mx-auto shadow-md">
      <div className="flex justify-between">
        <div className="flex justify-between w-full">
          <div className="flex ">
            <p className="text-md">Filters</p>
            <p
              onClick={props.clear}
              className={`text-md ml-2 text-red text-coral cursor-pointer underline ${
                props.canClear ? "" : "invisible"
              } `}
            >
              Reset
            </p>
          </div>
          {/* <p htmlFor="checked" className="inline-flex items-center cursor-pointer">
            <span className="mr-2 text-sm">Class taken</span>
            <span className="relative">
              <span className="block w-10 h-6 bg-purpc1 rounded-full shadow-inner"></span>
              <span className="absolute block w-4 h-4 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out bg-white transform translate-x-full"></span>
            </span>
          </p> */}
        </div>
      </div>

      <div className="bg-white pt-2 w-full md:max-w-lg mx-auto">
        <div className="w-full border border-solid border-purpc2"></div>
      </div>

      <div className="bg-white w-full px-0 mx-0 pb-2  md:p-4 md:pt-2 md:max-w-lg mb-3 md:mx-auto flex mb-4">
        <div className=" flex-grow text-center ">
          {/* <ClassMenu /> */}
          <select
            className="text-sm border-transparent text-purp focus:border-coral focus:ring-coral"
            id="filter_type"
            name="filter_type"
            onChange={props.filterChange}
            value={props.filter_type}
          >
            <option value="none" className="">
              Class Type
            </option>
            <option value="energize" className="">
              Energize
            </option>
            <option value="focus" className="">
              Focus
            </option>
            <option value="unwind" className="">
              Unwind
            </option>
            {props.ondemand ? (
              <option value="sleep" className="">
                Sleep
              </option>
            ) : (
              ""
            )}
          </select>
          <div style={{ width: "130px", margin: "auto" }}>
            <p className="text-coral text-sm pl-3 text-left">Pick your mood</p>
          </div>
        </div>
        <div className="flex-grow text-center">
          <select
            className="text-sm border-transparent text-purp focus:border-coral focus:ring-coral"
            id="filter_duration"
            name="filter_duration"
            onChange={props.filterChange}
            value={props.filter_duration}
          >
            <option value="none">Duration</option>
            {props.ondemand ? <option value="10">10min</option> : ""}
            {props.ondemand ? <option value="15">15min</option> : ""}
            <option value="30">30min</option>
            <option value="50">50min</option>
          </select>
          <div style={{ width: "115px", margin: "auto" }}>
            <p className="text-coral text-sm pl-3 text-left">How much time do you have?</p>
          </div>
        </div>
        <div className="flex-grow text-center">
          <select
            className="text-sm border-transparent text-purp focus:border-coral focus:ring-coral"
            id="filter_teacher"
            name="filter_teacher"
            onChange={props.filterChange}
            value={props.filter_teacher}
          >
            <option value="none">Teacher</option>
            {props.teachers
              .filter(
                (t) =>
                  !(!props.ondemand && (t.id === "dana" || t.id === "luigi" || t.id === "kevin"))
              )
              .map((t) => (
                <option key={t.id} value={t.id}>
                  {t.first_name}
                </option>
              ))}
          </select>
          <div style={{ width: "112px", margin: "auto" }}>
            <p className="text-coral text-sm pl-3 text-left">Pick your vibe</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// Note a version of this exists in views/billing/pones
export function ClassImgs3(props) {
  return (
    <div className="grid grid-flow-col auto-cols-auto gap-x-1 min-h-44 min-h-60">
      <div>
        <img
          src="https://storage.googleapis.com/chorus-public/img/bill/chorus-class-1.png"
          alt=""
          className="rounded-lg"
        />
      </div>
      <div>
        <img
          src="https://storage.googleapis.com/chorus-public/img/bill/chorus-class-2.png"
          alt=""
          className="rounded-lg"
        />
      </div>
      <div>
        <img
          src="https://storage.googleapis.com/chorus-public/img/bill/chorus-class-3.png"
          alt=""
          className="rounded-lg"
        />
      </div>
    </div>
  );
}

// ***WARNING!!!*** MAKE SURE TO CHANGE ClassData.js as well!!!!!!
export function ClassDesc(props) {
  const type = props.type;
  let desc = "";
  if (type === "energize") {
    return <EnergizeDesc loc={props.loc} />;
  } else if (type === "focus") {
    return <FocusDesc loc={props.loc} />;
  } else if (type === "unwind") {
    return <UnwindDesc loc={props.loc} />;
  } else if (type === "sleep") {
    return <SleepDesc loc={props.loc} />;
  } else if (type === "community") {
    return <CommunityDesc loc={props.loc} />;
  } else {
    return <Chor10Desc loc={props.loc} />;
  }
}

export function EnergizeDesc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-medium text-lg my-5">Energize</h2>
      <h3 className="font-medium text-md text-purp mt-3">Take in the morning</h3>
      <p className="mt-1 text-sm text-purp">
        Take this class in the morning (before you even get out of bed) to{" "}
        <strong className="font-semibold" className="font-semibold">
          start your day on the right foot
        </strong>
        . ☀️
      </p>

      <h3 className="font-medium text-md text-purp mt-5">30 minute classes</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus Energize is a 30 minute class designed to increase{" "}
        <strong className="font-semibold" className="font-semibold">
          clarity and set your day up for success.
        </strong>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Background</h3>
      <p className="mt-1 text-purp text-sm">
        All our classes follow{" "}
        <strong className="font-semibold" className="font-semibold">
          The Chorus Method
        </strong>
        , and are based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold" className="font-semibold">
          improving mental and physical health
        </strong>
        .
      </p>
      <p className="mt-2 text-purp text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>
      <h3 className="font-medium text-md text-purp mt-5">Community Feedback</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus is a community built for you. We are always eager to hear from you. If you have any
        feedback, please drop it{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.typeform.com/to/xTNHib3p"
        >
          here
        </a>
      </p>
    </div>
  );
}

export function FocusDesc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-medium text-lg my-5">Focus</h2>
      <h3 className="font-medium text-md text-purp mt-3">Take in the afternoon</h3>
      <p className="mt-1 text-purp text-sm">
        Take this class in the afternoon to{" "}
        <strong className="font-semibold">beat the midday slump!</strong>! ⚡️
      </p>

      <h3 className="font-medium text-md text-purp mt-5">30 minute class</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus Focus is a 30 minute class, designed to help you{" "}
        <strong className="font-semibold">refocus during your day</strong>. Skip that afternoon cup
        of coffee and reset with Chorus instead
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Background</h3>
      <p className="mt-1 text-purp text-sm">
        All our classes follow <strong className="font-semibold">The Chorus Method</strong>, and are
        based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold">improving mental and physical health</strong>.
      </p>
      <p className="mt-2 text-purp text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Community Feedback</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus is a community built for you. We are always eager to hear from you. If you have any
        feedback, please drop it{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.typeform.com/to/xTNHib3p"
        >
          here
        </a>
      </p>
    </div>
  );
}

export function UnwindDesc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-medium text-lg my-5">Unwind</h2>
      <h3 className="font-medium text-md text-purp mt-3">Take in the evening</h3>
      <p className="mt-1 text-purp text-sm">
        Take this class in the evening to{" "}
        <strong className="font-semibold">
          cultivate a deep sense of calm and clear your mind.
        </strong>
        . 😌
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Signature 50 minute class</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus Unwind is our signature 50-minute class.* Designed to{" "}
        <strong className="font-semibold">reduce anxiety</strong> and allow you to{" "}
        <strong className="font-semibold">connect more deeply with yourself</strong> and with a
        caring community.
      </p>
      <p className="mt-2 text-purp text-sm">
        * Don’t worry. It won’t feel like meditating for 50-minutes. This class provides the most
        immersive Chorus experience, therefore we recommend{" "}
        <strong className="font-semibold">newcomers start with a Chorus Unwind class. </strong>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Journaling</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus Unwind features brief journaling at the beginning and end of class to allow for a
        deeper connection with yourself and your intentions.
      </p>
      <p className="mt-2 text-purp text-sm">
        So, bring a journal and get ready to sink into the experience 📓
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Community</h3>
      <p className="mt-1 text-purp text-sm">
        Unwind classes are followed by an optional opportunity to stay and connect with the
        community for those who would like to participate.
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Background</h3>
      <p className="mt-1 text-purp text-sm">
        All our classes follow <strong className="font-semibold">The Chorus Method</strong>, and are
        based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold">improving mental and physical health</strong>.
      </p>
      <p className="mt-2 text-purp text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>
      <h3 className="font-medium text-md text-purp mt-5">Community Feedback</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus is a community built for you. We are always eager to hear from you. If you have any
        feedback, please drop it{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.typeform.com/to/xTNHib3p"
        >
          here
        </a>
      </p>
    </div>
  );
}

export function CommunityDesc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-bold text-2xl mt-5">Community</h2>
      <h3 className="font-medium text-xl mt-3">Take in the evening</h3>
      <p className="mt-1 text-sm">
        Take this class in the evening to{" "}
        <strong className="font-semibold">
          cultivate a deep sense of calm and clear your mind.
        </strong>
        . 😌
      </p>

      <h3 className="font-medium text-xl mt-5">50 minute class</h3>
      <p className="mt-1 text-sm">
        The 50 minute Community class is our original signature class. Designed to reduce anxiety
        and allow you to{" "}
        <strong className="font-semibold">
          connect more deeply with yourself and in community.
        </strong>
        .
      </p>

      <h3 className="font-medium text-xl mt-5">Live Only</h3>
      <p className="mt-1 text-sm">
        These classes are only available live. They are not recorded for on-demand.
      </p>

      <h3 className="font-medium text-xl mt-5">Community</h3>
      <p className="mt-1 text-sm">
        These classes are followed by an opportunity to stay and connect with the community for
        those who would like to participate.
      </p>

      <h3 className="font-medium text-xl mt-5">Background</h3>
      <p className="mt-1 text-sm">
        All our classes follow <strong className="font-semibold">The Chorus Method</strong>, and are
        based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold">improving mental and physical health</strong>.
      </p>
      <p className="mt-2 text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>
    </div>
  );
}

export function SleepDesc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-medium text-lg my-5">Sleep</h2>
      <h3 className="font-medium text-md text-purp mt-3">Take at night</h3>
      <p className="mt-1 text-purp text-sm">
        Take this class at night to ease into a{" "}
        <strong className="font-semibold">restorative night of sleep</strong> 💤
      </p>

      <h3 className="font-medium text-md text-purp mt-5">15 minute classes</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus Sleep is a 15 minute class designed to{" "}
        <strong className="font-semibold">
          help you release the day, and improve your sleep - leaving you refreshed and well rested.{" "}
        </strong>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">First Timer’s How-To-Video</h3>
      <p className="mt-1 text-purp text-sm">
        If this is your first Chorus class, head on over to the{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.com/faq/"
        >
          Welcome Video on our FAQ page
        </a>{" "}
        <strong className="font-semibold">before you begin.</strong>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Background</h3>
      <p className="mt-1 text-purp text-sm">
        All our classes follow <strong className="font-semibold">The Chorus Method</strong>, and are
        based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold">improving mental and physical health</strong>.
      </p>
      <p className="mt-2 text-purp text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Community Feedback</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus is a community built for you. We are always eager to hear from you. If you have any
        feedback, please drop it{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.typeform.com/to/xTNHib3p"
        >
          here
        </a>
      </p>
    </div>
  );
}

export function Chor10Desc({ loc }) {
  return (
    <div className="text-left">
      <h2 className=" font-medium text-lg my-5">Chorus 10</h2>

      <h3 className="font-medium text-md text-purp mt-5">New to Chorus?</h3>
      <p className="mt-1 text-purp text-sm">
        View the{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.com/faq/"
        >
          FAQ page
        </a>{" "}
        for more set-up info.
      </p>
      <h3 className="font-medium text-md text-purp mt-5">Take a quick reset</h3>
      <p className="mt-1 text-purp text-sm">
        Take this class as a{" "}
        <strong className="font-semibold"> quick reset before a meeting</strong>, or as a{" "}
        <strong className="font-semibold">refresher throughout the day to clear your mind</strong>.
        ✨
      </p>

      <h3 className="font-medium text-md text-purp mt-5">10 minute class</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus 10 is a 10 minute class designed to help you quiet the mind and reset, ANYTIME you
        need.
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Background</h3>
      <p className="mt-1 text-purp text-sm">
        All our classes follow <strong className="font-semibold">The Chorus Method</strong>, and are
        based on incredible traditions dating back thousands of years and research on{" "}
        <strong className="font-semibold">improving mental and physical health</strong>.
      </p>
      <p className="mt-2 text-purp text-sm">
        If you are curious to learn more, check out our blog post:{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          onClick={() => {
            clkBlogHowMeditationTrack({ loc });
            return true;
          }}
          href="https://chorusmeditation.com/blog/how-meditation-breathing-and-the-chorus-method-works-a-deeper-dive/"
        >
          How Meditation, Breathing Exercises, and The Chorus Method Work for Stress Relief, Nervous
          System Health, and a Life of Happiness
        </a>
      </p>

      <h3 className="font-medium text-md text-purp mt-5">Community Feedback</h3>
      <p className="mt-1 text-purp text-sm">
        Chorus is a community built for you. We are always eager to hear from you. If you have any
        feedback, please drop it{" "}
        <a
          rel="noreferrer"
          className="text-cblue underline cursor-pointer"
          target="_blank"
          href="https://chorusmeditation.typeform.com/to/xTNHib3p"
        >
          here
        </a>
      </p>
    </div>
  );
}

export function SetupTips(props) {
  const m = props.m;
  return (
    <>
      <h1 className="text-left font-bold text-2xl mt-5 mb-5">Set Up Tips</h1>

      <div className="text-left flex mt-3 items-center">
        <img
          className="flex-shrink-0 mr-5 w-28 h-full object-fill"
          src={m[0].icon}
          alt="chorus meditation couch"
        />
        <div className="">
          <h3 className="text-cblue text-xl font-semibold">{m[0].title}</h3>
          <p className="mt-2 text-sm">{m[0].text_short}</p>
        </div>
      </div>

      <div className="text-left mt-5 flex items-center">
        <img
          className="flex-shrink-0 mr-5 w-28 h-full object-fill"
          src={m[1].icon}
          alt="chorus meditation couch"
        />
        <div className="">
          <h3 className="text-cblue text-xl font-semibold">{m[1].title}</h3>
          <p className="mt-2 text-sm">{m[1].text_short}</p>
        </div>
      </div>

      <div className="text-left flex mt-5 items-center">
        <img
          className="flex-shrink-0 mr-5 w-28 h-full object-fill"
          src={m[2].icon}
          alt="chorus meditation couch"
        />
        <div className="">
          <h3 className="text-cblue text-xl font-semibold">{m[2].title}</h3>
          <p className="mt-2 text-sm">{m[2].text_short}</p>
        </div>
      </div>

      <div className="text-left flex mt-5 items-center">
        <img
          className="flex-shrink-0 mr-5 w-28 h-full object-fill"
          src={m[3].icon}
          alt="chorus meditation couch"
        />
        <div className="">
          <h3 className="text-cblue text-xl font-semibold">{m[3].title}</h3>
          <p className="mt-2 text-sm">{m[3].text_short}</p>
        </div>
      </div>

      <div className="text-left flex mt-5 items-center">
        <img
          className="flex-shrink-0 mr-5 w-28 h-full object-fill"
          src={m[4].icon}
          alt="chorus meditation couch"
        />
        <div className="">
          <h3 className="text-cblue text-xl font-semibold">{m[4].title}</h3>
          <p className="mt-2 text-sm">{m[4].text_short}</p>
        </div>
      </div>
      {props.skipExpect ? (
        ""
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          className="mt-3 text-left italic block text-sm underline text-cblue text-medium"
          href="https://chorusmeditation.com/what-to-expect/"
        >
          Read more about what to expect
        </a>
      )}
    </>
  );
}

export function LiveClassDate(props) {
  const dfs = parseISO(props.startTime);
  const now = new Date();
  if (
    dfs.getFullYear() === now.getFullYear() &&
    dfs.getMonth() === now.getMonth() &&
    dfs.getDate() === now.getDate()
  ) {
    if (props.isEvent) return <p className="text-purp">{`Today ${format(dfs, "h:mmaaa")}`}</p>;
    return <strong className="font-semibold">{`Today ${format(dfs, "h:mmaaa")}`}</strong>;
  }

  if (props.isEvent) {
    return (
      <>
        <p className="text-prup">{format(dfs, "EE h:mmaaa")}</p>{" "}
        <p className="text-prup">
          <span>{format(dfs, "MMM dd, yyyy")}</span>
        </p>
      </>
    );
  }

  return (
    <>
      <strong className="font-semibold">{format(dfs, "EE h:mmaaa")}</strong>{" "}
      {props.showYear ? (
        <span>{format(dfs, "MMM dd, yyyy")}</span>
      ) : (
        <span>{format(dfs, "MMM dd")}</span>
      )}
    </>
  );
}

export function EventDate(props) {
  const startDfs = parseISO(props.startTime);
  const endDfs = parseISO(props.endTime);

  return (
    <div>
      <p className="text-prup">
        {format(startDfs, "EE")}
        <span> {format(startDfs, "MMM dd, yyyy")} </span>
      </p>
      <p className="text-prup">
        <strong className="font-semibold">to </strong>
        {format(endDfs, "EE")}
        <span className="mt-2"> {format(endDfs, "MMM dd, yyyy")}</span>
      </p>
    </div>
  );
}

export function EventListDate(props) {
  const startDfs = parseISO(props.startTime);
  const endDfs = parseISO(props.endTime);

  return (
    <div className="flex">
      <p className="text-prup">
        {format(startDfs, "EE")}
        <span> {format(startDfs, "MMM dd")}</span>
      </p>
      <strong className="mx-1"> - </strong>
      <p className="text-prup">
        {format(endDfs, "EE")}
        <span className="mt-2"> {format(endDfs, "MMM dd")}</span>
      </p>
    </div>
  );
}

export function LoginPromoHeader(props) {
  return (
    <div className="bg-white md:w-96 mx-1 md:mx-auto shadow-md">
      <div className="p-6">
        <h1 className="font-medium text-2xl mb-4 text-center">Welcome to Chorus!</h1>

        <p className="text-sm">We now have a new booking system to improve your experience.</p>
        <h2 className="text-lg font-medium mt-3">Existing or Trial Member?</h2>
        <ul className="list-disc ml-8 text-sm">
          <li>
            Great to have you back!{" "}
            <span className="underline text-cblue cursor-pointer" onClick={props.showCodeForm}>
              Click here
            </span>{" "}
            to have a unique login code sent to your email
          </li>
        </ul>
        <h2 className="text-lg font-medium mt-3">New Member?</h2>
        <ul className="list-disc ml-8 text-sm">
          <li>Please create an account below to access all of our live and on-demand classes</li>
          <li>
            <span className="font-bold text-cblue">Your first 14 days are completely free</span>!
          </li>
          <li>No commitment. Cancel at any time</li>
        </ul>
      </div>
    </div>
  );
}

export function ButtOrSpin(props) {
  if (props.spin) {
    return (
      <div className="bg-gray-300 rounded-sm text-white mt-3 inline-block p-3 cursor-not-allowed">
        <Spinner />
      </div>
    );
  }
  return (
    <div
      className={`bg-${props.clr} rounded-sm text-white font-medium mt-3 inline-block p-3 cursor-pointer ${props.cn}`}
      onClick={props.clk}
    >
      {props.txt}
    </div>
  );
}

// Shown on the on demand or account pages when a user must log in to see
// something
export function MustLogin(props) {
  return (
    <div className="mx-auto text-center mt-4">
      <h2 className="text-xl">You must sign in to view this</h2>
      <p className="underline mt-3 cursor-pointer" onClick={props.startSignIn}>
        Click here to sign in or sign up
      </p>
    </div>
  );
}

export function NewRegSocialLoginButtsContinue(props) {
  const l = props.login;
  return (
    <>
      <a
        onClick={() => {
          clkContinueGoogle();
          return true;
        }}
        href={getGoogleOauthRedirect()}
        className="block relative text-center text-xs font-medium bg-white border p-3 duration-300 rounded-3xl hover:bg-gray-100 w-full mt-3"
      >
        <Google /> Continue with Google
      </a>
      <a
        onClick={() => {
          clkContinueFB();
          return true;
        }}
        href={getFBOauthRedirect()}
        className="block relative text-center text-xs font-medium bg-white border p-3 duration-300 rounded-3xl hover:bg-gray-100 w-full mt-3"
      >
        <FB /> Continue with Facebook
      </a>
      {!props.showingEmailFields && (
        <div
          onClick={() => {
            clkContinueEmail();
            props.setShowingEmailFields(true);
          }}
          className="cursor-pointer block relative text-center text-xs font-medium bg-white border p-3 duration-300 rounded-3xl hover:bg-gray-100 w-full mt-3"
        >
          <Mail cn="h-7 w-7 absolute top-1 left-3" stroke={"#5BA7C7"} /> Continue with Email
        </div>
      )}
    </>
  );
}

export function Loading() {
  return (
    <h3 className="font-medium text-2xl mt-5 mb-5 text-center">
      Loading <Spinner />{" "}
    </h3>
  );
}

export function LoadingWhite() {
  return (
    <h3 className="font-medium text-2xl mt-5 mb-5 text-center text-white">
      Loading <Spinner />{" "}
    </h3>
  );
}

export function SecureLine(props) {
  let clr = props.clr;

  if (!clr) {
    clr = "text-white";
  }
  return (
    <div className={`inline-block mt-3  p-2 space-x-4 font-medium ${clr}`}>
      <Lock className="text-opacity-80 h-5 w-5 inline-block" />
      <span className="text-opacity-80 text-sm">
        Secured Transaction Powered by{" "}
        <a
          rel="noreferrer"
          target="_blank"
          className="text-sm font-semibold underline cursor-pointer"
          href="https://stripe.com/docs/security/stripe"
        >
          Stripe
        </a>
      </span>
    </div>
  );
}

export function scrollToTop() {
  if (window.scrollTo) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
