/*

*** BIG UGLY FILE to mostly handle attribution and a/b test related params!

* Process:
- App launches
- We read everything from query string
- Process all params in memory excluding stuff that doesnt make sense
- Run a/b tests against certain params under certain conditions 
    (eg split test cc vs nocc trial for ppl coming from facebook)
- Write the final params to local storage (as attr or filters objects)
- Remove query string params so things are not shared
    ** Only concern here is if they are coming back from Google and want to book a class

* Specific param notes:
- coup=xnzAtlk3j ::: This looks like a coupon code but for now it represents a price/coupon/package
    offer on the frontend that is then translated to a stripe price id and stripe coupon code.
    The front end doesnt currently support user selectable prices/packages so we'll use this for a
    while while we test
*/

import { apiBase } from "./Init";
import {
  osName,
  browserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  deviceType,
} from "react-device-detect";
import { navigate } from "@reach/router";

// Not currently used/needed
export function setAttrParam(key, val) {
  let attr = storeGet("attr");
  attr[key] = val;
  storeSet("attr", attr);
}

export function getAttrParam(key) {
  let attr = storeGet("attr");
  return attr[key];
}

// Here's lets just save off the raw params and the abtest func will process
export function readSaveAllQueryParams() {
  let attr = storeGet("attr");
  if (!attr) {
    attr = {
      s: "",
      m: "",
      c: "",
      adsetid: "",
      adid: "",
      land: "",
      ob: "",
      coup: "", // Billing coupon code
      trl: "nocc", // Which trial have they ended up in (cc vs nocc)
      ctype: "",
      cteacher: "",
      cdur: "",
    };
  }
  // Always set to path name
  attr.land = window.location.pathname;

  // ** Trial
  // We don't currently accept explicity trial configurations.
  // If the user lands on /fblandpuf they get a puf "pay up front" "trial"
  // aka they have to pay
  if (attr.land === "/fblandpuf") {
    console.log("Settings puf trial");
    attr.trl = "puf";
  }

  if (attr.land === "/fbretgt") {
    console.log("Settings fbretgt value");
    storeSet("fbretgt", true);
  }

  // Trial processing code
  const trl = getQueryParam("trl");
  if (trl && validTrial(trl)) {
    attr.trl = trl;
  }

  const s = getQueryParam("utm_source");
  if (s) {
    attr.s = s;
  }
  const m = getQueryParam("utm_medium");
  if (m) {
    attr.m = m;
  }
  let c = getQueryParam("utm_campaign");
  if (c) {
    attr.c = c;
  } else {
    c = getQueryParam("campaignid"); // Fb
    if (c) {
      attr.c = c;
    }
  }
  const adsetid = getQueryParam("adsetid");
  if (adsetid) {
    attr.adsetid = adsetid;
  }
  const adid = getQueryParam("adid");
  if (adid) {
    attr.adid = adid;
  }

  // Right now none of the following are supported because:
  // - Landing pages set land param on mount
  // - ob is always var c no matter what
  // - SEE THE ABOVE DEFAULTS FOR MORE INFO

  // const land = getQueryParam("land");
  // if (land) {
  //   attr.land = land;
  // }

  const ob = getQueryParam("ob");
  if (ob && validOB(ob)) {
    attr.ob = ob;
  }

  const coup = getQueryParam("coup");
  if (coup) {
    attr.coup = coup;
  }

  storeSet("attr", attr);

  // Set obshow=true when testing the onboarding module
  // This (like all other params) will get cleared on logout()!
  if (getQueryParam("obshow") === "true") {
    storeSet("obshow", true);
  }

  if (getQueryParam("obshow") === "false") {
    storeSet("obshow", false);
  }

  // adding this for quickly testing the free trial welcome dialog for new user
  if (getQueryParam("showwelcomedialog") === "true") {
    storeSet("showwelcomedialog", true);
  }

  if (getQueryParam("showwelcomedialog") === "false") {
    storeSet("showwelcomedialog", false);
  }

  // in case if the book query param is present
  const book = getQueryParam("book");
  if (book) {
    storeSet("book", "true");
  }
}

export function runAB() {
  let attr = storeGet("attr");

  // As of Sept 9, 2021 we want to split test ALL new users for onboarding b vs c
  if (!attr.ob) {
    // ** As of Wed Dec 29, 2021 we are settling on var_b for all per ads Glen
    // (easier to write good ads copy)
    attr.ob = "var_b";
    // Old::
    // Only add this if it's not already set otherwise analytics and other things will get confusing
    // if (abIsA()) {
    //   attr.ob = "var_b";
    // } else {
    //   attr.ob = "var_c";
    // }
  }

  // TODO can probably remove this since FB traffic no longer sends to register
  if (attr.s === "FB") {
    // Oct 2021, all our ad traffic is still going to /register UG so let's redirect the user
    // to /fbland
    if (attr.land === "/register") {
      navigate("/fbland");
      attr.land = "/fbland";
      console.log(`A/B set land: ${attr.land}`);
    }
  }

  // Clear coupon if not valid
  // not used
  // if (attr.coup && !validCoupon(attr.coup)) {
  //   attr.coup = "";
  // }

  // Clear trial if not valid
  // if (attr.trl && !validTrial(attr.trl)) {
  //   attr.trl = "";
  // }

  // Clear ob
  // if (attr.land && !validLand(attr.land)) {
  //   attr.land = "";
  // }

  // Right now we are only running tests against facebook traffic
  // so we don't annoy friends and family

  // if (attr.s === "FB") {
  // We don't have a pre-configured trial setting, a/b test!
  // As of Tues July 26, we are no longer a/b test stripe trial until further notice
  // if (attr.trl === "") {
  //   if (abIsA()) {
  //     // Original variation of NO cc
  //     attr.trl = "nocc";
  //   } else {
  //     attr.trl = "strpcc";
  //   }
  // }
  // This is no longer being used for FB
  // if (attr.land === "") {
  //   if (abIsA()) {
  //     // Feel better now 2 is default, A variation
  //     // NOTE this is only if coming from FB. Otherwise they get a generic landing page
  //     attr.land = "fbn2";
  //   } else {
  //     attr.land = "mn1"; // Feel better now
  //   }
  // }
  // }
  storeSet("attr", attr);
}

export function validCoupon(coup) {
  if (coup === "zDu6nUOB" || coup === "3mo" || coup === "6mo") {
    return true;
  }
  return false;
}

export function validTrial(trl) {
  if (trl === "strpcc" || trl === "nocc" || trl === "monthq") {
    return true;
  }
  return false;
}

export function validOB(ob) {
  if (ob === "var_b" || ob === "var_c") {
    return true;
  }
  return false;
}

export function validLand(land) {
  // Either ct2 (claim trial 2) or fbn2 (feel better now 2) (med now 1)
  if (land === "ct2" || land === "fbn2" || land === "mn1") {
    return true;
  }
  return false;
}

// zDu6nUOB
export function getCoupon() {
  if (storeGet("attr").coup) {
    return storeGet("attr").coup;
  }
  return "";
}

export function clearOdFilters() {
  storeSet("odFilters", {
    classTypes: [],
    teachers: [],
    lengths: [],
    taken: [true, false],
  });
}

export function clearLiveFilters() {
  storeSet("liveFilters", {
    classTypes: [],
    teachers: [],
    lengths: [],
  });
}

export function loadFiltersFromQuery() {
  // We assume filters has something already from App.js which first clears filters
  // on app load
  let liveFilters = storeGet("liveFilters") || {};
  let odFilters = storeGet("odFilters") || {};

  const ctype = getQueryParam("ctype", true);
  if (ctype) {
    let arr = ctype.split(",") || [];
    // TODO validate class types from a central location
    liveFilters.classTypes = arr;

    odFilters.classTypes = arr;
  }

  const cteacher = getQueryParam("cteacher", true);
  if (cteacher) {
    let arr = cteacher.split(",") || [];
    // TODO validate teacher ids from a central location
    liveFilters.teachers = arr;
    odFilters.teachers = arr;
  }

  const cdur = getQueryParam("cdur", true);
  if (cdur) {
    try {
      let arr = cdur.split(",") || [];
      for (let a in arr) {
        arr[a] = parseInt(arr[a], 10);
      }
      // TODO validate durations from a central location
      liveFilters.lengths = arr || [];
      odFilters.lengths = arr || [];
    } catch (e) {
      console.log("exception in cdur filter", e);
    }
  }

  storeSet("liveFilters", liveFilters);
  storeSet("odFilters", odFilters);
}

// Only attach analytics related events NOT coupons or products as those only affect checkout/purchase
export function attachAttr() {
  const attr = storeGet("attr");
  if (!attr) {
    return "";
  }
  // TODO store for the session
  // import { osName, browserVersion, browserName, mobileVendor, mobileModel, deviceType } from "react-device-detect";
  return (
    `utm_source=${attr.s}&utm_medium=${attr.m}&utm_campaign=${attr.c}&` +
    `adsetid=${attr.adsetid}&adid=${attr.adid}&land=${attr.land}&ob=${attr.ob}&trl=${attr.trl}&` +
    `os=${osName}&bv=${browserVersion}&bn=${browserName}&mv=${mobileVendor}&mm=${mobileModel}&dt=${deviceType}&` +
    `did=${storeGet("devid")}&app=wapp_2021&coup=${attr.coup}` // Usually app is sent as a header unless we're redirecting like in the case of oauth
  );
}

export function getQueryParam(name, replace = false) {
  let url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|/|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  if (replace) results[2] = results[2].replace(/\\/g, "");
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getGoogleOauthRedirect() {
  return `${apiBase()}/users/oauth/start/google?redirect=${oauthRedirect()}&${attachAttr()}`;
}

export function getFBOauthRedirect() {
  return `${apiBase()}/users/oauth/start/facebook?redirect=${oauthRedirect()}&${attachAttr()}`;
}

// Get this app's url to redirect back to
function oauthRedirect() {
  const loginRedirect = getLoginRedirect();
  if (loginRedirect?.redir) {
    let r = loginRedirect.redir;

    if (loginRedirect.params) {
      const p = loginRedirect.params;
      r += "?";
      for (const k of Object.keys(loginRedirect.params)) {
        r += `${k}=${p[k]}&`;
      }
    }
    return `${window.location.origin}${r}`;
  }
  return `${window.location.origin}/`; // Send them home if not
}

// From now on we only clear the auth_token if that exists in the query so:
// - Segment can auto-track incoming utm and other params
// - Things like filters can persist
// - It's easier to share links with friends (coup and other params stick around)
// ** However our client side nav quickly erases query string params currently
export async function clearQuery() {
  if (!window.location.search) {
    // console.log("No query params!");
    return;
  }

  let params = new URLSearchParams(window.location.search);
  if (!params.get("auth_token")) {
    // console.log(`Params but no auth token ${params.toString()}`);
    return;
  }

  params.delete("auth_token");
  window.history.replaceState(null, "", "?" + params + window.location.hash);
}

// As of Aug 2021 we want to keep the device id across sessions so just
// clear specific things
export function storeClearAll() {
  storeDel("attr");
  storeDel("obshow");
  storeDel("user");
  storeDel("odFilters");
  storeDel("liveFilters");
}

export function storeSet(key, val) {
  localStorage.setItem(key, JSON.stringify(val));
}

export function storeGet(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function storeGetAndDel(key) {
  const keyValue = localStorage.getItem(key);
  storeDel(key);
  return JSON.parse(keyValue);
}

export function storeDel(key) {
  localStorage.removeItem(key);
}

// Returns TRUE for A, FALSE for B
export function abIsA() {
  if (getRandomInt(2) === 0) {
    return true;
  }
  return false;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function saveLoginRedirect(redir, params) {
  // Right now the only params we support are book = true
  if (params && params.book === true) {
    setBookClass();
  }
  storeSet("loginRedirect", { redir, params });
}

export function getLoginRedirect() {
  return storeGet("loginRedirect");
}

export function getAndClearLoginRedirect() {
  const r = storeGet("loginRedirect");
  if (r) {
    storeDel("loginRedirect");
  }
  return r;
}

// Class page is loading, check if they should auto book
// then remove it from local storage
export function shouldBookClass() {
  if (storeGet("book") === "true") {
    storeDel("book");
    return true;
  }
  return false;
}

// User tried to book a class but are not logged in.
// Store this intention and book right after they finish logging in
export function setBookClass() {
  storeSet("book", "true");
}
