import React from "react";
import InformationCircle from "common/icons/InformationCircle";

// todo: move it to the separate component file
const ShowFreeTierButton = ({ activateSub, spin }) => {
  return (
    <>
      <p
        className={`mt-4 text-charcoal hover:text-coral cursor-pointer ${
          spin ? "pointer-events-none text-gray-200" : ""
        }`}
        onClick={() => activateSub("free")}
      >
        Downgrade to Free Tier
      </p>
      <div className="flex items-center justify-center">
        <p className="text-purp text-sm mr-1">$10 per class</p>

        <div className={`has-tooltip`}>
          <div className="tooltip rounded shadow-lg text-sm bg-white text-center w-36 p-2 bg-black text-purp font-medium ml-5 -mt-10">
            Cancels current Subscription
          </div>
          <InformationCircle cn="h-5 w-5 cursor-pointer" stroke={"#B5AEC1"} />
        </div>
      </div>
    </>
  );
};

export default ShowFreeTierButton;
