import { downloadAppClkTrack } from "common/Ana";
import AppStore from "../../common/icons/AppStore";

export default function DownloadApp() {
  const iosLink = "https://apps.apple.com/us/app/chorus-meditation/id1557561444#?platform=iphone";

  const clk = () => {
    downloadAppClkTrack();
    // window.open(iosLink, "_blank").focus();
    return true;
  };

  return (
    <div className="hidden sm:flex mt-28 bg-obc2 rounded-xlarge py-8 w-full mb-16 mt-6 ">
      <div className="w-5/12 text-center">
        <img
          className="-mt-24 -mb-28  w-72 inline-block"
          src="https://storage.googleapis.com/chorus-public/img/etc/clay2.png"
          alt=""
        />
      </div>
      <div className="w-7/12 text-left mt-8">
        <h2 className="text-3xl text-charcoal font-semibold">Download our App</h2>
        <p className="text-sm mt-2 text-gray-600">
          Download our app, it's available in the App Store.
        </p>
        <a onClick={clk} rel="noreferrer" target="_blank" href={iosLink}>
          <AppStore cn={"w-32"} />
        </a>
      </div>
    </div>
  );
}
