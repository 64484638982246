import React, { createContext } from "react";
import useComments, { getCommentsInitialState } from "../hooks/Comments";

export const CommentsContext = createContext(getCommentsInitialState());

const CommentsProvider = ({ children }) => {
  const {
    subscribeToComments,
    addComment,
    deleteComment,
    deleteReply,
    addReply,
    addCommentReaction,
    removeCommentReaction,
    addReplyReaction,
    removeReplyReaction,
    getUsers,
    comments,
    users,
    commentsLoading,
  } = useComments();

  return (
    <CommentsContext.Provider
      value={{
        getUsers,
        subscribeToComments,
        addComment,
        deleteComment,
        deleteReply,
        addReply,
        addCommentReaction,
        removeCommentReaction,
        addReplyReaction,
        removeReplyReaction,
        comments,
        users,
        commentsLoading,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export default CommentsProvider;
