import { navigate } from "@reach/router";
import BoldMusic from "common/icons/BoldMusic";
import Squig from "common/icons/Squig";
import sliderData from "common/constants/fblanding/LandingPageSlider";
import { Carousel } from "react-responsive-carousel";
import { useState } from "react";
import PianoLines from "common/icons/PianoLines";
import BoldPlayButton from "common/icons/BoldPlayButton";
import { clkNYTTrack, clkTryForYourselfTrack } from "common/Ana";

const StackedCards = () => {
  return (
    <>
      <div className="min-h-24 w-full lg:w-full sm:w-48 ">
        <BoldMusic />

        <p className="font-bold mt-1 w-40">Breathe to the beat of music</p>
        <p className="text-xs text-gray-500 mt-2">
          From hip hop to indie to pop, our instructor-curated playlists will keep you motivated and
          engaged
        </p>
        <div className="bg-white pt-2 w-full mx-auto sm:px-3 mt-3 block md:hidden lg:block">
          <div className="w-full border border-obeige h-px"></div>
        </div>
      </div>
      <div className="min-h-24 w-full lg:w-full sm:w-48 mt-4 md:mt-0 lg:mt-4">
        <PianoLines />
        <p className="font-bold mt-1 w-40">To help you sleep, energize, and unwind</p>
        <p className="text-xs text-gray-500 mt-2">100+ classes</p>
        <p className="text-xs text-gray-500 mt-2">Live and on-demand</p>
        <div className="bg-white pt-2 w-full mx-auto sm:px-3 mt-3 block md:hidden lg:block">
          <div className="w-full border border-obeige h-px"></div>
        </div>
      </div>
      <div className="min-h-24 w-full lg:w-full sm:w-48 mt-4 md:mt-0 lg:mt-4">
        <BoldPlayButton />

        <p className="font-bold mt-1 w-48">Expert-led by world- class teachers</p>
        <p className="text-xs text-gray-500 mt-2">As short as 10 minutes</p>
      </div>
    </>
  );
};

export default function TopWhitePanel({ heading, trialExpire, btnText, linkId }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentData = sliderData[currentIndex];
  return (
    <>
      <div className="bg-white rounded-3xl p-3 sm:py-14 lg:py-16 sm:pl-6 lg:pl-8 sm:pr-8 sm:h-194 lg:h-160 relative">
        <div className="md:flex relative sm:h-112 lg:h-128">
          {/* dynamic classes */}
          <div className="object-cover -mt-2 h-14 w-12 rounded-full hidden"></div>
          {/* left col */}
          <div className="md:w-5/12 lg:w-4/12 mt-6 xl:mt-12 lg:mt-8 md:mt-12 sm:mt-6 text-left pl-7 sm:pl-2">
            {!!trialExpire && (
              <a
                onClick={() => {
                  clkTryForYourselfTrack();
                  return true;
                }}
                className="inline-block bg-lcoral bg-opacity-20 py-2 px-4 rounded-3xl font-semibold text-base text-coral my-4 sm:-mt-2 sm:mb-8 lg:mb-6 lg:mt-2 xl:my-6"
                href="#join"
              >
                Your free trial has now expired.
              </a>
            )}
            <h1
              className="text-3xl sm:text-4xl text-charcoal font-bold max-w-76"
              style={{ lineHeight: 1.25 }}
            >
              {heading}
            </h1>
            {trialExpire ? (
              <div className="sm:h-5"></div>
            ) : (
              <p className="text-purp text-lg mt-6">
                Try Chorus to help you find{" "}
                <span className="font-semibold"> calm, clearer thinking, and more positivity </span>{" "}
                in your days.
              </p>
            )}
            <a
              onClick={() => {
                clkTryForYourselfTrack();
                return true;
              }}
              href={linkId}
            >
              <button className="mt-6 sm:mt-8 bg-coral py-2 w-48 rounded-3xl cursor-pointer">
                <p className="text-lg font-semibold text-white">{btnText}</p>
              </button>
            </a>
            <div className="block sm:hidden text-center mt-6 mb-2">
              <Squig />
            </div>
          </div>
          {/* Middle large image */}
          <div className="md:w-7/12 lg:w-5/12 relative sm:pl-8 lg:pl-4 mr-1 mt-6 sm:mt-1 lg:mt-6 xl:mt-1 h-full">
            <Carousel
              autoPlay={true}
              key={"carousel"}
              infiniteLoop={true}
              className="w-full testimonials fbland h-full rounded-3xl"
              showThumbs={false}
              showStatus={false}
              onChange={(index) => setCurrentIndex(index)}
            >
              {sliderData.map((data, i) => (
                <img
                  className="object-cover w-full rounded-3xl "
                  style={{ height: "calc(100% - 1.5rem)" }}
                  src={data.image}
                  alt={data.name}
                  key={`imag-${i}`}
                />
              ))}
            </Carousel>
            <div className="w-40 h-17 bg-white bg-opacity-90 rounded-full flex items-center absolute bottom-0 left-4 sm:left-0 transform sm:-translate-x-10 -translate-y-14 lg:-translate-y-40 xl:-translate-y-24">
              <div className="h-13 w-13 bg-purpc1 text-white rounded-full items-center ml-2">
                <img
                  className={currentData.imageClasses}
                  src={currentData.image1}
                  alt="chorus meditation instructor ellie"
                />
              </div>
              <div>
                <div className="text-xs font-semibold">{currentData.name}</div>
                <div className="mt-1 px-4 text-xs text-corange font-semibold">★ 5.0</div>
              </div>
            </div>
            <div className="invisible sm:visible text-center absolute bottom-0 left-0 transform -translate-x-20 translate-y-8">
              <Squig />
            </div>
          </div>
          <div className="md:w-3/12 text-left mt-6 sm:-mt-8 block md:hidden lg:block">
            <div className="h-full my-3 pl-6 w-52 ml-7 sm:ml-0 sm:w-full">
              <StackedCards />
            </div>
          </div>
        </div>
        <div className="hidden md:block lg:hidden">
          <div className="mt-12">
            <div className="w-full border border-gray-100 h-px"></div>
            <div className="flex mt-4 justify-between items-center px-2 text-left">
              <StackedCards />
            </div>
          </div>
        </div>
      </div>
      <div className="-mt-5 rounded-b-3xl bg-lbeigec1 px-3 pt-20 pb-14 ">
        <div className="mx-2 sm:mx-auto sm:max-w-156">
          <div className="flex">
            <p
              style={{ color: "#DB995D", fontSize: "60px" }}
              className="font-bold -mt-4 sm:-mt-2 mr-1 sm:mr-0"
            >
              ”
            </p>
            <p className="text-3xl sm:text-5xl font-bold text-purp text-left">
              My body feels amazing, slow and quiet
              <span
                style={{ color: "#DB995D", fontSize: "60px", fontFamily: "Poppins" }}
                className="font-bold mt-2 sm:mt-0"
              >
                ”
              </span>
            </p>
          </div>
          <a
            onClick={() => {
              clkNYTTrack();
              return true;
            }}
            target="_blank"
            rel="noreferrer"
            className="cursor-pointer"
            href="https://www.nytimes.com/2019/11/15/business/the-work-diary-of-an-audio-erotica-ceo.html"
          >
            <img
              className="sm:ml-8 max-w-64 mt-8 mx-auto"
              src="https://storage.googleapis.com/chorus-public/img/land/nyTimes.png"
            />
          </a>
        </div>
      </div>
    </>
  );
}
