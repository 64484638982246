import { CardElement } from "@stripe/react-stripe-js";

export default function CardOrLast4(props) {
  const a = props.actions;
  const last4OrNull = a.hasPM();
  const free = props.plan.id === "free";
  if (last4OrNull) {
    return (
      <>
        <p className="text-lg text-white">
          We'll use your default card ending in ({last4OrNull}){free ? " for future purchases" : ""}
        </p>
      </>
    );
  }
  return (
    <>
      {props.title ? (
        <p className="text-lg text-white mb-8">{props.title}</p>
      ) : free ? (
        <p className="text-lg text-white mb-8">
          Please add a default card for easy class bookings. You can remove it at any time.
        </p>
      ) : (
        <p className="text-lg text-white mb-8">Please enter your credit card below.</p>
      )}

      <CardElement
        className="bg-white p-2 py-4 sm:p-5 rounded-lg"
        options={{
          style: {
            base: {
              fontSize: "18px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
    </>
  );
}
