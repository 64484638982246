import React, { useState, useEffect } from "react";
import { fromUnixTime, parseISO } from "date-fns";

import BillingActions from "views/Billing/Actions";
import { useStripe, useElements } from "@stripe/react-stripe-js";
// dialogs
import DowngradeWarning from "components/Dialogs/DowngradeWarning";
import Deactivated from "components/Dialogs/Deactivated";
import KeepSubscription from "components/Dialogs/KeepSubscription";
import SubscriptionUpgrade from "components/Dialogs/SubscriptionUpgrade";
import PlanUpdated from "components/Dialogs/PlanUpdated";

import CreateRadioButtons from "./CreateRadioButtons";
import ShowStripeInputFields from "./ShowStripeInputFields";
import ShowFreeTierButton from "./ShowFreeTierButton";
import StripeSecure from "components/StripeSecure/index";
import { billPlanPickedTrack, clkKeepSubscriptionTrack } from "common/Ana";

export default function Plans({
  plans,
  showCardInputField,
  showCard,
  setShowCard,
  billData,
  setUserData,
  user,
  setBillData,
  savedCardHidden,
  showLast4,
  title,
  btnText,
  trialExpire,
}) {
  // if the user is on free tier assign 6mo as default
  let [plan, setPlan] = useState(user.status2 === "free" ? "6mo" : user.member_plan || "6mo");
  let [spin, setSpin] = useState(false);
  let [rdDisabled, setRdDisabled] = useState(false);
  const [callActivateSub, setCallActivateSub] = useState(false);

  // user is downgrading to free tier - showWarning
  // user has canceled the membership - showDeactivationMsg
  // user click on keep subscription button - showKeepSubscriptionMsg
  // user has updated the membership - showSubscriptionUpgradeMsg
  const [status, setStatus] = useState("");

  useEffect(() => {
    // dont show dialog if the page is trial expired and user has selected free tier
    if (trialExpire && callActivateSub && plan === "free") {
      activateSub("free");
    }
  }, [callActivateSub, plan]);

  const callSetUserData = async () => {
    if (trialExpire && plan !== "free") setStatus("planUpdated");
    else await setUserData();
  };

  const elements = useElements();
  const stripe = useStripe();
  const actions = new BillingActions(
    stripe,
    elements,
    user,
    setBillData,
    billData,
    callSetUserData
  );
  const a = actions;

  useEffect(() => {
    if (setShowCard && !showCardInputField) {
      setShowCard(showCardInputField);
    }
  }, [showCardInputField]);

  const sp = (val) => {
    billPlanPickedTrack(val);
    setPlan(val);
  };

  const setSpinAndDisabled = (val) => {
    setSpin(val);
    setRdDisabled(val);
  };

  const successCB = (statusValue) => {
    if (!trialExpire) setStatus(statusValue);
  };

  const activateSub = async (selectedPlan, cardAttached) => {
    if (trialExpire && selectedPlan === "free" && plan !== "free") {
      setPlan("free");
      setCallActivateSub(true);
    } else {
      if (user.member_plan && user.member_plan !== selectedPlan && selectedPlan === "free") {
        // if user is downgrading his plan to free tier
        setStatus("warning");
      } else {
        let statusValue = "";
        if (user.status2 === "sub" && selectedPlan !== "free") {
          statusValue = "subscriptionUpgradeMsg";
        }
        if ((user.status2 === "trial" || user.status2 === "free") && selectedPlan !== "free") {
          statusValue = "keepSubscriptionMsg";
        }
        // if card is attached call action function which checks the payment input too
        cardAttached
          ? await a.activateNewWPlan(
              (val) => setSpinAndDisabled(val),
              selectedPlan,
              () => successCB(statusValue)
            )
          : await a.activateNewWPlanDCC(
              (val) => setSpinAndDisabled(val),
              selectedPlan,
              () => successCB(statusValue)
            );
      }
    }
  };

  const freeTierActivated = (value) => {
    setSpin(value);
    setRdDisabled(value);
    if (!value) {
      setStatus("deactivationMsg");
    }
  };

  // cancel subscription
  const onCancel = async () => {
    setStatus("");
    a.activateNewWPlanDCC(freeTierActivated, "free");
  };

  const emptyPlanActivated = (value) => {
    setSpin(value);
    setRdDisabled(value);
  };

  const onEmptyPlanSucces = () => {
    if (!trialExpire) {
      // only assign 1mo40 if they don't already have the member plan
      setPlan(user.member_plan && user.member_plan !== "free" ? user.member_plan : "1mo40");
      setStatus("keepSubscriptionMsg");
    }
  };

  const onReactivateSubscription = async () => {
    a.activateNewWPlanDCC(emptyPlanActivated, "", () => onEmptyPlanSucces());
  };

  const handleOnClose = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setStatus("");
  };

  const currentPeriodEnd = billData?.sub?.current_period_end
    ? fromUnixTime(billData?.sub?.current_period_end)
    : user.trial_ends
    ? parseISO(user.trial_ends)
    : "";

  const last4OrNull = actions.hasPM();

  return (
    <div
      className={`bg-obc2 bg-opacity-20 py-8 ${
        savedCardHidden
          ? "rounded-xlarge sm:rounded-t-none sm:rounded-b-xlarge mt-4 sm:mt-0"
          : "mt-10 sm:mt-16 rounded-xlarge"
      } text-center shadow-md ${spin ? "opacity-70 pointer-events-none" : ""}`}
      id="plans"
    >
      {/* DIALOGS */}

      {/* downgrading to free tier */}
      {status === "warning" && (
        <DowngradeWarning
          onCancel={onCancel}
          onClose={handleOnClose}
          key="warning"
          onKeepSubscriptionClick={() => {
            setStatus("keepSubscriptionMsg");
            clkKeepSubscriptionTrack();
          }}
        />
      )}

      {/* user has cancelled the subscription */}
      {status === "deactivationMsg" && (
        <Deactivated
          key="deactivated"
          spin={spin}
          memberPlanCanceled={user?.member_plan_canceled}
          onReactivateSubscription={onReactivateSubscription}
          onClose={handleOnClose}
          currentPeriodEnd={currentPeriodEnd}
        />
      )}

      {/* user has cancelled the subscription */}
      {status === "planUpdated" && (
        <PlanUpdated key="planUpdated" setUserData={() => setUserData()} />
      )}

      {/* user click on kep subscription */}
      {status === "keepSubscriptionMsg" && (
        <KeepSubscription key="keepSubscription" onClose={handleOnClose} />
      )}

      {/* user has upgraded the plan */}
      {status === "subscriptionUpgradeMsg" && (
        <SubscriptionUpgrade key="subscriptionUpgrade" onClose={handleOnClose} />
      )}

      {/* Plans section */}
      <h2 className="font-bold text-2xl sm:text-4xl text-charcoal mt-2" id="join">
        {user.status === "active" && user.status2 === "trial"
          ? "Join the Community"
          : title || "Update Plan"}
      </h2>

      {user.status2 === "free" && !user?.member_plan_canceled && (
        <>
          <p className="text-charcoal text-sm mt-5">
            You are currently on our free tier, where you pay $10 per class
          </p>
          <p className="text-charcoal text-sm mt-1 -mb-2">
            Upgrade now for unlimited access to all classes, for{" "}
            <span className="font-semibold">as little as $10 per mont</span>
          </p>
        </>
      )}

      {user?.member_plan_canceled ? (
        <>
          <p className="text-charcoal text-sm mt-4 -mb-2">
            Click below to reactivate your subscription
          </p>

          <button
            onClick={onReactivateSubscription}
            className="focus:outline-none sm:w-84 mt-10 mx-auto bg-coral py-3 px-5 rounded-3xl text-xl font-bold text-white hover:bg-gray-400 mx-auto"
          >
            Reactivate Subscription
          </button>
        </>
      ) : (
        <CreateRadioButtons
          plan={plan}
          sp={sp}
          plans={plans}
          showCardInputField={showCardInputField}
          showCard={showCard}
          user={user}
          last4OrNull={last4OrNull}
          showLast4={showLast4}
          activateSub={activateSub}
          btnText={btnText}
          spin={spin}
          setShowCard={setShowCard}
          rdDisabled={rdDisabled}
        />
      )}

      {/* show inline card if there is no attached cards and user has no cancelled plan */}
      {!!(showCard && user.member_plan !== plan && !user?.member_plan_canceled) && (
        <div>
          <ShowStripeInputFields
            activateSub={activateSub}
            actions={actions}
            rdDisabled={rdDisabled}
            plan={plan}
            user={user}
          />

          <StripeSecure lockClass="w-3 my-2 mr-1" />

          {/* hide in case of free tier */}
          {user.member_plan === "free" ? (
            <></>
          ) : (
            <ShowFreeTierButton activateSub={activateSub} spin={spin} />
          )}
        </div>
      )}
    </div>
  );
}
