import { logout } from "../../common/Init";

// Must pass email
export default function LogoutOption(props) {
  return (
    <div className="md:max-w-lg mx-1 md:mx-auto mt-4 text-left text-xs text-gray-600">
      You are logged in as <span className="font-semibold">{props.email}</span>. Click here to{" "}
      <span onClick={logout} className="underline text-cblue cursor-pointer">
        logout.
      </span>
    </div>
  );
}
