import React, { useEffect, useState } from "react";
import BillingActions from "../../views/Billing/Actions";
import { getBillingData } from "../../apiClient/requests";
import apiClient from "../../apiClient";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import CircleChecked from "../../common/icons/CircleChecked";
import { RadioGroup } from "@headlessui/react";
import StripeSecure from "../../components/StripeSecure";
import JoinButt from "./JoinButt";
import CardOrLast4 from "./CardOrLast4";
import { LoadingWhite } from "../../common/Ponents";
import { getAttrParam } from "../../common/Params";
import { navigate } from "@reach/router";
import { handleError2 } from "apiClient/errors";

export default function PUFBilling(props) {
  const elements = useElements();
  const stripe = useStripe();

  let coup = "6mo";
  let [plan, setPlan] = useState(coup);
  let [billData, setBillData] = useState({});
  let [spin, setSpin] = useState(false);
  let [loading, setLoading] = useState(true);
  let [rdDisabled, setRdDisabled] = useState(false);

  const actions = new BillingActions(
    stripe,
    elements,
    props.user,
    setBillData,
    billData,
    props.setUserData
  );
  const a = actions;

  const getBillData = async () => {
    try {
      const req = getBillingData();
      const response = await apiClient(req);
      const data = response.data || {};
      setBillData(data);
    } catch (err) {
      handleError2(err);
    }
  };

  useEffect(() => {
    getBillData();
  }, []);

  // Stop loading once we have the plans
  useEffect(() => {
    if (billData?.plans) {
      setLoading(false);
    }
  }, [billData]);

  const sp = (val) => {
    setPlan(val);
  };

  const getPlan = (id) => {
    const plans = billData?.plans || [];
    for (const p of plans) {
      if (p.id === id) {
        return p;
      }
    }
  };

  const setSpinAndDisabled = (val) => {
    setSpin(val);
    setRdDisabled(val);
  };

  const activateSub = async () => {
    await a.activateNewWPlan(setSpinAndDisabled, plan);
    navigate("/welcome");
  };

  const CircleEmpty = (props) => {
    return (
      <div {...props}>
        <div className="rounded-full w-full h-full border-2 border-gray box-border"></div>
      </div>
    );
  };

  // Not sure we want to do this anymore as we show a loading thing until plans are ready
  const plans = billData?.plans || [];

  if (loading) {
    return (
      <div className="bg-obc2 bg-opacity-20 py-8 rounded-xlarge text-center shadow-md">
        <LoadingWhite />
      </div>
    );
  }

  return (
    <div className="bg-obc2 bg-opacity-20 py-8 rounded-xlarge text-center shadow-md">
      <h2 className="font-bold text-2xl text-charcoal" id="join">
        Join the Community
      </h2>

      <div className="px-1 md:px-10">
        <RadioGroup value={plan} onChange={sp} disabled={rdDisabled}>
          <div className="sm:flex  px-4 mt-4 sm:mt-12 sm:justify-center sm:space-x-20">
            {plans
              ?.filter((p) => p.puf_enabled)
              .map((po) => {
                if (props.user.status2 === "free" && po.id === "free") {
                  // They are already on a free plan, dont show them the free option
                  return "";
                }
                return (
                  <RadioGroup.Option
                    key={po.id}
                    value={po.id}
                    className={({ checked, active, disabled }) =>
                      `rounded-3xl bg-white p-3 px-12 sm:px-3 w-full sm:h-42 sm:w-72 mt-3 sm:mt-0 ${
                        checked ? " ring-4 ring-coral outline-none" : ""
                      } ${active ? " ring-4 ring-coral outline-none" : ""} ${
                        disabled
                          ? " bg-gray-200 text-gray-400 cursor-not-allowed text-opacity-25"
                          : "cursor-pointer "
                      }`
                    }
                  >
                    {({ checked, disabled }) => (
                      <div className="relative">
                        <div className="">
                          {checked ? (
                            <CircleChecked
                              stroke="#fff"
                              fill="#EB5757"
                              strokeWidth={1.75}
                              className="w-8 h-8 -mt-1 -mb-1 mx-auto text-coral"
                            />
                          ) : (
                            <CircleEmpty
                              className={`w-6 h-6 mx-auto ${
                                disabled ? "bg-gray-200" : ""
                              } box-border p-0.5`}
                            />
                          )}

                          <div className="text-center">
                            <RadioGroup.Label
                              as="div"
                              className={`mt-3  text-base  ${checked ? "" : ""}`}
                            >
                              <p className="line-through text-gray-600 px-3">{po.strike_text}</p>
                              <p className="font-bold">{po.title}</p>
                              <p className="text-xs text-gray-600">{po.sub_text}</p>
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="div"
                              className={`mt-3 text-sm  ${checked ? "" : ""}`}
                            >
                              {po.desc}
                            </RadioGroup.Description>
                          </div>
                        </div>
                        {po.id === "6mo" ? (
                          <div className="absolute -bottom-7 sm:-bottom-20 left-1/2 transform -translate-x-16 rounded-lg bg-cblue text-white text-sm py-1 w-32">
                            Best Seller
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </RadioGroup.Option>
                );
              })}
          </div>
        </RadioGroup>
        <p className="mt-8">
          Try Chorus risk-free for a month. If it's not for you, we'll give you a full refund.
        </p>
        {plan !== "none" ? (
          <div className="rounded-3xl bg-coral mt-6 sm:mx-4 py-8 px-4 sm:px-16">
            <div>
              <CardOrLast4 actions={actions} plan={getPlan(plan)} user={props.user} />
            </div>
            <div className="w-full">
              <JoinButt
                title={getPlan(plan).btn_text}
                // txt={getPlan(plan).sm_bill_text}
                clr=""
                disabled={rdDisabled}
                clk={activateSub}
              />
              <p className="text-xs mt-3">{getPlan(plan).sm_bill_text}</p>
              <p className="text-xs mt-1 italic">Cancel anytime. Money-back guarantee.</p>
            </div>
          </div>
        ) : (
          ""
        )}
        {plan !== "none" ? (
          <div className="mt-4">
            <StripeSecure lockClass="w-4 mr-2" secureClass="text-base" powerClass="w-32" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
