import PoweredByStripe from "../../common/icons/PoweredByStripe";
import SecureLock from "../../common/icons/SecureLock";

export default function StripeSecure({ lockClass, secureClass, powerClass }) {
  return (
    <div
      className={`${secureClass || "text-xs"} mt-8 font-semibold flex justify-center items-center`}
    >
      <SecureLock classNames={lockClass} /> Pay Securely.
      <PoweredByStripe classNames={powerClass} />
    </div>
  );
}
