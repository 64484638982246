import React, { useEffect } from "react";
import { navODB } from "common/Halps";

const Fbretgt = () => {
  useEffect(() => {
    navODB();
  }, []);

  return <></>;
};

export default Fbretgt;
