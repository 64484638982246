export function Lightning(props) {
  return (
    <svg
      width="8"
      height="10"
      className={props.cn}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 0.5V4H8L3.5 9.5V6H0L4.5 0.5Z" fill="#F5F4F2" />
    </svg>
  );
}

export default Lightning;
