import React, { useState } from "react";
import apiClient from "../../apiClient";
import { addMemberToKlaviyoListReq } from "../../apiClient/requests";
import { NewsletterSupport } from "components/NewsletterSupport/new";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) return;

    try {
      setLoading(true);
      const req = addMemberToKlaviyoListReq({
        email,
      });
      await apiClient(req);
      setEmail("");
      setMsg("Thanks for subscribing");
      setLoading(false);
    } catch (err) {
      setMsg("Invalid email");
      setLoading(false);
    }
  };

  const setEmailValue = (value) => {
    setEmail(value);
    if (msg) setMsg("");
  };

  return (
    <>
      <div
        id="footer-container"
        className="sm:max-w-screen-md md:max-w-screen-lg xl:max-w-screen-xl px-2 md:mx-auto w-full"
      >
        <NewsletterSupport />
        <div className="pt-4 pb-6 sm:pb-12 -mt-20">
          <div
            className="sm:flex justify-between text-center sm:text-left rounded-xlarge pt-20 sm:pt-0 pl-4 sm:px-8"
            style={{ background: "#F0EEEC" }}
          >
            <div className="sm:pt-10 pt-6 pb-6 sm:pb-14">
              <div className="sm:mx-auto w-24 h-6 sm:ml-2 mt-1">
                <a href="https://chorusmeditation.com/" target="_blank" rel="noreferrer">
                  <img src="https://storage.googleapis.com/chorus-public/img/etc/purp-logo.png" />
                </a>
              </div>
              <div className="sm:mx-auto mt-4 flex items-center w-16">
                <a
                  href="https://www.instagram.com/chorusmeditation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-7"
                    src="https://storage.googleapis.com/chorus-public/img/etc/instagram.png"
                  />
                </a>
                <a
                  href="https://www.facebook.com/chorusmeditation"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="w-7 ml-1"
                    src="https://storage.googleapis.com/chorus-public/img/etc/facebook1.png"
                  />
                </a>
              </div>
            </div>

            {/* connect with us */}
            <div className="sm:pt-10 sm:pb-14 pt-6 sm:p-5  ">
              <div className="text-xl md:text-base lg:text-xl text-left capitalize text-charcoal font-bold">
                Connect with us
              </div>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/contact-us"
                target="_blank"
                rel="noreferrer"
              >
                Get in touch
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/blog/"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://www.linkedin.com/jobs/view/2784489708/"
                target="_blank"
                rel="noreferrer"
              >
                Careers
              </a>
            </div>

            {/* Get to know us */}
            <div className="sm:pt-10 sm:pb-14 pt-6 sm:p-5  ">
              <div className="text-xl md:text-base lg:text-xl text-left capitalize text-charcoal font-bold">
                Get to know us
              </div>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/how-it-works"
                target="_blank"
                rel="noreferrer"
              >
                How it works
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/what-to-expect"
                target="_blank"
                rel="noreferrer"
              >
                What to expect
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href={`${window.location.origin}/community`}
                target="_blank"
                rel="noreferrer"
              >
                Community
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/faq"
                target="_blank"
                rel="noreferrer"
              >
                FAQ
              </a>
            </div>

            {/* Legal Stuff */}
            <div className="sm:pt-10 pb-6 sm:pb-14 pt-6 sm:p-5  ">
              <div className="text-xl md:text-base lg:text-xl text-left capitalize text-charcoal font-bold">
                The legal stuff
              </div>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/communityguidelines/"
                target="_blank"
                rel="noreferrer"
              >
                Community Guidelines
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/terms-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              <a
                className="my-3 block text-left text-purp opacity-80 hover:text-gray-500"
                href="https://chorusmeditation.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
