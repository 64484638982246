import { useState, useContext } from "react";
import { UserContext } from "../contexts/User";
import { markAllNotificationsReadReq, markNotificationReadReq } from "../apiClient/requests";
import apiClient from "../apiClient";
import { formAndSaveUserObject } from "../common/Init";
import { handleError2 } from "apiClient/errors";

// Used for vscode intellisense
export const getNotificationsInitialState = () => ({
  loading: false,
  handleNotificationType: (notifData) => {},
  markNotificationsRead: (id) => {},
  markAllNotificationsRead: (id) => {},
  setUsers: (users) => {},
});

const serverNotifTypes = {
  LiveClassRem: "live_class_rem",
  OdClassRem: "od_class_rem",
  PCELive: "pce_live",
  ConsistencySupport: "con_support",
  OD: "od",
  Live: "live",
};

const useNotifications = () => {
  const [loading, setLoading] = useState(false);

  const { setUserData, user } = useContext(UserContext);

  const handleNotificationType = (notifData) => {
    if (notifData) {
      switch (notifData.type) {
        // Live class related
        case serverNotifTypes.LiveClassRem:
          return `/classes/${notifData?.object_id}`;
        case serverNotifTypes.PCELive:
          return `/classes/${notifData?.object_id}`;
        // Generic live notification type
        case serverNotifTypes.Live:
          return `/classes/${notifData?.object_id}`;

        // On-demand related
        case serverNotifTypes.OdClassRem:
          return `/on-demand/${notifData?.object_id}`;
        // Generic on-demand type
        case serverNotifTypes.OD:
          return `/on-demand/${notifData?.object_id}`;

        // Other
        case serverNotifTypes.ConsistencySupport:
          return "/";
        default:
          return "/"; // Hopefully just route to home page
      }
    }
  };

  const markNotificationsRead = async (id) => {
    try {
      setLoading(true);
      const req = markNotificationReadReq(id);
      const resp = await apiClient(req);
      setUserData(formAndSaveUserObject(resp?.data || {}, user.teachers));
    } catch (e) {
      handleError2(e);
    } finally {
      setLoading(false);
    }
  };

  const markAllNotificationsRead = async () => {
    try {
      setLoading(true);
      const req = markAllNotificationsReadReq();
      const resp = await apiClient(req);
      setUserData(formAndSaveUserObject(resp?.data || {}, user.teachers));
    } catch (e) {
      handleError2(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleNotificationType,
    markNotificationsRead,
    markAllNotificationsRead,
  };
};

export default useNotifications;
