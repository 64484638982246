import * as halps from "common/Halps";
import { apiBase, getUserFromStorage } from "common/Init";
import { attachAttr } from "common/Params";

export const headers = {
  "Content-Type": "application/json",
};

// get user auth token
export const getAuthToken = () => {
  const user = getUserFromStorage();
  if (user && Object.keys(user).length > 0 && user.token) {
    return `Bearer ${user.token}`;
  }
};

// Add the Authorization header with our auth token if we currently have one
export const getAuthenticatedHeaders = () => {
  const tkn = getAuthToken();
  let hdr = {
    ...headers,
  };
  if (tkn) {
    hdr.Authorization = tkn;
  }

  return hdr;
};

export const createReqObject = (url, method, header, data = {}) => {
  // Attach attr vals to all server request query strings now so we can track everything!
  if (url.includes("?")) {
    url += "&" + attachAttr();
  } else {
    url += "?" + attachAttr();
  }
  // We need to always identify which app we are
  header["X-APP-ID"] = "wapp_2021";
  return {
    url: `${apiBase()}${url}`,
    headers: header,
    data,
    method,
  };
};

// update user
export const updateUserReq = (data) => {
  return createReqObject(`/users`, "PUT", getAuthenticatedHeaders(), data);
};

// get user
export const getUserReq = () => {
  return createReqObject(`/users`, "GET", getAuthenticatedHeaders());
};

// mark as favorite
export const markFavoriteReq = (data) => {
  return createReqObject(`/favorites`, "POST", getAuthenticatedHeaders(), data);
};

// get favorite videos
export const getFavoriteVideosReq = () => {
  return createReqObject(`/favorites`, "GET", getAuthenticatedHeaders());
};

// delete favorite video
export const deleteFavoriteVideoReq = (favid) => {
  return createReqObject(`/favorites/${favid}`, "DELETE", getAuthenticatedHeaders());
};

// get billing data
export const getBillingData = () => {
  return createReqObject(`/bill`, "GET", getAuthenticatedHeaders());
};

// add a payment method
export const addPaymentMethod = (data) => {
  return createReqObject(`/bill/pm`, "POST", getAuthenticatedHeaders(), data);
};

// make a payment method the default
export const makeDefaultPaymentMethod = (pmid, data) => {
  return createReqObject(`/bill/pm/make-default/${pmid}`, "POST", getAuthenticatedHeaders(), data);
};

// Remove the payment method
export const removePaymentMethod = (pmid, data) => {
  return createReqObject(`/bill/pm/remove/${pmid}`, "POST", getAuthenticatedHeaders(), data);
};

// **OLD** Activate a new stripe subscription
export const activateStripeSub = (data) => {
  return createReqObject(`/bill/sub`, "POST", getAuthenticatedHeaders(), data);
};

//  Activate a new stripe subscription with provided plan
export const activateStripeSubWPlan = (data) => {
  return createReqObject(`/bill/sub3`, "POST", getAuthenticatedHeaders(), data);
};

// Cancel subscription :(
export const cancelSub = (data) => {
  return createReqObject(`/bill/sub/cancel`, "POST", getAuthenticatedHeaders(), data);
};

// update notification status to read
export const markNotificationReadReq = (notifId) => {
  return createReqObject(`/users/notifications/${notifId}`, "DELETE", getAuthenticatedHeaders());
};

// update status of all notifications to read
export const markAllNotificationsReadReq = () => {
  return createReqObject(`/users/notifications/`, "DELETE", getAuthenticatedHeaders());
};

// Update user settings PATCH
export const userUpdateSettings = (data) => {
  return createReqObject(
    `/users/settings`,
    "PATCH",
    getAuthenticatedHeaders(),
    JSON.stringify(data)
  );
};

// Send the initial on demand view request
export const odView = (id) => {
  return createReqObject(`/classes/ondemand/${id}/view`, "POST", getAuthenticatedHeaders());
};

// Update OD view percentage
export const updateODViewed = (id, data) => {
  return createReqObject(
    `/classes/ondemand/${id}/view-update`,
    "PUT",
    getAuthenticatedHeaders(),
    data
  );
};

// klaviyo - add member to list
export const addMemberToKlaviyoListReq = (data) => {
  return createReqObject(`/contact/email-signup`, "POST", headers, data);
};

// Create live class booking
export const liveBook = (data) => {
  return createReqObject(`/classes/bookings`, "POST", getAuthenticatedHeaders(), data);
};

// Get class data login optional
export const getLiveClass = (id) => {
  return createReqObject(`/classes/${id}`, "GET", getAuthenticatedHeaders());
};

// Send the app loaded request for our metrics
export const sendAppLoaded = (data) => {
  return createReqObject(`/uas/al`, "POST", getAuthenticatedHeaders(), data);
};

// Registration
export const reg = (data) => {
  return createReqObject(`/users`, "POST", getAuthenticatedHeaders(), data);
};

// Get on demands data based on filters
export const getonDemandsReq = (data) => {
  return createReqObject(`/classes/ondemand-page`, "POST", getAuthenticatedHeaders(), data);
};

// Get on demand by id
export const getonDemandByIdReq = (id) => {
  return createReqObject(`/classes/ondemand/${id}`, "GET", getAuthenticatedHeaders());
};

// get live classes
export const getLiveClasses = () => {
  return createReqObject(`/classes`, "GET", getAuthenticatedHeaders());
};

// get a single class
export const getClass = (id) => {
  return createReqObject(`/classes/${id}`, "GET", getAuthenticatedHeaders());
};

// get a single class
export const getBookings = () => {
  return createReqObject(`/classes/bookings`, "GET", getAuthenticatedHeaders());
};

// Book a live class!
export const bookLiveClass = (data) => {
  return createReqObject(`/classes/bookings`, "POST", getAuthenticatedHeaders(), data);
};

// Log user in using email/password
export const login = (data) => {
  return createReqObject(`/users/login`, "POST", getAuthenticatedHeaders(), data);
};

// User is requesting a login code to be sent to their email
export const loginSendCode = (data) => {
  return createReqObject(`/users/login/send-code`, "POST", getAuthenticatedHeaders(), data);
};

// User has already received the login code and is now entering it
export const loginWithCode = (data) => {
  return createReqObject(`/users/login/with-code`, "POST", getAuthenticatedHeaders(), data);
};

// Cancel a live class booking
export const cancelLiveClassBooking = (id) => {
  return createReqObject(`/classes/bookings/${id}`, "DELETE", getAuthenticatedHeaders());
};

// TODO move this to remote config!
export const getTeachers = () => {
  return createReqObject(`/teachers`, "GET", getAuthenticatedHeaders());
};

// apply coupon
export const applyUserCouponReq = (data) => {
  return createReqObject(`/users/coup`, "POST", getAuthenticatedHeaders(), data);
};

// contact - feeback
export const contact = (data) => {
  return createReqObject(`/contact`, "POST", headers, data);
};
