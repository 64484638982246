import axios from "axios";

export const apiClient = (config) => {
  return new Promise(function (resolve, reject) {
    axios(config)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export default apiClient;
