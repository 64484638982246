import React from "react";

function Calendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.cn || "h-7 w-7"}
      fill="none"
      viewBox="0 0 41 39"
    >
      <path
        stroke={props.stroke || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth || "3"}
        d="M14.333 11.497V5m13.334 6.497V5M9.333 34.237h23.334c.884 0 1.732-.342 2.357-.951.625-.61.976-1.436.976-2.297V11.497c0-.861-.351-1.688-.976-2.297a3.378 3.378 0 00-2.357-.951H9.333c-.884 0-1.732.342-2.357.951A3.207 3.207 0 006 11.497V30.99c0 .861.351 1.688.976 2.297.625.609 1.473.951 2.357.951z"
      ></path>
      <path
        stroke={props.stroke || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.51 17.37a.535.535 0 00-1.02 0l-.813 2.503a.535.535 0 01-.509.37h-2.631a.536.536 0 00-.315.969l2.129 1.546a.536.536 0 01.194.599l-.813 2.503a.535.535 0 00.824.599l2.13-1.547a.536.536 0 01.629 0l2.13 1.547a.535.535 0 00.823-.6l-.813-2.502a.535.535 0 01.194-.599l2.13-1.546a.536.536 0 00-.316-.97h-2.632a.536.536 0 01-.508-.37l-.814-2.502z"
      ></path>
    </svg>
  );
}

export default Calendar;
