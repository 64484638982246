import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import useIsMounted from "hooks/useHasMounted";
import { format, fromUnixTime } from "date-fns";
import { classDateDiff } from "common/Dates";

// dialog for membership canceled case
export default function Deactivated({ onClose, onReactivateSubscription, currentPeriodEnd, spin }) {
  // just handling the wierd dialog on close function calling issue
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  const date = currentPeriodEnd ? format(currentPeriodEnd, "MMM dd, yyyy") : "";

  return (
    <div className="min-w-screen h-screen animated fadeIn faster bg-dark-transparent fixed  left-0 top-0 inset-0 z-40 outline-none focus:outline-none">
      <Dialog
        className="position-at-center-fixed w-full px-2 sm:px-0 z-50  pb-8"
        open={true}
        onClose={onClose}
      >
        <div className="max-w-md  mx-auto">
          <div className="rounded-large relative pt-3 px-3 pb-6 bg-white w-full mx-auto">
            <p onClick={onClose} className="text-charcoal text-2xl float-right mr-4 cursor-pointer">
              &times;
            </p>
            <div className="text-center mt-6 px-2 mx-auto">
              <div className="text-center">
                <img
                  className="w-32 mx-auto inline-block"
                  src="https://storage.googleapis.com/chorus-public/img/bill/undraw_Check_boxes_re_v40f.png"
                  alt=""
                />
              </div>
              {currentPeriodEnd ? (
                <h2 className="text-charcoal mt-4 text-2xl sm:text-2.5xl font-semibold mx-auto sm:px-4">
                  Your Chorus account will be deactivated in {classDateDiff(currentPeriodEnd)}
                </h2>
              ) : (
                <></>
              )}
              <p className="text-purp mt-4 opacity-80 sm:px-4">
                Your subscription has been canceled and you won’t be billed again. You will have
                full access until {date}.
              </p>
              <h2 className="text-charcoal mt-4 text-2xl font-semibold mx-auto px-4">
                We’d love for you to stick around
              </h2>
            </div>
            <div className="w-full text-center">
              <button
                onClick={onReactivateSubscription}
                className={`focus:outline-none w-full sm:w-84 mt-10 mx-auto  py-3 rounded-3xl text-xl font-bold text-white hover:bg-gray-400 ${
                  spin ? "pointer-events-none bg-gray-200" : "bg-coral"
                }`}
              >
                Reactivate Subscription
              </button>
              <p className="text-charcoal mt-2 text-xs opacity-60">
                We will use your default card on file.
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
