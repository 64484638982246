import React from "react";

function BoldMusic({ cn, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn || "h-6 w-6"}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke={stroke || "#5BA7C7"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M8 18V5l12-3v13M8 18c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM8 9l12-3"
      ></path>
    </svg>
  );
}

export default BoldMusic;
