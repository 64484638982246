import React, { createContext, useState } from "react";

const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [shouldShowNav, setShowNav] = useState(true);
  const [shouldShowFooter, setShowFooter] = useState(true);

  const showScheduleDialog = () => {
    setShowDialog(true);
    setShowNav(false);
  };

  const hideScheduleDialog = () => {
    setShowDialog(false);
    setShowNav(true);
  };

  const showFooter = () => {
    setShowFooter(true);
  };

  const hideFooter = () => {
    setShowFooter(false);
  };

  const showNav = () => {
    setShowNav(true);
  };

  const hideNav = () => {
    setShowNav(false);
  };

  return (
    <ScheduleContext.Provider
      value={{
        showScheduleDialog,
        hideScheduleDialog,
        showNav,
        hideNav,
        showFooter,
        hideFooter,
        shouldShowFooter,
        showDialog,
        shouldShowNav,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContext;
