import React, { createContext } from "react";
import useNotifications, { getNotificationsInitialState } from "../hooks/Notifications";

export const NotificationsContext = createContext(getNotificationsInitialState());

const NotificationsProvider = ({ children }) => {
  const { loading, handleNotificationType, markNotificationsRead, markAllNotificationsRead } =
    useNotifications();

  return (
    <NotificationsContext.Provider
      value={{
        loading,
        handleNotificationType,
        markNotificationsRead,
        markAllNotificationsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
