/*

*** Initial processes to run when the app spins up

*/

import { v4 as uuidv4 } from "uuid";
import apiClient from "../apiClient";
import { sendAppLoaded } from "../apiClient/requests";
import { loginTrack, logoutTrack, regTrack } from "./Ana";
import { fbTrack, handleError } from "./Halps";
import {
  clearQuery,
  getAndClearLoginRedirect,
  getAttrParam,
  getQueryParam,
  loadFiltersFromQuery,
  readSaveAllQueryParams,
  runAB,
  storeClearAll,
  storeGet,
  storeSet,
} from "./Params";

//
// -------------------------- User/Auth Stuffs
//

// Large helper to try to just deal with all the annoying user flows
// As of May 2021, we want to ALWAYS send something back since the wapp isnt gated anymore
export async function handleUserState(teachers) {
  // First save off utm params to local storage
  readSaveAllQueryParams();

  // And add any filters that came from the query string
  loadFiltersFromQuery();

  // All params should now be saved from the query string.
  // Run a/b tests and re-save to local storage
  runAB();

  // Let's start off with always checking to see if they have the token in the query string
  // Which could be the case if they are logging in on behalf of another user via admin OR
  // Using the password reset email from another account or whatev

  let user = await userFromQuery(teachers); // This will clear the query string as well!
  if (user) {
    return user;
  }

  // Otherwise see if we currently have a token in local storage

  user = getUserFromStorage();
  if (user && Object.keys(user).length > 0 && user.token) {
    // Clear out query string before we refresh
    clearQuery();
    // For now until we have a better handle on refreshing data,
    // always refresh the data when the app loads
    return refreshUser(user.token, teachers);
  }
  user = userDefault(teachers);
  // Save the default user to storage and send the "guest" request
  saveUserToStorage(user);
  clearQuery();
  return user;
}

// Call this each time the app successfully loads.
// The goal is to keep this device id static across any number of user sessions
export function handleDeviceIDAndAppLoad() {
  let devID = storeGet("devid");
  if (!devID) {
    devID = uuidv4();
    storeSet("devid", devID);
    console.log(`Set device id ${devID}`);
  }
  const req = sendAppLoaded({ device_id: devID });
  apiClient(req).then(() => console.log("App finished loading"));
}

// Return the safe/default empty structure for the user
export function userDefault(teachers) {
  return {
    token: null,
    bookings_all: [],
    bookings_past: [],
    bookings_upcoming: [],
    event_bookings_all: [],
    event_bookings_past: [],
    event_bookings_upcoming: [],
    user_classes: {},
    user_events: {},
    teachers,
    // Other user fields...
    // ...userData.user,
  };
}

async function userFromQuery(teachers) {
  // See if they're coming back from login
  let token = getQueryParam("auth_token");
  if (!token) {
    return null;
  }

  // They just logged in/reged, take their token
  let userData = await getUser(token);
  // Make an easier to use user!
  const u = formAndSaveUserObject(userData, teachers);

  // Get the other params from the query to see if we registered and which provider
  // they used
  const providerName = getQueryParam("provider"); // google, fb, etc
  const didReg = getQueryParam("did_reg");
  if (didReg === "true") {
    // REGISTERED!
    storeSet("did_reg", true);
    regTrack(u, providerName, getAttrParam("ob"), getAttrParam("trl"));
  } else {
    loginTrack(u, providerName);
  }

  clearQuery();

  // The redirect we sent along with the initial redirect should be accurate
  // so let's just nuke it from storage
  getAndClearLoginRedirect();
}

export async function refreshUser(token, teachers) {
  let userData = await getUser(token);
  return formAndSaveUserObject(userData, teachers);
}

// Call when we get a fresh user resp from the api
export function formAndSaveUserObject(userData, teachers) {
  const u = {
    token: userData.token,
    bookings_all: userData.bookings_upcoming.concat(userData.bookings_past),
    bookings_past: userData.bookings_past,
    bookings_upcoming: userData.bookings_upcoming,

    event_bookings_all:
      userData.event_bookings_upcoming?.concat(userData.event_bookings_past) || [],
    event_bookings_past: userData.event_bookings_past,
    event_bookings_upcoming: userData.event_bookings_upcoming,
    firebase_token: userData.firebase_token,
    user_classes: userData.user_classes,
    user_events: userData.user_events,
    notifications: userData.notifications,
    settings: userData.settings,
    teachers,
    ...userData.user,
  };
  saveUserToStorage(u);
  return u;
}

// TODO get rid of this! If we get a 401, we need to clear the user gracefully!
// Alert their session has expired
export async function getUser(token) {
  return await fetch(`${apiBase()}/users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "X-APP-ID": "wapp_2021",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data && data.error) {
        handleError(data);
      } else {
        return data;
      }
    });
}

export function getUserFromStorage() {
  return JSON.parse(localStorage.getItem("user"));
}

export function saveUserToStorage(user) {
  localStorage.setItem("user", JSON.stringify(user));
}

// TODO reset user instead
export function delUserFromStorage() {
  saveUserToStorage(userDefault());
  // localStorage.removeItem("user");
}

// TODO this is likely kind of abrupt and should be improved
export function logout() {
  logoutTrack(getUserFromStorage());

  // Clear everything out of local storage sans third party stuff
  storeClearAll();
  // Write back the default user
  delUserFromStorage();
  // Need to hard refresh the page to make sure all data propagates
  window.location.href = "/";
}

export function apiBase() {
  if (!process.env.REACT_APP_API_ENDPOINT) {
    throw new Error("API ENDPOINT NOT SET!");
  }

  return process.env.REACT_APP_API_ENDPOINT;
}
