import { Spinline } from "../../common/Icons";

export default function JoinButt(props) {
  return (
    <div
      className={`max-w-10/12 lg:max-w-8/12 mt-4 rounded-full inline-block py-2 px-12 ${
        props.disabled
          ? "bg-gray-200 text-gray-400 cursor-not-allowed relative text-opacity-25"
          : "bg-corange text-white cursor-pointer"
      }`}
      onClick={!props.disabled ? props.clk : () => null}
    >
      <div className="font-semibold text-lg">{props.title}</div>
      <div className="text-xs">{props.txt}</div>

      {props.disabled ? <Spinline /> : ""}
    </div>
  );
}
