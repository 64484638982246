import React, { createContext } from "react";
import useUser from "../hooks/User";

const getInitialState = {
  loading: false,
  userDataloading: false,
  user: {
    bookings_upcoming: [],
    bookings_past: [],
    event_bookings_upcoming: [],
    event_bookings_past: [],
    teachers: [],
    user: {},
  },
  setLoading: (value) => {},
  setUserDataloading: (value) => {},
  setUserWithCallBack: (data, regOrShow) => {},
  setUserData: (data) => {},
};

export const UserContext = createContext({ ...getInitialState });

const UserProvider = ({ children }) => {
  const { loading, userDataloading, user, setLoading, setUserData, getUser, setUserWithCallBack } =
    useUser();

  return (
    <UserContext.Provider
      value={{
        loading,
        userDataloading,
        setLoading,
        user,
        setUserData,
        getUser,
        setUserWithCallBack,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
