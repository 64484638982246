import React from "react";

function BoldPlayButton({ cn, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn || "h-8 w-8"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={fill || "#5BA7C7"}
        d="M8.019 5.208a5.333 5.333 0 00-5.333 5.333v10.666a5.334 5.334 0 005.333 5.334h16a5.334 5.334 0 005.333-5.334V10.541a5.333 5.333 0 00-5.333-5.333h-16zm0 2.666h16a2.666 2.666 0 012.667 2.667v10.666a2.666 2.666 0 01-2.667 2.667h-16a2.666 2.666 0 01-2.667-2.666V10.54A2.666 2.666 0 018.02 7.874zm4.833 2.875c-.492.264-.833.76-.833 1.458v7.334c0 1.398 1.392 2.07 2.541 1.416l5.417-3.083c1.838-1.14 1.771-2.9 0-4l-5.417-3.083a1.761 1.761 0 00-1.708-.042z"
      ></path>
    </svg>
  );
}

export default BoldPlayButton;
